import { CSSProperties, useEffect, useRef } from 'react';
import { EChartsOption, getInstanceByDom, init, SetOptionOpts } from 'echarts';

type Props = {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    isLoading?: boolean;
    theme?: 'light' | 'dark';
    testId?: string;
};

export const EChart = ({ option, style, settings, isLoading = false, theme = 'light', testId }: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chart = chartRef.current !== null ? init(chartRef.current, theme) : undefined;

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        const resizeChart = () => {
            chart?.resize();
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, [theme]);

    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        return () => {
            chart?.dispose();
        };
    }, []);

    // Update chart
    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        if (chart === undefined) {
            return;
        }

        chart.setOption(option, settings);
    }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    // Update chart
    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        if (chart === undefined) {
            return;
        }

        isLoading
            ? chart.showLoading({
                  text: 'Wird geladen',
                  color: '#5A5A5A',
              })
            : chart.hideLoading();
    }, [isLoading, theme]);

    return <div ref={chartRef} style={{ width: '100%', height: '500px', ...style }} data-testid={testId} />;
};
