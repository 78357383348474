import React, { FC, useEffect } from 'react';
import { Temporal } from 'temporal-polyfill';
import { useIntl } from 'react-intl';
import { CalendarIcon } from '@trawa-energy/ui-kit';
import { TimelineIcon } from './TimelineIcon.tsx';

export const TimestampSlider: FC<{
    latestDayWithData: Temporal.PlainDate;
    value: Temporal.PlainDateTime;
    onChange: (value: Temporal.PlainDateTime) => void;
}> = ({ latestDayWithData, value, onChange }) => {
    const intl = useIntl();
    const minDateTime = latestDayWithData.toPlainDateTime();
    const selectableDateTimes = generateSelectableDateTimes(minDateTime);
    const selectedDateTimeIndex = selectableDateTimes.findIndex(dateTime => value.equals(dateTime));

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = selectedDateTimeIndex === selectableDateTimes.length - 1 ? 0 : selectedDateTimeIndex + 1;

            onChange(selectableDateTimes[nextIndex]);
        }, 6250);

        return () => clearInterval(interval);
    }, [onChange, selectableDateTimes, selectedDateTimeIndex]);

    const tailwindClasses = [
        'w-[calc(100%+1.5rem)]', // full-w + thumb-width
        'h-4',
        'block',
        'bg-transparent',
        'appearance-none',
        '[&::-webkit-slider-thumb]:appearance-none',
        '[&::-moz-range-thumb]:appearance-none',
    ];

    return (
        <div className="border border-gray-5 rounded-2xl py-4 lg:py-5 flex flex-col gap-8 lg:gap-10 m-12">
            <div className="px-20 lg:px-24 col-span-2 2xl:col-span-1">
                <div className="relative">
                    <div className="flex justify-between h-10 lg:h-14 items-center">
                        <span className="-translate-x-2/4">
                            <TimelineIcon type="day" isActive={selectedDateTimeIndex === 0} />
                        </span>
                        <span className="-translate-x-1/4">
                            <TimelineIcon type="day" isActive={selectedDateTimeIndex === 1} />
                        </span>
                        <span className="translate-x-1/4">
                            <TimelineIcon type="day" isActive={selectedDateTimeIndex === 2} />
                        </span>
                        <span className="translate-x-2/4">
                            <TimelineIcon type="night" isActive={selectedDateTimeIndex === 3} />
                        </span>
                    </div>
                    <div className="relative">
                        <input
                            className={tailwindClasses.join(' ')}
                            type="range"
                            min={0}
                            max={selectableDateTimes.length - 1}
                            value={selectableDateTimes.findIndex(x => value.equals(x))}
                            readOnly={true}
                        />
                        <div className="h-[1.25px] bg-white absolute top-1/2 w-full -translate-y-1/2 z-10" />
                        <div className="grid grid-cols-3 absolute top-1/2 w-full">
                            <div className="border-l-[1.5px] border-white h-2" />
                            <div className="border-x-[1.5px] border-white h-2" />
                            <div className="border-r-[1.5px] border-white h-2" />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span
                            className={`-translate-x-2/4 text-base lg:text-xl ${selectedDateTimeIndex === 0 ? 'font-bold' : 'font-normal'}`}
                        >
                            {intl.formatMessage(
                                { id: 'tv.timeslider.time' },
                                { time: intl.formatTime(selectableDateTimes[0].toString()) },
                            )}
                        </span>
                        <span
                            className={`-translate-x-1/4 text-base lg:text-xl  ${selectedDateTimeIndex === 1 ? 'font-bold' : 'font-normal'}`}
                        >
                            {intl.formatMessage(
                                { id: 'tv.timeslider.time' },
                                { time: intl.formatTime(selectableDateTimes[1].toString()) },
                            )}
                        </span>
                        <span
                            className={`translate-x-1/4 text-base lg:text-xl  ${selectedDateTimeIndex === 2 ? 'font-bold' : 'font-normal'}`}
                        >
                            {intl.formatMessage(
                                { id: 'tv.timeslider.time' },
                                { time: intl.formatTime(selectableDateTimes[2].toString()) },
                            )}
                        </span>
                        <span
                            className={`translate-x-2/4 text-base lg:text-xl ${selectedDateTimeIndex === 3 ? 'font-bold' : 'font-normal'}`}
                        >
                            {intl.formatMessage(
                                { id: 'tv.timeslider.time' },
                                { time: intl.formatTime(selectableDateTimes[3].toString()) },
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="flex items-center gap-2 border border-gray-5 px-4 py-2 lg:px-5 lg:py-2.5 rounded-xl dark:bg-primary-green-6 font-bold text-xl lg:text-2xl">
                    <CalendarIcon className="text-primary" />
                    {intl.formatDate(latestDayWithData.toString(), { dateStyle: 'medium' })}
                </div>
            </div>
        </div>
    );
};

const generateSelectableDateTimes = (minDateTime: Temporal.PlainDateTime) => [
    minDateTime.add({ hours: 8 }),
    minDateTime.add({ hours: 12 }),
    minDateTime.add({ hours: 16 }),
    minDateTime.add({ hours: 20 }),
];
