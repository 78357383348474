import { useUserSettings } from '../userSettings.ts';

export const useFeatureFlags = () => {
    const { userSettings } = useUserSettings();
    /* TODO : Implement using featurevisor here */

    return {
        hasLocationEnabled: userSettings.featureFlags.includes('ppa locations'),
        isBeta: userSettings.featureFlags.includes('beta customer'),
        isManageOnly: userSettings.featureFlags.includes('manage only'),
        isClusterEnabled: userSettings.featureFlags.includes('cluster'),
    };
};
