import marketLocationIconCluster from '../../assets/icons/locationCluster.svg';

export const plainMapStyle = [
    {
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#768070',
            },
            {
                weight: '1.00',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#d0c4a1',
            },
            {
                weight: '1.50',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
            {
                color: '#d0c4a1',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 57,
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                lightness: 24,
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#c1d8f5',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

export const googleMapsOptions = {
    styles: plainMapStyle,
    disableDefaultUI: true,
    zoomControl: true,
    maxZoom: 15,
};

export const mapContainerStyles = {
    height: '100%',
    width: '100%',
    background: '#FDFDFB',
};

export const clusterStylesAssetsLocations = (url: string) => [
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 16,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url,
    },
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 16,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url,
    },
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 18,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url,
    },
];

export const clusterStyleMarketLocations = [
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 16,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url: marketLocationIconCluster,
    },
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 16,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url: marketLocationIconCluster,
    },
    {
        width: 48,
        height: 48,
        textColor: 'black',
        textSize: 18,
        fontFamily: 'DM Sans',
        fontWeight: '700',
        borderRadius: '50%',
        backgroundColor: '#29ED9B',
        url: marketLocationIconCluster,
    },
];

export const defaultZoom = 6.5;

/* middle of Germany */
export const defaultCoordinates: google.maps.LatLngLiteral = {
    lat: 52,
    lng: 10,
};

/* bounding box of Germany */
export const defaultBoundingBox = { south: 47.2701114, north: 55.099161, west: 5.8663153, east: 15.0419319 };

const polygonOptionsStates = {
    strokeColor: '#F3F3F3',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: '#055E45',
    fillOpacity: 0.6,
    zIndex: 100,
} as google.maps.PolygonOptions;

const polygonOptionsNotStatesTrans = {
    strokeColor: '#F3F3F3',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: 'transparent',
    fillOpacity: 0,
    zIndex: 101,
} as google.maps.PolygonOptions;

export const germanStates = {
    bavaria: polygonOptionsStates,
    badw: polygonOptionsStates,
    hessen: polygonOptionsStates,
    nrw: polygonOptionsStates,
    rhp: polygonOptionsStates,
    saarland: polygonOptionsStates,
    niedersachsen: polygonOptionsStates,
    brandenburg: polygonOptionsStates,
    hamburg: polygonOptionsStates,
    mvp: polygonOptionsStates,
    sachsen: polygonOptionsStates,
    'sachsen-anhalt': polygonOptionsStates,
    schleswigh: polygonOptionsStates,
    thuringen: polygonOptionsStates,
    berlin: polygonOptionsNotStatesTrans,
    bremen: polygonOptionsNotStatesTrans,
} as { [key: string]: google.maps.PolygonOptions };
