import { Temporal } from 'temporal-polyfill';
import { Period } from './useFilterValues';

export function getDateRangeForPeriod(today: Temporal.PlainDate, period: Period) {
    switch (period) {
        case 'dayBeforeYesterday': {
            const dby = today.subtract({ days: 2 });
            return { from: dby, to: dby };
        }
        case 'yesterday': {
            const yesterday = today.subtract({ days: 1 });
            return { from: yesterday, to: yesterday };
        }
        case 'thisMonth': {
            const startOfMonth = today.subtract({ days: today.day - 1 });
            return {
                from: startOfMonth,
                to: startOfMonth.add({ months: 1 }).subtract({ days: 1 }),
            };
        }
        case 'lastMonth': {
            const startOfLastMonth = today.subtract({ days: today.day - 1 }).subtract({ months: 1 });
            return {
                from: startOfLastMonth,
                to: startOfLastMonth.add({ months: 1 }).subtract({ days: 1 }),
            };
        }
        default:
            throw new Error(`Unknown period '${period}'`);
    }
}
