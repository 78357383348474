import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from 'apps/portal-api/src/routers/appRouter';
import { use } from 'echarts';
import { useEffect, useState } from 'react';

type GroupItem = inferRouterOutputs<AppRouter>['dashboard']['getMarketLocationsGroup'][number];

type SelectedGroupHookProps = {
    groupItems: GroupItem[] | undefined;
    selectedItemId: string;
    searchValue?: string;
};

export const useSelectedGroup = ({
    groupItems,
    selectedItemId,
    searchValue = '',
}: SelectedGroupHookProps): GroupItem[] => {
    const [filterBySearch, setFilterBySearch] = useState<GroupItem[]>([]);

    useEffect(() => {
        const filteredGroupItems = groupItems
            ?.filter(groupItem => groupItem.nameGroup.toLowerCase().includes(searchValue.toLowerCase()))
            .filter(groupItem => {
                if (!selectedItemId) {
                    return true;
                }

                return groupItem.clusterId === selectedItemId;
            });

        setFilterBySearch(filteredGroupItems || []);
    }, [searchValue, groupItems, selectedItemId]);

    return filterBySearch;
};
