import { mapContainerStyles, plainMapStyle } from '../components/maps/mapStyles';

export enum MapOption {
    minimal,
    default,
}

type MapStylesAndOptions = {
    mapContainerStyles: React.CSSProperties;
    options: google.maps.MapOptions;
};

export const getMapOptions = (option: MapOption): MapStylesAndOptions => {
    return {
        options: {
            disableDefaultUI: true,
            mapTypeId: option === MapOption.default ? 'satellite' : undefined,
            gestureHandling: null,
            zoomControl: option === MapOption.default ? false : true,
            maxZoom: 18,
            styles: option === MapOption.minimal ? plainMapStyle : null,
        },
        mapContainerStyles,
    };
};
