import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { router } from './routing/router.tsx';
import { getTranslations } from './locales/localeConfig.ts';
import AuthTRpcProviders from './AuthTRpcProviders.tsx';
import { AuthContext } from './auth/authContext.ts';
import { useLocaleTimeZone } from './hooks/useLocaleTimeZone.ts';
import { useHybridAuthenticate } from './auth/useHybridAuthenticate.ts';

export function App() {
    const { locale, timeZone } = useLocaleTimeZone();
    const { authState, getAccessToken } = useHybridAuthenticate();

    return (
        <AuthContext.Provider value={authState}>
            <div className="min-h-screen">
                <IntlProvider locale={locale} timeZone={timeZone} messages={getTranslations(locale)}>
                    <AuthTRpcProviders getAccessToken={getAccessToken}>
                        <RouterProvider router={router} />
                    </AuthTRpcProviders>
                </IntlProvider>
            </div>
        </AuthContext.Provider>
    );
}
