import { MarketLocation } from '../../constants/locations';

// TODO: instead of hardcoding Germany we need country-specific address formatting
export function getAddress(marketLocation: MarketLocation): string | null {
    const { addressStreet, addressNumber, addressPostalCode, addressCity, addressCountry } = marketLocation;

    // Check if any required address components are missing
    if (!addressStreet || !addressPostalCode || !addressCity || !addressCountry) {
        return null;
    }

    // Construct the full address string in German format
    const fullAddress =
        (addressNumber ? `${addressStreet} ${addressNumber}` : addressStreet) +
        `, ${addressPostalCode} ${addressCity}, ${addressCountry}`;

    return fullAddress;
}
