import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

export const Box: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({ children, className, ...props }) => {
    return (
        <div
            className={`border bg-gray-1 dark:border-gray-5 dark:bg-gray-6 rounded-lg z-10 relative flex ${className ?? ''}`}
            {...props}
        >
            {children}
        </div>
    );
};
