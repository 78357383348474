import { useEffect, useMemo, useState } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import { EChart } from './EChart.tsx';
import { graphic } from 'echarts';
import { Card } from './Card.tsx';
import { PageHeading } from './PageHeading.tsx';
import { useFilterValues } from '../utils/useFilterValues.tsx';
import { getSelectedDatesByFilterValues } from '../utils/getSelectedDatesByFilterValues.ts';
import { trpc } from '../utils/trpc.ts';
import { useContractPartnerId } from '../userSettings.ts';
import { DateRangeFilterControls } from './filter-controls/DateRangeFilterCotrols.tsx';
import { MarketLocationFilterControls } from './filter-controls/MarketLocationFilterControls.tsx';
import { useFormatDate } from '../hooks/useFormatDate.ts';
import { IntervalFilterControls } from './filter-controls/IntervalFilterControls.tsx';
import { formatAxisDateTimeByInterval } from '../utils/formatting/formatAxisDateTimeInterval.ts';
import { Temporal } from 'temporal-polyfill';

type MarketLocationTypeDropdown = {
    id: string;
    label: string;
};

const marketLocationDropdownDefault: MarketLocationTypeDropdown[] = [];

type CustomItemTooltip = {
    seriesType: string;
    // Series index in option.series
    seriesIndex: number;
    // Series name
    seriesName: string;
    // Data name, or category name
    name: string;
    // Marker
    marker: string;
    // Data index in input data array
    dataIndex: number;
    // Original data as input
    value: number | string;
    // Color of data
    color: string;
};

export const AnalysisCharts = ({ useAthena }: { useAthena?: boolean }) => {
    const intl = useIntl();
    const [filterValues, setFilterValues] = useFilterValues();
    const selectedDates = useMemo(() => getSelectedDatesByFilterValues(filterValues), [filterValues]);
    const formatedDate = useFormatDate(selectedDates, filterValues.period);
    const [marketLocationDropdownOptions, setMarketLocationDropdownOptions] = useState(marketLocationDropdownDefault);

    const contractPartnerId = useContractPartnerId();

    const { data: analysisData, isLoading } = trpc.analysis.getAnalysisData.useQuery({
        // TODO: This will remove when we do all the test for psql
        useAthena,
        contractPartnerId,
        dateRange: {
            from: selectedDates.from.toString(),
            exclusiveTo: selectedDates.exclusiveTo.toString(),
        },
        aggregationInterval: filterValues.interval === '15min' ? undefined : filterValues.interval,
    });

    useEffect(() => {
        if (marketLocationDropdownOptions.length === 0 && analysisData) {
            setMarketLocationDropdownOptions(
                analysisData.marketLocations.map(({ id, label }) => ({ id, label: label ?? '' })),
            );
        }
    }, [analysisData, marketLocationDropdownOptions.length]);

    const formatValue = (value: unknown) => {
        const floatValue = typeof value === 'string' ? parseFloat(value) : value;

        if (floatValue === undefined || typeof floatValue !== 'number') {
            return '';
        }

        return intl.formatNumber(floatValue, {
            maximumFractionDigits: 2,
        });
    };

    const selectedMarketLocation =
        analysisData?.marketLocations?.find(({ id }) => filterValues.marketLocationId === id) ??
        analysisData?.marketLocations[0];

    const pvGenerationIsSet = selectedMarketLocation?.pvData !== undefined;

    const consumptionPeakIsSet =
        selectedMarketLocation?.marketLocationPeak !== undefined && filterValues.interval === '15min';

    const consumption = selectedMarketLocation?.volumeEntriesByMarketLocation ?? [];
    const pvGeneration = selectedMarketLocation?.pvData ?? [];
    const energyCosts = selectedMarketLocation?.cost ?? [];
    const energyCostsIsSet = energyCosts.length > 0;
    const consumptionPeak = selectedMarketLocation?.marketLocationPeak ?? 0; //analysisElementData?.marketLocationPeak ?? 0;
    const maxConsumptionInPeriod = selectedMarketLocation
        ? Math.max(...selectedMarketLocation.volumeEntriesByMarketLocation.map(o => o.kwh))
        : 0;
    const maxPVInPeriod = selectedMarketLocation?.pvData
        ? Math.max(...selectedMarketLocation.pvData.map(o => o.kwh))
        : 0;
    const highPointChart = Math.ceil(Math.max(maxConsumptionInPeriod, maxPVInPeriod, consumptionPeak) + 10);
    const prices = analysisData?.marketPrice;

    const paddingX = 50;
    const chartHeight = 250;
    const paddingTop = 75;
    const titleHight = 14;
    const chartSize = 375 * 2;

    const isLoadingMarketLocations = [
        {
            id: 'loading',
            label: intl.formatMessage({ id: 'common.loading' }),
        },
    ];

    const formatTooltipValue = (value: string | number, name: string) => {
        const formatValueMap: Record<string, string> = {
            Netzbezug: 'common.kwh',
            'PV Erzeugung': 'common.kwh',
            'Preis Strombörse': 'common.ctPerKwh',
            Gesamtkosten: 'common.eur',
        };

        return `<b>${formatValue(value)} ${intl.formatMessage({ id: formatValueMap[name] ?? 'common.kwh' })}</b>`;
    };

    // eslint-disable-next-line
    const generateTooltip = (params: Array<CustomItemTooltip> | Object) => {
        const tooltipElements: Array<CustomItemTooltip> = params as Array<CustomItemTooltip>;
        const first: CustomItemTooltip = tooltipElements[0];

        const date = consumption[first.dataIndex].timestampStart;
        let formatOptions: FormatDateOptions = {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: selectedMarketLocation?.timeZone,
        };

        if (['day'].includes(filterValues.interval)) {
            const { hour, minute, ...rest } = formatOptions;
            formatOptions = rest;
        }

        const formatDate = intl.formatDate(new Date(Temporal.Instant.from(date).toString()), formatOptions);

        const tooltipDataToRender = tooltipElements
            .sort((n1, n2) => n1.seriesIndex - n2.seriesIndex)
            .map(
                param =>
                    `<p>
                    ${param.marker} ${param.seriesName}: ${formatTooltipValue(param.value, param.seriesName)}
                </p>`,
            );

        return `<div style="min-width:240px">
                    <p>
                        ${formatDate}
                    </p>
                    ${tooltipDataToRender.join('')}
                </div>`;
    };

    return (
        <section>
            <div id="analysis-page-header" className="analysis-page-header pb-5">
                <PageHeading title={'analysis.title'} />
                <div className="flex flex-wrap items-start gap-x-2">
                    <DateRangeFilterControls filterValues={filterValues} setFilterValues={setFilterValues} />
                    <MarketLocationFilterControls
                        setFilterValues={setFilterValues}
                        filterValues={filterValues}
                        isLoading={isLoading}
                        marketLocations={
                            marketLocationDropdownOptions && marketLocationDropdownOptions.length > 0
                                ? marketLocationDropdownOptions?.map(({ id, label }) => ({
                                      id,
                                      label: label ?? '',
                                  }))
                                : isLoadingMarketLocations
                        }
                    />

                    <IntervalFilterControls
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        showDayOption={false}
                    />
                </div>
            </div>
            <Card className="p-3 md:p-8">
                <h3 className="text-base md:text-lg xl:text-xl font-bold w-full">
                    {intl.formatMessage(
                        {
                            id: pvGenerationIsSet
                                ? 'analysis.chartTitle.consumptionAndGenerationNoMaLo'
                                : 'analysis.chartTitle.consumptionNoMaLo',
                        },
                        {
                            date: formatedDate,
                            marketLocation: selectedMarketLocation?.label,
                        },
                    )}
                </h3>
                {consumption.length > 0 || isLoading ? (
                    <>
                        <EChart
                            testId="analysis-chart-element"
                            style={{ height: `${chartSize}px` }}
                            isLoading={isLoading}
                            option={{
                                grid: [
                                    {
                                        top: paddingTop,
                                        left: paddingX,
                                        right: paddingX,
                                        height: chartHeight,
                                    },
                                    {
                                        top: paddingTop * 2 + chartHeight + titleHight * 2 + 30,
                                        left: paddingX,
                                        right: paddingX,
                                        height: chartHeight,
                                    },
                                ],
                                title: [
                                    {
                                        text: '',
                                        left: 'left',
                                    },
                                    {
                                        text: intl.formatMessage(
                                            {
                                                id: energyCostsIsSet
                                                    ? 'analysis.chartTitle.priceAndCosts'
                                                    : 'analysis.chartTitle.price',
                                            },
                                            {
                                                date: '',
                                                marketLocation: '',
                                            },
                                        ),
                                        textStyle: {
                                            fontSize: 19,
                                            color: '#000',
                                            fontWeight: 'bold',
                                            fontFamily: 'DM Sans',
                                        },
                                        left: 'left',
                                        top: paddingTop + titleHight + chartHeight + 40,
                                    },
                                ],
                                legend: {
                                    right: 0,
                                    data: [
                                        intl.formatMessage({ id: 'analysis.chartLegend.consumption' }),
                                        pvGenerationIsSet &&
                                            intl.formatMessage({ id: 'analysis.chartLegend.pvGeneration' }),
                                        prices?.length !== 0 &&
                                            intl.formatMessage({ id: 'analysis.chartLegend.marketPrice' }),
                                        energyCostsIsSet && intl.formatMessage({ id: 'analysis.chartLegend.costs' }),
                                    ].filter(Boolean),
                                },
                                visualMap: [
                                    {
                                        show: false,
                                        type: 'continuous',
                                        seriesIndex: 1,
                                        min: 0,
                                        max: 30,
                                        inRange: {
                                            color: ['#27ae60', '#f1c40f', '#e74c3c'],
                                        },
                                    },
                                ],
                                xAxis: [
                                    {
                                        type: 'category',
                                        data: consumption.map(({ timestampStart }) =>
                                            formatAxisDateTimeByInterval(
                                                intl,
                                                new Date(Temporal.Instant.from(timestampStart).toString()),
                                                filterValues.interval,
                                                consumption.length,
                                                filterValues.period,
                                                selectedMarketLocation?.timeZone ?? '',
                                            ),
                                        ),
                                        axisTick: {
                                            alignWithLabel: true,
                                        },
                                    },
                                    {
                                        gridIndex: 1,
                                        type: 'category',
                                        data: consumption.map(({ timestampStart }) =>
                                            formatAxisDateTimeByInterval(
                                                intl,
                                                new Date(Temporal.Instant.from(timestampStart).toString()),
                                                filterValues.interval,
                                                consumption.length,
                                                filterValues.period,
                                                selectedMarketLocation?.timeZone ?? '',
                                            ),
                                        ),
                                        axisLine: {
                                            onZero: false,
                                        },
                                        axisTick: {
                                            alignWithLabel: true,
                                        },
                                    },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        name: intl.formatMessage({ id: 'common.kwh' }),
                                        max: highPointChart,
                                        nameTextStyle: {
                                            fontWeight: 'bold',
                                        },
                                    },
                                    {
                                        type: 'value',
                                        name: intl.formatMessage({ id: 'common.ctPerKwh' }),
                                        position: energyCostsIsSet ? 'right' : 'left',
                                        nameTextStyle: {
                                            fontWeight: 'bold',
                                        },
                                        gridIndex: 1,
                                        splitLine: {
                                            show: !energyCostsIsSet,
                                        },
                                    },
                                    {
                                        type: 'value',
                                        name: intl.formatMessage({ id: 'common.eur' }),
                                        position: 'left',
                                        nameTextStyle: {
                                            fontWeight: 'bold',
                                        },
                                        gridIndex: 1,
                                        show: energyCostsIsSet,
                                    },
                                ],
                                axisPointer: {
                                    link: [
                                        {
                                            xAxisIndex: 'all',
                                        },
                                    ],
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    formatter: generateTooltip,
                                },
                                series: [
                                    {
                                        name: intl.formatMessage({ id: 'analysis.chartLegend.consumption' }),
                                        data: consumption.map(({ kwh }) => kwh),
                                        tooltip: {
                                            valueFormatter: value =>
                                                `${formatValue(value)} ${intl.formatMessage({ id: 'common.kwh' })}`,
                                        },
                                        type: 'bar',
                                        color: '#82b6fc',
                                        emphasis: {
                                            itemStyle: {
                                                color: '#066EF9',
                                            },
                                        },
                                        markLine: consumptionPeakIsSet
                                            ? {
                                                  symbol: 'none',
                                                  lineStyle: {
                                                      type: 8,
                                                      width: 1.5,
                                                      color: '#E84236',
                                                  },
                                                  label: {
                                                      formatter: intl.formatMessage({
                                                          id: 'analysis.peak',
                                                      }),
                                                      position: 'insideStartTop',
                                                      color: '#E84236',
                                                  },
                                                  data: [
                                                      {
                                                          name: 'Peak',
                                                          yAxis: consumptionPeak,
                                                      },
                                                  ],
                                              }
                                            : undefined,
                                        zlevel: 1,
                                    },
                                    {
                                        name: intl.formatMessage({ id: 'analysis.chartLegend.marketPrice' }),
                                        data: prices?.map(
                                            ({ price }) =>
                                                `${(typeof price === 'string' ? parseFloat(price) : price) * 100}`,
                                        ),
                                        tooltip: {
                                            valueFormatter: value =>
                                                `${value} ${intl.formatMessage({ id: 'common.ctPerKwh' })}`,
                                        },
                                        type: 'line',
                                        showSymbol: false,
                                        step: 'middle',
                                        xAxisIndex: 1,
                                        yAxisIndex: 1,
                                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                                            {
                                                offset: 0,
                                                color: '#e74c3c',
                                            },
                                            {
                                                offset: 0.05,
                                                color: '#f1c40f',
                                            },
                                            {
                                                offset: 0.7,
                                                color: '#27ae60',
                                            },
                                        ]),
                                        zlevel: 1,
                                    },
                                    {
                                        name: intl.formatMessage({ id: 'analysis.chartLegend.pvGeneration' }),
                                        data: pvGeneration.map(({ kwh }) => kwh),
                                        tooltip: {
                                            valueFormatter: value =>
                                                `${formatValue(value)} ${intl.formatMessage({ id: 'common.kwh' })}`,
                                        },
                                        symbol: 'none',
                                        type: 'line',
                                        lineStyle: {
                                            width: 0,
                                        },
                                        color: '#EFB13A',
                                        areaStyle: {
                                            color: new graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: '#EFB13A',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#DD8B0F',
                                                },
                                            ]),
                                        },
                                        zlevel: 2,
                                    },
                                    {
                                        name: intl.formatMessage({ id: 'analysis.chartLegend.costs' }),
                                        data: energyCosts.map(({ sumCost }) => sumCost),
                                        tooltip: {
                                            valueFormatter: value =>
                                                `${value} ${intl.formatMessage({ id: 'common.eur' })}`,
                                        },
                                        type: 'line',
                                        areaStyle: {
                                            color: '#055E45',
                                        },
                                        step: 'middle',
                                        symbol: 'none',
                                        color: '#055E45',
                                        xAxisIndex: 1,
                                        yAxisIndex: 2,
                                    },
                                ],
                            }}
                        />

                        <p className="text-center text-xs font-bold text-gray-4">
                            {intl.formatMessage({ id: 'filterControls.intervals.' + filterValues.interval })}
                        </p>
                    </>
                ) : (
                    <div className="flex place-content-center place-items-center text-lg font-bold h-[375px] w-full text-gray-4">
                        {intl.formatMessage({ id: 'common.noData' })}
                    </div>
                )}
            </Card>
        </section>
    );
};
