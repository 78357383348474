/**
 * This component is responsible for handling magic link, not for requesting it.
 * States:
 * - Signing in: progress
 * - Link is invalid: error message
 * - Link is expired: error message
 */

import { useIntl } from 'react-intl';
import { LoadingSpinner } from '@trawa-energy/ui-kit';
import { Section } from './Section.tsx';
import { SigningInStatus } from './MagicLinkLogin.tsx';

export function MagicLinkHandler({ signingInStatus }: { signingInStatus: MagicLinkHandlerStatus }) {
    const intl = useIntl();

    const statusComponents: Record<MagicLinkHandlerStatus, JSX.Element> = {
        SIGNING_IN_WITH_LINK: <LoadingSpinner size="lg" className="text-primary-green" />,
        SIGNIN_LINK_EXPIRED: (
            <Section title={intl.formatMessage({ id: 'magicLink.linkExpired.title' })}>
                {intl.formatMessage({ id: 'magicLink.linkExpired.body' })}
            </Section>
        ),
        INVALID_SIGNIN_LINK: (
            <Section title={intl.formatMessage({ id: 'magicLink.linkInvalid.title' })}>
                {intl.formatMessage({ id: 'magicLink.linkInvalid.body' })}
            </Section>
        ),
    };

    if (!statusComponents[signingInStatus]) {
        throw new Error(`Invalid signingInStatus '${signingInStatus}' in MagicLinkHandler`);
    }

    return statusComponents[signingInStatus];
}

const magicLinkHandlerStatuses = ['SIGNING_IN_WITH_LINK', 'INVALID_SIGNIN_LINK', 'SIGNIN_LINK_EXPIRED'] as const;
type MagicLinkHandlerStatus = (typeof magicLinkHandlerStatuses)[number];

MagicLinkHandler.isValidStatus = (status: SigningInStatus): status is MagicLinkHandlerStatus => {
    return magicLinkHandlerStatuses.includes(status as MagicLinkHandlerStatus);
};
