/**
 * Mock data for creating demo charts for the AnalysisDemoCharts component.
 * Ask Volker if this is still needed. Maybe it can be removed.
 */

export const indiaBerlinMockData = [
    { date: '00:00', value: 8.5 },
    { date: '00:15', value: 8.5 },
    { date: '00:30', value: 8.75 },
    { date: '00:45', value: 8.5 },
    { date: '01:00', value: 8.5 },
    { date: '01:15', value: 8.75 },
    { date: '01:30', value: 12.75 },
    { date: '01:45', value: 18 },
    { date: '02:00', value: 11 },
    { date: '02:15', value: 8.5 },
    { date: '02:30', value: 8.5 },
    { date: '02:45', value: 8.75 },
    { date: '03:00', value: 8.75 },
    { date: '03:15', value: 8.5 },
    { date: '03:30', value: 8.5 },
    { date: '03:45', value: 8.5 },
    { date: '04:00', value: 8.75 },
    { date: '04:15', value: 8.5 },
    { date: '04:30', value: 8.75 },
    { date: '04:45', value: 8.75 },
    { date: '05:00', value: 8.5 },
    { date: '05:15', value: 17.5 },
    { date: '05:30', value: 10.25 },
    { date: '05:45', value: 8.5 },
    { date: '06:00', value: 8.75 },
    { date: '06:15', value: 8.5 },
    { date: '06:30', value: 8.5 },
    { date: '06:45', value: 8.75 },
    { date: '07:00', value: 8.5 },
    { date: '07:15', value: 8.75 },
    { date: '07:30', value: 8.5 },
    { date: '07:45', value: 8.75 },
    { date: '08:00', value: 8.5 },
    { date: '08:15', value: 8.5 },
    { date: '08:30', value: 8.5 },
    { date: '08:45', value: 8.75 },
    { date: '09:00', value: 8.5 },
    { date: '09:15', value: 8.5 },
    { date: '09:30', value: 8.75 },
    { date: '09:45', value: 8.5 },
    { date: '10:00', value: 8.5 },
    { date: '10:15', value: 8.75 },
    { date: '10:30', value: 8.5 },
    { date: '10:45', value: 8.5 },
    { date: '11:00', value: 8.5 },
    { date: '11:15', value: 8.75 },
    { date: '11:30', value: 8.5 },
    { date: '11:45', value: 8.5 },
    { date: '12:00', value: 8.5 },
    { date: '12:15', value: 8.75 },
    { date: '12:30', value: 8.5 },
    { date: '12:45', value: 9 },
    { date: '13:00', value: 8.5 },
    { date: '13:15', value: 8.5 },
    { date: '13:30', value: 8.75 },
    { date: '13:45', value: 8.5 },
    { date: '14:00', value: 8.75 },
    { date: '14:15', value: 8.5 },
    { date: '14:30', value: 8.75 },
    { date: '14:45', value: 8.5 },
    { date: '15:00', value: 8.5 },
    { date: '15:15', value: 8.75 },
    { date: '15:30', value: 8.5 },
    { date: '15:45', value: 8.5 },
    { date: '16:00', value: 8.75 },
    { date: '16:15', value: 8.5 },
    { date: '16:30', value: 8.5 },
    { date: '16:45', value: 8.75 },
    { date: '17:00', value: 11.25 },
    { date: '17:15', value: 18.75 },
    { date: '17:30', value: 10 },
    { date: '17:45', value: 8.5 },
    { date: '18:00', value: 8.5 },
    { date: '18:15', value: 8.75 },
    { date: '18:30', value: 12.75 },
    { date: '18:45', value: 16 },
    { date: '19:00', value: 11 },
    { date: '19:15', value: 16.75 },
    { date: '19:30', value: 11.25 },
    { date: '19:45', value: 14.5 },
    { date: '20:00', value: 13.75 },
    { date: '20:15', value: 12.5 },
    { date: '20:30', value: 12.25 },
    { date: '20:45', value: 35 },
    { date: '21:00', value: 92.5 },
    { date: '21:15', value: 51.25 },
    { date: '21:30', value: 40.5 },
    { date: '21:45', value: 44.5 },
    { date: '22:00', value: 45.75 },
    { date: '22:15', value: 42.25 },
    { date: '22:30', value: 45.25 },
    { date: '22:45', value: 50.75 },
    { date: '23:00', value: 49.75 },
    { date: '23:15', value: 50.25 },
    { date: '23:30', value: 51.25 },
    { date: '23:45', value: 51.5 },
];

export const setexMockData = [
    { date: '00:00', value: 229 },
    { date: '00:15', value: 230.5 },
    { date: '00:30', value: 230.5 },
    { date: '00:45', value: 230.5 },
    { date: '01:00', value: 231 },
    { date: '01:15', value: 229.5 },
    { date: '01:30', value: 230.5 },
    { date: '01:45', value: 231 },
    { date: '02:00', value: 229 },
    { date: '02:15', value: 230 },
    { date: '02:30', value: 229.5 },
    { date: '02:45', value: 229.5 },
    { date: '03:00', value: 229 },
    { date: '03:15', value: 225 },
    { date: '03:30', value: 228.5 },
    { date: '03:45', value: 230.5 },
    { date: '04:00', value: 230 },
    { date: '04:15', value: 228.5 },
    { date: '04:30', value: 229.5 },
    { date: '04:45', value: 228 },
    { date: '05:00', value: 230.5 },
    { date: '05:15', value: 229.5 },
    { date: '05:30', value: 229 },
    { date: '05:45', value: 231 },
    { date: '06:00', value: 240.5 },
    { date: '06:15', value: 261.5 },
    { date: '06:30', value: 259.5 },
    { date: '06:45', value: 260.5 },
    { date: '07:00', value: 259.5 },
    { date: '07:15', value: 259.5 },
    { date: '07:30', value: 259.5 },
    { date: '07:45', value: 258.5 },
    { date: '08:00', value: 259.5 },
    { date: '08:15', value: 257.5 },
    { date: '08:30', value: 259 },
    { date: '08:45', value: 261.5 },
    { date: '09:00', value: 264 },
    { date: '09:15', value: 266 },
    { date: '09:30', value: 266 },
    { date: '09:45', value: 265 },
    { date: '10:00', value: 266 },
    { date: '10:15', value: 265.5 },
    { date: '10:30', value: 268.5 },
    { date: '10:45', value: 265.5 },
    { date: '11:00', value: 267.5 },
    { date: '11:15', value: 267 },
    { date: '11:30', value: 266 },
    { date: '11:45', value: 263.5 },
    { date: '12:00', value: 264 },
    { date: '12:15', value: 264 },
    { date: '12:30', value: 263 },
    { date: '12:45', value: 260.5 },
    { date: '13:00', value: 259 },
    { date: '13:15', value: 260 },
    { date: '13:30', value: 262 },
    { date: '13:45', value: 262.5 },
    { date: '14:00', value: 261.5 },
    { date: '14:15', value: 262 },
    { date: '14:30', value: 263.5 },
    { date: '14:45', value: 261.5 },
    { date: '15:00', value: 260.5 },
    { date: '15:15', value: 262 },
    { date: '15:30', value: 261.5 },
    { date: '15:45', value: 261 },
    { date: '16:00', value: 261.5 },
    { date: '16:15', value: 261.5 },
    { date: '16:30', value: 260.5 },
    { date: '16:45', value: 260 },
    { date: '17:00', value: 260 },
    { date: '17:15', value: 259.5 },
    { date: '17:30', value: 258.5 },
    { date: '17:45', value: 260 },
    { date: '18:00', value: 263 },
    { date: '18:15', value: 262.5 },
    { date: '18:30', value: 264 },
    { date: '18:45', value: 263 },
    { date: '19:00', value: 262.5 },
    { date: '19:15', value: 260 },
    { date: '19:30', value: 260.5 },
    { date: '19:45', value: 262 },
    { date: '20:00', value: 261 },
    { date: '20:15', value: 261 },
    { date: '20:30', value: 263 },
    { date: '20:45', value: 261 },
    { date: '21:00', value: 261.5 },
    { date: '21:15', value: 261 },
    { date: '21:30', value: 261 },
    { date: '21:45', value: 260 },
    { date: '22:00', value: 255 },
    { date: '22:15', value: 248.5 },
    { date: '22:30', value: 227 },
    { date: '22:45', value: 225.5 },
    { date: '23:00', value: 224.5 },
    { date: '23:15', value: 225.5 },
    { date: '23:30', value: 224.5 },
    { date: '23:45', value: 224.5 },
];

export const steinthermeBadBelzigMockData = [
    { date: '00:00', value: 1.61 },
    { date: '00:15', value: 1.82 },
    { date: '00:30', value: 1.74 },
    { date: '00:45', value: 1.16 },
    { date: '01:00', value: 1.26 },
    { date: '01:15', value: 1.38 },
    { date: '01:30', value: 1.49 },
    { date: '01:45', value: 1.67 },
    { date: '02:00', value: 1.49 },
    { date: '02:15', value: 1.37 },
    { date: '02:30', value: 1.32 },
    { date: '02:45', value: 1.42 },
    { date: '03:00', value: 1.57 },
    { date: '03:15', value: 2.01 },
    { date: '03:30', value: 4.47 },
    { date: '03:45', value: 6.62 },
    { date: '04:00', value: 7.58 },
    { date: '04:15', value: 7.21 },
    { date: '04:30', value: 6.65 },
    { date: '04:45', value: 7.03 },
    { date: '05:00', value: 7.01 },
    { date: '05:15', value: 5.64 },
    { date: '05:30', value: 5.25 },
    { date: '05:45', value: 5.31 },
    { date: '06:00', value: 5.97 },
    { date: '06:15', value: 5.63 },
    { date: '06:30', value: 0 },
    { date: '06:45', value: 0 },
    { date: '07:00', value: 0 },
    { date: '07:15', value: 0 },
    { date: '07:30', value: 0 },
    { date: '07:45', value: 0 },
    { date: '08:00', value: 0 },
    { date: '08:15', value: 0 },
    { date: '08:30', value: 0 },
    { date: '08:45', value: 0 },
    { date: '09:00', value: 0 },
    { date: '09:15', value: 6.73 },
    { date: '09:30', value: 9.38 },
    { date: '09:45', value: 4.07 },
    { date: '10:00', value: 1.22 },
    { date: '10:15', value: 1.78 },
    { date: '10:30', value: 1.32 },
    { date: '10:45', value: 0.94 },
    { date: '11:00', value: 0.83 },
    { date: '11:15', value: 1 },
    { date: '11:30', value: 1 },
    { date: '11:45', value: 2.32 },
    { date: '12:00', value: 0.34 },
    { date: '12:15', value: 1.55 },
    { date: '12:30', value: 0.2 },
    { date: '12:45', value: 1.05 },
    { date: '13:00', value: 1.36 },
    { date: '13:15', value: 0.2 },
    { date: '13:30', value: 1.99 },
    { date: '13:45', value: 0 },
    { date: '14:00', value: 3.29 },
    { date: '14:15', value: 11.58 },
    { date: '14:30', value: 17 },
    { date: '14:45', value: 11.64 },
    { date: '15:00', value: 11.38 },
    { date: '15:15', value: 24.59 },
    { date: '15:30', value: 25.61 },
    { date: '15:45', value: 1.97 },
    { date: '16:00', value: 1.35 },
    { date: '16:15', value: 4.96 },
    { date: '16:30', value: 30.85 },
    { date: '16:45', value: 24.35 },
    { date: '17:00', value: 3.51 },
    { date: '17:15', value: 1.7 },
    { date: '17:30', value: 28.96 },
    { date: '17:45', value: 29.9 },
    { date: '18:00', value: 14.27 },
    { date: '18:15', value: 0.04 },
    { date: '18:30', value: 0.08 },
    { date: '18:45', value: 0.19 },
    { date: '19:00', value: 2.67 },
    { date: '19:15', value: 0 },
    { date: '19:30', value: 0 },
    { date: '19:45', value: 0.05 },
    { date: '20:00', value: 1.65 },
    { date: '20:15', value: 0 },
    { date: '20:30', value: 0.1 },
    { date: '20:45', value: 0.42 },
    { date: '21:00', value: 1.87 },
    { date: '21:15', value: 0 },
    { date: '21:30', value: 0 },
    { date: '21:45', value: 0 },
    { date: '22:00', value: 0 },
    { date: '22:15', value: 0 },
    { date: '22:30', value: 0 },
    { date: '22:45', value: 0 },
    { date: '23:00', value: 0 },
    { date: '23:15', value: 0.3 },
    { date: '23:30', value: 2.54 },
    { date: '23:45', value: 1.98 },
];

export const whuMockData = [
    { date: '00:00', value: 8.175 },
    { date: '00:15', value: 7.9 },
    { date: '00:30', value: 8.425 },
    { date: '00:45', value: 7.925 },
    { date: '01:00', value: 7.975 },
    { date: '01:15', value: 7.775 },
    { date: '01:30', value: 8.175 },
    { date: '01:45', value: 7.775 },
    { date: '02:00', value: 8 },
    { date: '02:15', value: 7.7 },
    { date: '02:30', value: 8.475 },
    { date: '02:45', value: 8.4 },
    { date: '03:00', value: 7.9 },
    { date: '03:15', value: 7.525 },
    { date: '03:30', value: 8.3 },
    { date: '03:45', value: 7.875 },
    { date: '04:00', value: 7.7 },
    { date: '04:15', value: 8.025 },
    { date: '04:30', value: 7.95 },
    { date: '04:45', value: 8.375 },
    { date: '05:00', value: 10.05 },
    { date: '05:15', value: 11.3 },
    { date: '05:30', value: 12.225 },
    { date: '05:45', value: 13.775 },
    { date: '06:00', value: 14 },
    { date: '06:15', value: 15.025 },
    { date: '06:30', value: 13.925 },
    { date: '06:45', value: 13.75 },
    { date: '07:00', value: 13.25 },
    { date: '07:15', value: 12.6 },
    { date: '07:30', value: 12.525 },
    { date: '07:45', value: 12.4 },
    { date: '08:00', value: 11.675 },
    { date: '08:15', value: 11.275 },
    { date: '08:30', value: 11.325 },
    { date: '08:45', value: 10.75 },
    { date: '09:00', value: 10.65 },
    { date: '09:15', value: 9.9 },
    { date: '09:30', value: 9.35 },
    { date: '09:45', value: 9.625 },
    { date: '10:00', value: 9.65 },
    { date: '10:15', value: 9.325 },
    { date: '10:30', value: 8.075 },
    { date: '10:45', value: 7.45 },
    { date: '11:00', value: 7.1 },
    { date: '11:15', value: 6.725 },
    { date: '11:30', value: 7.05 },
    { date: '11:45', value: 6.325 },
    { date: '12:00', value: 8.7 },
    { date: '12:15', value: 8.175 },
    { date: '12:30', value: 9.375 },
    { date: '12:45', value: 5.45 },
    { date: '13:00', value: 4.75 },
    { date: '13:15', value: 4.275 },
    { date: '13:30', value: 4.7 },
    { date: '13:45', value: 4.275 },
    { date: '14:00', value: 4.35 },
    { date: '14:15', value: 4.8 },
    { date: '14:30', value: 5.225 },
    { date: '14:45', value: 5.275 },
    { date: '15:00', value: 4.325 },
    { date: '15:15', value: 6.225 },
    { date: '15:30', value: 6.675 },
    { date: '15:45', value: 7.175 },
    { date: '16:00', value: 7.525 },
    { date: '16:15', value: 8.3 },
    { date: '16:30', value: 8.95 },
    { date: '16:45', value: 14.625 },
    { date: '17:00', value: 14.875 },
    { date: '17:15', value: 11.725 },
    { date: '17:30', value: 12.475 },
    { date: '17:45', value: 12.575 },
    { date: '18:00', value: 14.275 },
    { date: '18:15', value: 11.625 },
    { date: '18:30', value: 14.425 },
    { date: '18:45', value: 13.325 },
    { date: '19:00', value: 14.95 },
    { date: '19:15', value: 15.25 },
    { date: '19:30', value: 16.4 },
    { date: '19:45', value: 15.575 },
    { date: '20:00', value: 14.35 },
    { date: '20:15', value: 14.475 },
    { date: '20:30', value: 14.025 },
    { date: '20:45', value: 12.125 },
    { date: '21:00', value: 12.35 },
    { date: '21:15', value: 12.35 },
    { date: '21:30', value: 12.025 },
    { date: '21:45', value: 12.35 },
    { date: '22:00', value: 11.475 },
    { date: '22:15', value: 10.375 },
    { date: '22:30', value: 9.9 },
    { date: '22:45', value: 9.35 },
    { date: '23:00', value: 9.15 },
    { date: '23:15', value: 8.425 },
    { date: '23:30', value: 8.425 },
    { date: '23:45', value: 8.125 },
];

export const pricesMockData0 = [
    { date: '00:00', value: 104.79 },
    { date: '00:15', value: 104.79 },
    { date: '00:30', value: 104.79 },
    { date: '00:45', value: 104.79 },
    { date: '01:00', value: 104.52 },
    { date: '01:15', value: 104.52 },
    { date: '01:30', value: 104.52 },
    { date: '01:45', value: 104.52 },
    { date: '02:00', value: 102.2 },
    { date: '02:15', value: 102.2 },
    { date: '02:30', value: 102.2 },
    { date: '02:45', value: 102.2 },
    { date: '03:00', value: 10.0 },
    { date: '03:15', value: 10.0 },
    { date: '03:30', value: 10.0 },
    { date: '03:45', value: 10.0 },
    { date: '04:00', value: 101.63 },
    { date: '04:15', value: 101.63 },
    { date: '04:30', value: 101.63 },
    { date: '04:45', value: 101.63 },
    { date: '05:00', value: 106.3 },
    { date: '05:15', value: 106.3 },
    { date: '05:30', value: 106.3 },
    { date: '05:45', value: 106.3 },
    { date: '06:00', value: 129.41 },
    { date: '06:15', value: 129.41 },
    { date: '06:30', value: 129.41 },
    { date: '06:45', value: 129.41 },
    { date: '07:00', value: 168.09 },
    { date: '07:15', value: 168.09 },
    { date: '07:30', value: 168.09 },
    { date: '07:45', value: 168.09 },
    { date: '08:00', value: 219.27 },
    { date: '08:15', value: 219.27 },
    { date: '08:30', value: 219.27 },
    { date: '08:45', value: 219.27 },
    { date: '09:00', value: 237.13 },
    { date: '09:15', value: 237.13 },
    { date: '09:30', value: 237.13 },
    { date: '09:45', value: 237.13 },
    { date: '10:00', value: 222.5 },
    { date: '10:15', value: 222.5 },
    { date: '10:30', value: 222.5 },
    { date: '10:45', value: 222.5 },
    { date: '11:00', value: 223.34 },
    { date: '11:15', value: 223.34 },
    { date: '11:30', value: 223.34 },
    { date: '11:45', value: 223.34 },
    { date: '12:00', value: 195.21 },
    { date: '12:15', value: 195.21 },
    { date: '12:30', value: 195.21 },
    { date: '12:45', value: 195.21 },
    { date: '13:00', value: 189.81 },
    { date: '13:15', value: 189.81 },
    { date: '13:30', value: 189.81 },
    { date: '13:45', value: 189.81 },
    { date: '14:00', value: 195.0 },
    { date: '14:15', value: 195.0 },
    { date: '14:30', value: 195.0 },
    { date: '14:45', value: 195.0 },
    { date: '15:00', value: 195.0 },
    { date: '15:15', value: 195.0 },
    { date: '15:30', value: 195.0 },
    { date: '15:45', value: 195.0 },
    { date: '16:00', value: 226.0 },
    { date: '16:15', value: 226.0 },
    { date: '16:30', value: 226.0 },
    { date: '16:45', value: 226.0 },
    { date: '17:00', value: 261.0 },
    { date: '17:15', value: 261.0 },
    { date: '17:30', value: 261.0 },
    { date: '17:45', value: 261.0 },
    { date: '18:00', value: 249.34 },
    { date: '18:15', value: 249.34 },
    { date: '18:30', value: 249.34 },
    { date: '18:45', value: 249.34 },
    { date: '19:00', value: 194.49 },
    { date: '19:15', value: 194.49 },
    { date: '19:30', value: 194.49 },
    { date: '19:45', value: 194.49 },
    { date: '20:00', value: 153.93 },
    { date: '20:15', value: 153.93 },
    { date: '20:30', value: 153.93 },
    { date: '20:45', value: 153.93 },
    { date: '21:00', value: 132.72 },
    { date: '21:15', value: 132.72 },
    { date: '21:30', value: 132.72 },
    { date: '21:45', value: 132.72 },
    { date: '22:00', value: 124.72 },
    { date: '22:15', value: 124.72 },
    { date: '22:30', value: 124.72 },
    { date: '22:45', value: 124.72 },
    { date: '23:00', value: 113.79 },
    { date: '23:15', value: 113.79 },
    { date: '23:30', value: 113.79 },
    { date: '23:45', value: 113.79 },
];

export const pricesMockData4 = [
    { date: '00:00', value: 61.33 },
    { date: '00:15', value: 61.33 },
    { date: '00:30', value: 61.33 },
    { date: '00:45', value: 61.33 },
    { date: '01:00', value: 55.48 },
    { date: '01:15', value: 55.48 },
    { date: '01:30', value: 55.48 },
    { date: '01:45', value: 55.48 },
    { date: '02:00', value: 52.24 },
    { date: '02:15', value: 52.24 },
    { date: '02:30', value: 52.24 },
    { date: '02:45', value: 52.24 },
    { date: '03:00', value: 49.57 },
    { date: '03:15', value: 49.57 },
    { date: '03:30', value: 49.57 },
    { date: '03:45', value: 49.57 },
    { date: '04:00', value: 51.16 },
    { date: '04:15', value: 51.16 },
    { date: '04:30', value: 51.16 },
    { date: '04:45', value: 51.16 },
    { date: '05:00', value: 58.72 },
    { date: '05:15', value: 58.72 },
    { date: '05:30', value: 58.72 },
    { date: '05:45', value: 58.72 },
    { date: '06:00', value: 79.65 },
    { date: '06:15', value: 79.65 },
    { date: '06:30', value: 79.65 },
    { date: '06:45', value: 79.65 },
    { date: '07:00', value: 97.8 },
    { date: '07:15', value: 97.8 },
    { date: '07:30', value: 97.8 },
    { date: '07:45', value: 97.8 },
    { date: '08:00', value: 103.44 },
    { date: '08:15', value: 103.44 },
    { date: '08:30', value: 103.44 },
    { date: '08:45', value: 103.44 },
    { date: '09:00', value: 96.4 },
    { date: '09:15', value: 96.4 },
    { date: '09:30', value: 96.4 },
    { date: '09:45', value: 96.4 },
    { date: '10:00', value: 88.92 },
    { date: '10:15', value: 88.92 },
    { date: '10:30', value: 88.92 },
    { date: '10:45', value: 88.92 },
    { date: '11:00', value: 85.13 },
    { date: '11:15', value: 85.13 },
    { date: '11:30', value: 85.13 },
    { date: '11:45', value: 85.13 },
    { date: '12:00', value: 82.17 },
    { date: '12:15', value: 82.17 },
    { date: '12:30', value: 82.17 },
    { date: '12:45', value: 82.17 },
    { date: '13:00', value: 80.22 },
    { date: '13:15', value: 80.22 },
    { date: '13:30', value: 80.22 },
    { date: '13:45', value: 80.22 },
    { date: '14:00', value: 85.81 },
    { date: '14:15', value: 85.81 },
    { date: '14:30', value: 85.81 },
    { date: '14:45', value: 85.81 },
    { date: '15:00', value: 98.93 },
    { date: '15:15', value: 98.93 },
    { date: '15:30', value: 98.93 },
    { date: '15:45', value: 98.93 },
    { date: '16:00', value: 108.92 },
    { date: '16:15', value: 108.92 },
    { date: '16:30', value: 108.92 },
    { date: '16:45', value: 108.92 },
    { date: '17:00', value: 122.31 },
    { date: '17:15', value: 122.31 },
    { date: '17:30', value: 122.31 },
    { date: '17:45', value: 122.31 },
    { date: '18:00', value: 124.89 },
    { date: '18:15', value: 124.89 },
    { date: '18:30', value: 124.89 },
    { date: '18:45', value: 124.89 },
    { date: '19:00', value: 117.37 },
    { date: '19:15', value: 117.37 },
    { date: '19:30', value: 117.37 },
    { date: '19:45', value: 117.37 },
    { date: '20:00', value: 101.19 },
    { date: '20:15', value: 101.19 },
    { date: '20:30', value: 101.19 },
    { date: '20:45', value: 101.19 },
    { date: '21:00', value: 87.71 },
    { date: '21:15', value: 87.71 },
    { date: '21:30', value: 87.71 },
    { date: '21:45', value: 87.71 },
    { date: '22:00', value: 83.92 },
    { date: '22:15', value: 83.92 },
    { date: '22:30', value: 83.92 },
    { date: '22:45', value: 83.92 },
    { date: '23:00', value: 71.42 },
    { date: '23:15', value: 71.42 },
    { date: '23:30', value: 71.42 },
    { date: '23:45', value: 71.42 },
];
export const pricesMockData3 = [
    { date: '00:00', value: 30.16 },
    { date: '00:15', value: 30.16 },
    { date: '00:30', value: 30.16 },
    { date: '00:45', value: 30.16 },
    { date: '01:00', value: 25.08 },
    { date: '01:15', value: 25.08 },
    { date: '01:30', value: 25.08 },
    { date: '01:45', value: 25.08 },
    { date: '02:00', value: 20.9 },
    { date: '02:15', value: 20.9 },
    { date: '02:30', value: 20.9 },
    { date: '02:45', value: 20.9 },
    { date: '03:00', value: 3.48 },
    { date: '03:15', value: 3.48 },
    { date: '03:30', value: 3.48 },
    { date: '03:45', value: 3.48 },
    { date: '04:00', value: 0.07 },
    { date: '04:15', value: 0.07 },
    { date: '04:30', value: 0.07 },
    { date: '04:45', value: 0.07 },
    { date: '05:00', value: 0.33 },
    { date: '05:15', value: 0.33 },
    { date: '05:30', value: 0.33 },
    { date: '05:45', value: 0.33 },
    { date: '06:00', value: 23.85 },
    { date: '06:15', value: 23.85 },
    { date: '06:30', value: 23.85 },
    { date: '06:45', value: 23.85 },
    { date: '07:00', value: 45.12 },
    { date: '07:15', value: 45.12 },
    { date: '07:30', value: 45.12 },
    { date: '07:45', value: 45.12 },
    { date: '08:00', value: 48.96 },
    { date: '08:15', value: 48.96 },
    { date: '08:30', value: 48.96 },
    { date: '08:45', value: 48.96 },
    { date: '09:00', value: 37.24 },
    { date: '09:15', value: 37.24 },
    { date: '09:30', value: 37.24 },
    { date: '09:45', value: 37.24 },
    { date: '10:00', value: 34.29 },
    { date: '10:15', value: 34.29 },
    { date: '10:30', value: 34.29 },
    { date: '10:45', value: 34.29 },
    { date: '11:00', value: 26.05 },
    { date: '11:15', value: 26.05 },
    { date: '11:30', value: 26.05 },
    { date: '11:45', value: 26.05 },
    { date: '12:00', value: 23.51 },
    { date: '12:15', value: 23.51 },
    { date: '12:30', value: 23.51 },
    { date: '12:45', value: 23.51 },
    { date: '13:00', value: 11.56 },
    { date: '13:15', value: 11.56 },
    { date: '13:30', value: 11.56 },
    { date: '13:45', value: 11.56 },
    { date: '14:00', value: 10.53 },
    { date: '14:15', value: 10.53 },
    { date: '14:30', value: 10.53 },
    { date: '14:45', value: 10.53 },
    { date: '15:00', value: 16.8 },
    { date: '15:15', value: 16.8 },
    { date: '15:30', value: 16.8 },
    { date: '15:45', value: 16.8 },
    { date: '16:00', value: 16.95 },
    { date: '16:15', value: 16.95 },
    { date: '16:30', value: 16.95 },
    { date: '16:45', value: 16.95 },
    { date: '17:00', value: 35.08 },
    { date: '17:15', value: 35.08 },
    { date: '17:30', value: 35.08 },
    { date: '17:45', value: 35.08 },
    { date: '18:00', value: 39.34 },
    { date: '18:15', value: 39.34 },
    { date: '18:30', value: 39.34 },
    { date: '18:45', value: 39.34 },
    { date: '19:00', value: 48.49 },
    { date: '19:15', value: 48.49 },
    { date: '19:30', value: 48.49 },
    { date: '19:45', value: 48.49 },
    { date: '20:00', value: 40.97 },
    { date: '20:15', value: 40.97 },
    { date: '20:30', value: 40.97 },
    { date: '20:45', value: 40.97 },
    { date: '21:00', value: 33.15 },
    { date: '21:15', value: 33.15 },
    { date: '21:30', value: 33.15 },
    { date: '21:45', value: 33.15 },
    { date: '22:00', value: 28.77 },
    { date: '22:15', value: 28.77 },
    { date: '22:30', value: 28.77 },
    { date: '22:45', value: 28.77 },
    { date: '23:00', value: 6.05 },
    { date: '23:15', value: 6.05 },
    { date: '23:30', value: 6.05 },
    { date: '23:45', value: 6.05 },
];

export const pricesMockData2 = [
    { date: '00:00', value: 3.05 },
    { date: '00:15', value: 3.05 },
    { date: '00:30', value: 3.05 },
    { date: '00:45', value: 3.05 },
    { date: '01:00', value: -0.03 },
    { date: '01:15', value: -0.03 },
    { date: '01:30', value: -0.03 },
    { date: '01:45', value: -0.03 },
    { date: '02:00', value: -0.64 },
    { date: '02:15', value: -0.64 },
    { date: '02:30', value: -0.64 },
    { date: '02:45', value: -0.64 },
    { date: '03:00', value: -4.41 },
    { date: '03:15', value: -4.41 },
    { date: '03:30', value: -4.41 },
    { date: '03:45', value: -4.41 },
    { date: '04:00', value: -4.84 },
    { date: '04:15', value: -4.84 },
    { date: '04:30', value: -4.84 },
    { date: '04:45', value: -4.84 },
    { date: '05:00', value: -0.18 },
    { date: '05:15', value: -0.18 },
    { date: '05:30', value: -0.18 },
    { date: '05:45', value: -0.18 },
    { date: '06:00', value: 14.76 },
    { date: '06:15', value: 14.76 },
    { date: '06:30', value: 14.76 },
    { date: '06:45', value: 14.76 },
    { date: '07:00', value: 42.09 },
    { date: '07:15', value: 42.09 },
    { date: '07:30', value: 42.09 },
    { date: '07:45', value: 42.09 },
    { date: '08:00', value: 51.58 },
    { date: '08:15', value: 51.58 },
    { date: '08:30', value: 51.58 },
    { date: '08:45', value: 51.58 },
    { date: '09:00', value: 50.76 },
    { date: '09:15', value: 50.76 },
    { date: '09:30', value: 50.76 },
    { date: '09:45', value: 50.76 },
    { date: '10:00', value: 50.1 },
    { date: '10:15', value: 50.1 },
    { date: '10:30', value: 50.1 },
    { date: '10:45', value: 50.1 },
    { date: '11:00', value: 50.78 },
    { date: '11:15', value: 50.78 },
    { date: '11:30', value: 50.78 },
    { date: '11:45', value: 50.78 },
    { date: '12:00', value: 39.32 },
    { date: '12:15', value: 39.32 },
    { date: '12:30', value: 39.32 },
    { date: '12:45', value: 39.32 },
    { date: '13:00', value: 36.44 },
    { date: '13:15', value: 36.44 },
    { date: '13:30', value: 36.44 },
    { date: '13:45', value: 36.44 },
    { date: '14:00', value: 45.04 },
    { date: '14:15', value: 45.04 },
    { date: '14:30', value: 45.04 },
    { date: '14:45', value: 45.04 },
    { date: '15:00', value: 49.95 },
    { date: '15:15', value: 49.95 },
    { date: '15:30', value: 49.95 },
    { date: '15:45', value: 49.95 },
    { date: '16:00', value: 50.8 },
    { date: '16:15', value: 50.8 },
    { date: '16:30', value: 50.8 },
    { date: '16:45', value: 50.8 },
    { date: '17:00', value: 54.21 },
    { date: '17:15', value: 54.21 },
    { date: '17:30', value: 54.21 },
    { date: '17:45', value: 54.21 },
    { date: '18:00', value: 53.32 },
    { date: '18:15', value: 53.32 },
    { date: '18:30', value: 53.32 },
    { date: '18:45', value: 53.32 },
    { date: '19:00', value: 50.09 },
    { date: '19:15', value: 50.09 },
    { date: '19:30', value: 50.09 },
    { date: '19:45', value: 50.09 },
    { date: '20:00', value: 42.18 },
    { date: '20:15', value: 42.18 },
    { date: '20:30', value: 42.18 },
    { date: '20:45', value: 42.18 },
    { date: '21:00', value: 40.04 },
    { date: '21:15', value: 40.04 },
    { date: '21:30', value: 40.04 },
    { date: '21:45', value: 40.04 },
    { date: '22:00', value: 42.01 },
    { date: '22:15', value: 42.01 },
    { date: '22:30', value: 42.01 },
    { date: '22:45', value: 42.01 },
    { date: '23:00', value: 38.53 },
    { date: '23:15', value: 38.53 },
    { date: '23:30', value: 38.53 },
    { date: '23:45', value: 38.53 },
];

export const pricesMockData1 = [
    { date: '00:00', value: 14.05 },
    { date: '00:15', value: 14.05 },
    { date: '00:30', value: 14.05 },
    { date: '00:45', value: 14.05 },
    { date: '01:00', value: -0.96 },
    { date: '01:15', value: -0.96 },
    { date: '01:30', value: -0.96 },
    { date: '01:45', value: -0.96 },
    { date: '02:00', value: -0.02 },
    { date: '02:15', value: -0.02 },
    { date: '02:30', value: -0.02 },
    { date: '02:45', value: -0.02 },
    { date: '03:00', value: -0.24 },
    { date: '03:15', value: -0.24 },
    { date: '03:30', value: -0.24 },
    { date: '03:45', value: -0.24 },
    { date: '04:00', value: -0.28 },
    { date: '04:15', value: -0.28 },
    { date: '04:30', value: -0.28 },
    { date: '04:45', value: -0.28 },
    { date: '05:00', value: -0.39 },
    { date: '05:15', value: -0.39 },
    { date: '05:30', value: -0.39 },
    { date: '05:45', value: -0.39 },
    { date: '06:00', value: -0.36 },
    { date: '06:15', value: -0.36 },
    { date: '06:30', value: -0.36 },
    { date: '06:45', value: -0.36 },
    { date: '07:00', value: -0.07 },
    { date: '07:15', value: -0.07 },
    { date: '07:30', value: -0.07 },
    { date: '07:45', value: -0.07 },
    { date: '08:00', value: 0.01 },
    { date: '08:15', value: 0.01 },
    { date: '08:30', value: 0.01 },
    { date: '08:45', value: 0.01 },
    { date: '09:00', value: 0.0 },
    { date: '09:15', value: 0.0 },
    { date: '09:30', value: 0.0 },
    { date: '09:45', value: 0.0 },
    { date: '10:00', value: -0.21 },
    { date: '10:15', value: -0.21 },
    { date: '10:30', value: -0.21 },
    { date: '10:45', value: -0.21 },
    { date: '11:00', value: -0.08 },
    { date: '11:15', value: -0.08 },
    { date: '11:30', value: -0.08 },
    { date: '11:45', value: -0.08 },
    { date: '12:00', value: -0.03 },
    { date: '12:15', value: -0.03 },
    { date: '12:30', value: -0.03 },
    { date: '12:45', value: -0.03 },
    { date: '13:00', value: 0.61 },
    { date: '13:15', value: 0.61 },
    { date: '13:30', value: 0.61 },
    { date: '13:45', value: 0.61 },
    { date: '14:00', value: 10.03 },
    { date: '14:15', value: 10.03 },
    { date: '14:30', value: 10.03 },
    { date: '14:45', value: 10.03 },
    { date: '15:00', value: 40.03 },
    { date: '15:15', value: 40.03 },
    { date: '15:30', value: 40.03 },
    { date: '15:45', value: 40.03 },
    { date: '16:00', value: 76.92 },
    { date: '16:15', value: 76.92 },
    { date: '16:30', value: 76.92 },
    { date: '16:45', value: 76.92 },
    { date: '17:00', value: 83.9 },
    { date: '17:15', value: 83.9 },
    { date: '17:30', value: 83.9 },
    { date: '17:45', value: 83.9 },
    { date: '18:00', value: 76.98 },
    { date: '18:15', value: 76.98 },
    { date: '18:30', value: 76.98 },
    { date: '18:45', value: 76.98 },
    { date: '19:00', value: 67.37 },
    { date: '19:15', value: 67.37 },
    { date: '19:30', value: 67.37 },
    { date: '19:45', value: 67.37 },
    { date: '20:00', value: 62.16 },
    { date: '20:15', value: 62.16 },
    { date: '20:30', value: 62.16 },
    { date: '20:45', value: 62.16 },
    { date: '21:00', value: 54.02 },
    { date: '21:15', value: 54.02 },
    { date: '21:30', value: 54.02 },
    { date: '21:45', value: 54.02 },
    { date: '22:00', value: 51.94 },
    { date: '22:15', value: 51.94 },
    { date: '22:30', value: 51.94 },
    { date: '22:45', value: 51.94 },
    { date: '23:00', value: 38.41 },
    { date: '23:15', value: 38.41 },
    { date: '23:30', value: 38.41 },
    { date: '23:45', value: 38.41 },
];

export const steinthermeBadBelzigMarchMockData = [
    { date: 'Fri Mar 01 2024', value: 342.48 },
    { date: 'Sat Mar 02 2024', value: 1958.93 },
    { date: 'Sun Mar 03 2024', value: 1916.25 },
    { date: 'Mon Mar 04 2024', value: 372.59 },
    { date: 'Tue Mar 05 2024', value: 184.34 },
    { date: 'Wed Mar 06 2024', value: 253.46 },
    { date: 'Thu Mar 07 2024', value: 195.97 },
    { date: 'Fri Mar 08 2024', value: 335.27 },
    { date: 'Sat Mar 09 2024', value: 198.44 },
    { date: 'Sun Mar 10 2024', value: 369.4 },
    { date: 'Mon Mar 11 2024', value: 402.47 },
    { date: 'Tue Mar 12 2024', value: 261.37 },
    { date: 'Wed Mar 13 2024', value: 283.91 },
    { date: 'Thu Mar 14 2024', value: 320.03 },
    { date: 'Fri Mar 15 2024', value: 490.21 },
    { date: 'Sat Mar 16 2024', value: 1900.9 },
    { date: 'Sun Mar 17 2024', value: 1779.01 },
    { date: 'Mon Mar 18 2024', value: 249.66 },
    { date: 'Tue Mar 19 2024', value: 240.99 },
    { date: 'Wed Mar 20 2024', value: 363.48 },
    { date: 'Thu Mar 21 2024', value: 466.94 },
    { date: 'Fri Mar 22 2024', value: 628.47 },
    { date: 'Sat Mar 23 2024', value: 464.69 },
    { date: 'Sun Mar 24 2024', value: 347.82 },
    { date: 'Mon Mar 25 2024', value: 343.02 },
    { date: 'Tue Mar 26 2024', value: 324.71 },
    { date: 'Wed Mar 27 2024', value: 391.9 },
    { date: 'Thu Mar 28 2024', value: 321.37 },
    { date: 'Fri Mar 29 2024', value: 342.36 },
    { date: 'Sat Mar 30 2024', value: 216.23 },
];

export const steinthermeBadBelzigAprilMockData = [
    { date: 'Mon Apr 01 2024', value: 244.67 },
    { date: 'Tue Apr 02 2024', value: 342.95 },
    { date: 'Wed Apr 03 2024', value: 282.41 },
    { date: 'Thu Apr 04 2024', value: 297.53 },
    { date: 'Fri Apr 05 2024', value: 313.49 },
    { date: 'Sat Apr 06 2024', value: 199.01 },
    { date: 'Sun Apr 07 2024', value: 230.04 },
    { date: 'Mon Apr 08 2024', value: 603.21 },
    { date: 'Tue Apr 09 2024', value: 270.29 },
    { date: 'Wed Apr 10 2024', value: 185.04 },
    { date: 'Thu Apr 11 2024', value: 175.11 },
    { date: 'Fri Apr 12 2024', value: 212.94 },
    { date: 'Sat Apr 13 2024', value: 1113.43 },
    { date: 'Sun Apr 14 2024', value: 143.4 },
    { date: 'Mon Apr 15 2024', value: 183.72 },
    { date: 'Tue Apr 16 2024', value: 200.58 },
    { date: 'Wed Apr 17 2024', value: 353.16 },
    { date: 'Thu Apr 18 2024', value: 233.47 },
    { date: 'Fri Apr 19 2024', value: 637.48 },
    { date: 'Sat Apr 20 2024', value: 251.92 },
    { date: 'Sun Apr 21 2024', value: 405.35 },
    { date: 'Mon Apr 22 2024', value: 343.44 },
    { date: 'Tue Apr 23 2024', value: 452.94 },
    { date: 'Wed Apr 24 2024', value: 764.9 },
    { date: 'Thu Apr 25 2024', value: 1758.62 },
    { date: 'Fri Apr 26 2024', value: 2072.84 },
    { date: 'Sat Apr 27 2024', value: 1890.75 },
    { date: 'Sun Apr 28 2024', value: 1950.65 },
    { date: 'Mon Apr 29 2024', value: 1612.8 },
    { date: 'Tue Apr 30 2024', value: 2101.24 },
];

export const pvGenerationMockData = [
    { date: '2024-05-15 00:00:00', value: 0 },
    { date: '2024-05-15 00:05:00', value: 0 },
    { date: '2024-05-15 00:10:00', value: 0 },
    { date: '2024-05-15 00:15:00', value: 0 },
    { date: '2024-05-15 00:20:00', value: 0 },
    { date: '2024-05-15 00:25:00', value: 0 },
    { date: '2024-05-15 00:30:00', value: 0 },
    { date: '2024-05-15 00:35:00', value: 0 },
    { date: '2024-05-15 00:40:00', value: 0 },
    { date: '2024-05-15 00:45:00', value: 0 },
    { date: '2024-05-15 00:50:00', value: 0 },
    { date: '2024-05-15 00:55:00', value: 0 },
    { date: '2024-05-15 01:00:00', value: 0 },
    { date: '2024-05-15 01:05:00', value: 0 },
    { date: '2024-05-15 01:10:00', value: 0 },
    { date: '2024-05-15 01:15:00', value: 0 },
    { date: '2024-05-15 01:20:00', value: 0 },
    { date: '2024-05-15 01:25:00', value: 0 },
    { date: '2024-05-15 01:30:00', value: 0 },
    { date: '2024-05-15 01:35:00', value: 0 },
    { date: '2024-05-15 01:40:00', value: 0 },
    { date: '2024-05-15 01:45:00', value: 0 },
    { date: '2024-05-15 01:50:00', value: 0 },
    { date: '2024-05-15 01:55:00', value: 0 },
    { date: '2024-05-15 02:00:00', value: 0 },
    { date: '2024-05-15 02:05:00', value: 0 },
    { date: '2024-05-15 02:10:00', value: 0 },
    { date: '2024-05-15 02:15:00', value: 0 },
    { date: '2024-05-15 02:20:00', value: 0 },
    { date: '2024-05-15 02:25:00', value: 0 },
    { date: '2024-05-15 02:30:00', value: 0 },
    { date: '2024-05-15 02:35:00', value: 0 },
    { date: '2024-05-15 02:40:00', value: 0 },
    { date: '2024-05-15 02:45:00', value: 0 },
    { date: '2024-05-15 02:50:00', value: 0 },
    { date: '2024-05-15 02:55:00', value: 0 },
    { date: '2024-05-15 03:00:00', value: 0 },
    { date: '2024-05-15 03:05:00', value: 0 },
    { date: '2024-05-15 03:10:00', value: 0 },
    { date: '2024-05-15 03:15:00', value: 0 },
    { date: '2024-05-15 03:20:00', value: 0 },
    { date: '2024-05-15 03:25:00', value: 0 },
    { date: '2024-05-15 03:30:00', value: 0 },
    { date: '2024-05-15 03:35:00', value: 0 },
    { date: '2024-05-15 03:40:00', value: 0 },
    { date: '2024-05-15 03:45:00', value: 0 },
    { date: '2024-05-15 03:50:00', value: 0 },
    { date: '2024-05-15 03:55:00', value: 0 },
    { date: '2024-05-15 04:00:00', value: 0 },
    { date: '2024-05-15 04:05:00', value: 0 },
    { date: '2024-05-15 04:10:00', value: 0 },
    { date: '2024-05-15 04:15:00', value: 0 },
    { date: '2024-05-15 04:20:00', value: 0 },
    { date: '2024-05-15 04:25:00', value: 0 },
    { date: '2024-05-15 04:30:00', value: 0 },
    { date: '2024-05-15 04:35:00', value: 0 },
    { date: '2024-05-15 04:40:00', value: 0 },
    { date: '2024-05-15 04:45:00', value: 0 },
    { date: '2024-05-15 04:50:00', value: 0 },
    { date: '2024-05-15 04:55:00', value: 0 },
    { date: '2024-05-15 05:00:00', value: 0 },
    { date: '2024-05-15 05:05:00', value: 0 },
    { date: '2024-05-15 05:10:00', value: 0 },
    { date: '2024-05-15 05:15:00', value: 0 },
    { date: '2024-05-15 05:20:00', value: 0 },
    { date: '2024-05-15 05:25:00', value: 0 },
    { date: '2024-05-15 05:30:00', value: 0 },
    { date: '2024-05-15 05:35:00', value: 0 },
    { date: '2024-05-15 05:40:00', value: 492 },
    { date: '2024-05-15 05:45:00', value: 1128 },
    { date: '2024-05-15 05:50:00', value: 2052 },
    { date: '2024-05-15 05:55:00', value: 3528 },
    { date: '2024-05-15 06:00:00', value: 5856 },
    { date: '2024-05-15 06:05:00', value: 8556 },
    { date: '2024-05-15 06:10:00', value: 10356 },
    { date: '2024-05-15 06:15:00', value: 12864 },
    { date: '2024-05-15 06:20:00', value: 17100 },
    { date: '2024-05-15 06:25:00', value: 25368 },
    { date: '2024-05-15 06:30:00', value: 35520 },
    { date: '2024-05-15 06:35:00', value: 39120 },
    { date: '2024-05-15 06:40:00', value: 40680 },
    { date: '2024-05-15 06:45:00', value: 45144 },
    { date: '2024-05-15 06:50:00', value: 56016 },
    { date: '2024-05-15 06:55:00', value: 72996 },
    { date: '2024-05-15 07:00:00', value: 81408 },
    { date: '2024-05-15 07:05:00', value: 65688 },
    { date: '2024-05-15 07:10:00', value: 112248 },
    { date: '2024-05-15 07:15:00', value: 92028 },
    { date: '2024-05-15 07:20:00', value: 118716 },
    { date: '2024-05-15 07:25:00', value: 154512 },
    { date: '2024-05-15 07:30:00', value: 163308 },
    { date: '2024-05-15 07:35:00', value: 160392 },
    { date: '2024-05-15 07:40:00', value: 159288 },
    { date: '2024-05-15 07:45:00', value: 157536 },
    { date: '2024-05-15 07:50:00', value: 167736 },
    { date: '2024-05-15 07:55:00', value: 173112 },
    { date: '2024-05-15 08:00:00', value: 141672 },
    { date: '2024-05-15 08:05:00', value: 223140 },
    { date: '2024-05-15 08:10:00', value: 220380 },
    { date: '2024-05-15 08:15:00', value: 245232 },
    { date: '2024-05-15 08:20:00', value: 251040 },
    { date: '2024-05-15 08:25:00', value: 201984 },
    { date: '2024-05-15 08:30:00', value: 207552 },
    { date: '2024-05-15 08:35:00', value: 262452 },
    { date: '2024-05-15 08:40:00', value: 260772 },
    { date: '2024-05-15 08:45:00', value: 248040 },
    { date: '2024-05-15 08:50:00', value: 259548 },
    { date: '2024-05-15 08:55:00', value: 229596 },
    { date: '2024-05-15 09:00:00', value: 277632 },
    { date: '2024-05-15 09:05:00', value: 326532 },
    { date: '2024-05-15 09:10:00', value: 345132 },
    { date: '2024-05-15 09:15:00', value: 321996 },
    { date: '2024-05-15 09:20:00', value: 377400 },
    { date: '2024-05-15 09:25:00', value: 377196 },
    { date: '2024-05-15 09:30:00', value: 345312 },
    { date: '2024-05-15 09:35:00', value: 381792 },
    { date: '2024-05-15 09:40:00', value: 403512 },
    { date: '2024-05-15 09:45:00', value: 406752 },
    { date: '2024-05-15 09:50:00', value: 388212 },
    { date: '2024-05-15 09:55:00', value: 369216 },
    { date: '2024-05-15 10:00:00', value: 360324 },
    { date: '2024-05-15 10:05:00', value: 398292 },
    { date: '2024-05-15 10:10:00', value: 404844 },
    { date: '2024-05-15 10:15:00', value: 495948 },
    { date: '2024-05-15 10:20:00', value: 511896 },
    { date: '2024-05-15 10:25:00', value: 491556 },
    { date: '2024-05-15 10:30:00', value: 333096 },
    { date: '2024-05-15 10:35:00', value: 490200 },
    { date: '2024-05-15 10:40:00', value: 450372 },
    { date: '2024-05-15 10:45:00', value: 391716 },
    { date: '2024-05-15 10:50:00', value: 455052 },
    { date: '2024-05-15 10:55:00', value: 442344 },
    { date: '2024-05-15 11:00:00', value: 418752 },
    { date: '2024-05-15 11:05:00', value: 346596 },
    { date: '2024-05-15 11:10:00', value: 353112 },
    { date: '2024-05-15 11:15:00', value: 450120 },
    { date: '2024-05-15 11:20:00', value: 491280 },
    { date: '2024-05-15 11:25:00', value: 452208 },
    { date: '2024-05-15 11:30:00', value: 405588 },
    { date: '2024-05-15 11:35:00', value: 463032 },
    { date: '2024-05-15 11:40:00', value: 478596 },
    { date: '2024-05-15 11:45:00', value: 467700 },
    { date: '2024-05-15 11:50:00', value: 447912 },
    { date: '2024-05-15 11:55:00', value: 465840 },
    { date: '2024-05-15 12:00:00', value: 428052 },
    { date: '2024-05-15 12:05:00', value: 572892 },
    { date: '2024-05-15 12:10:00', value: 558984 },
    { date: '2024-05-15 12:15:00', value: 593700 },
    { date: '2024-05-15 12:20:00', value: 571680 },
    { date: '2024-05-15 12:25:00', value: 533988 },
    { date: '2024-05-15 12:30:00', value: 515544 },
    { date: '2024-05-15 12:35:00', value: 543612 },
    { date: '2024-05-15 12:40:00', value: 552696 },
    { date: '2024-05-15 12:45:00', value: 483756 },
    { date: '2024-05-15 12:50:00', value: 483384 },
    { date: '2024-05-15 12:55:00', value: 440124 },
    { date: '2024-05-15 13:00:00', value: 366324 },
    { date: '2024-05-15 13:05:00', value: 477540 },
    { date: '2024-05-15 13:10:00', value: 532860 },
    { date: '2024-05-15 13:15:00', value: 464928 },
    { date: '2024-05-15 13:20:00', value: 571140 },
    { date: '2024-05-15 13:25:00', value: 520932 },
    { date: '2024-05-15 13:30:00', value: 341844 },
    { date: '2024-05-15 13:35:00', value: 322560 },
    { date: '2024-05-15 13:40:00', value: 329520 },
    { date: '2024-05-15 13:45:00', value: 380040 },
    { date: '2024-05-15 13:50:00', value: 446196 },
    { date: '2024-05-15 13:55:00', value: 440340 },
    { date: '2024-05-15 14:00:00', value: 407388 },
    { date: '2024-05-15 14:05:00', value: 492960 },
    { date: '2024-05-15 14:10:00', value: 547440 },
    { date: '2024-05-15 14:15:00', value: 563412 },
    { date: '2024-05-15 14:20:00', value: 412392 },
    { date: '2024-05-15 14:25:00', value: 339372 },
    { date: '2024-05-15 14:30:00', value: 304656 },
    { date: '2024-05-15 14:35:00', value: 373284 },
    { date: '2024-05-15 14:40:00', value: 330444 },
    { date: '2024-05-15 14:45:00', value: 438996 },
    { date: '2024-05-15 14:50:00', value: 369936 },
    { date: '2024-05-15 14:55:00', value: 593412 },
    { date: '2024-05-15 15:00:00', value: 306276 },
    { date: '2024-05-15 15:05:00', value: 243744 },
    { date: '2024-05-15 15:10:00', value: 440628 },
    { date: '2024-05-15 15:15:00', value: 427968 },
    { date: '2024-05-15 15:20:00', value: 294528 },
    { date: '2024-05-15 15:25:00', value: 285648 },
    { date: '2024-05-15 15:30:00', value: 381144 },
    { date: '2024-05-15 15:35:00', value: 243144 },
    { date: '2024-05-15 15:40:00', value: 256164 },
    { date: '2024-05-15 15:45:00', value: 234972 },
    { date: '2024-05-15 15:50:00', value: 220152 },
    { date: '2024-05-15 15:55:00', value: 234384 },
    { date: '2024-05-15 16:00:00', value: 271452 },
    { date: '2024-05-15 16:05:00', value: 239160 },
    { date: '2024-05-15 16:10:00', value: 194952 },
    { date: '2024-05-15 16:15:00', value: 243024 },
    { date: '2024-05-15 16:20:00', value: 308916 },
    { date: '2024-05-15 16:25:00', value: 226680 },
    { date: '2024-05-15 16:30:00', value: 229656 },
    { date: '2024-05-15 16:35:00', value: 203532 },
    { date: '2024-05-15 16:40:00', value: 165288 },
    { date: '2024-05-15 16:45:00', value: 150828 },
    { date: '2024-05-15 16:50:00', value: 128748 },
    { date: '2024-05-15 16:55:00', value: 119892 },
    { date: '2024-05-15 17:00:00', value: 119688 },
    { date: '2024-05-15 17:05:00', value: 153000 },
    { date: '2024-05-15 17:10:00', value: 137880 },
    { date: '2024-05-15 17:15:00', value: 133620 },
    { date: '2024-05-15 17:20:00', value: 116916 },
    { date: '2024-05-15 17:25:00', value: 109392 },
    { date: '2024-05-15 17:30:00', value: 133764 },
    { date: '2024-05-15 17:35:00', value: 149772 },
    { date: '2024-05-15 17:40:00', value: 124428 },
    { date: '2024-05-15 17:45:00', value: 117468 },
    { date: '2024-05-15 17:50:00', value: 117048 },
    { date: '2024-05-15 17:55:00', value: 93816 },
    { date: '2024-05-15 18:00:00', value: 70356 },
    { date: '2024-05-15 18:05:00', value: 59736 },
    { date: '2024-05-15 18:10:00', value: 60864 },
    { date: '2024-05-15 18:15:00', value: 70272 },
    { date: '2024-05-15 18:20:00', value: 76692 },
    { date: '2024-05-15 18:25:00', value: 87624 },
    { date: '2024-05-15 18:30:00', value: 102132 },
    { date: '2024-05-15 18:35:00', value: 85644 },
    { date: '2024-05-15 18:40:00', value: 72612 },
    { date: '2024-05-15 18:45:00', value: 57348 },
    { date: '2024-05-15 18:50:00', value: 38292 },
    { date: '2024-05-15 18:55:00', value: 39036 },
    { date: '2024-05-15 19:00:00', value: 53952 },
    { date: '2024-05-15 19:05:00', value: 55620 },
    { date: '2024-05-15 19:10:00', value: 49800 },
    { date: '2024-05-15 19:15:00', value: 43320 },
    { date: '2024-05-15 19:20:00', value: 40464 },
    { date: '2024-05-15 19:25:00', value: 30516 },
    { date: '2024-05-15 19:30:00', value: 18948 },
    { date: '2024-05-15 19:35:00', value: 12048 },
    { date: '2024-05-15 19:40:00', value: 10236 },
    { date: '2024-05-15 19:45:00', value: 10872 },
    { date: '2024-05-15 19:50:00', value: 14748 },
    { date: '2024-05-15 19:55:00', value: 10788 },
    { date: '2024-05-15 20:00:00', value: 7140 },
    { date: '2024-05-15 20:05:00', value: 9804 },
    { date: '2024-05-15 20:10:00', value: 10800 },
    { date: '2024-05-15 20:15:00', value: 7476 },
    { date: '2024-05-15 20:20:00', value: 7404 },
    { date: '2024-05-15 20:25:00', value: 8712 },
    { date: '2024-05-15 20:30:00', value: 10968 },
    { date: '2024-05-15 20:35:00', value: 11832 },
    { date: '2024-05-15 20:40:00', value: 14376 },
    { date: '2024-05-15 20:45:00', value: 14400 },
    { date: '2024-05-15 20:50:00', value: 12984 },
    { date: '2024-05-15 20:55:00', value: 11376 },
    { date: '2024-05-15 21:00:00', value: 10800 },
    { date: '2024-05-15 21:05:00', value: 7188 },
    { date: '2024-05-15 21:10:00', value: 4560 },
    { date: '2024-05-15 21:15:00', value: 1776 },
    { date: '2024-05-15 21:20:00', value: 156 },
    { date: '2024-05-15 21:25:00', value: 0 },
    { date: '2024-05-15 21:30:00', value: 0 },
    { date: '2024-05-15 21:35:00', value: 0 },
    { date: '2024-05-15 21:40:00', value: 0 },
    { date: '2024-05-15 21:45:00', value: 0 },
    { date: '2024-05-15 21:50:00', value: 0 },
    { date: '2024-05-15 21:55:00', value: 0 },
    { date: '2024-05-15 22:00:00', value: 0 },
    { date: '2024-05-15 22:05:00', value: 0 },
    { date: '2024-05-15 22:10:00', value: 0 },
    { date: '2024-05-15 22:15:00', value: 0 },
    { date: '2024-05-15 22:20:00', value: 0 },
    { date: '2024-05-15 22:25:00', value: 0 },
    { date: '2024-05-15 22:30:00', value: 0 },
    { date: '2024-05-15 22:35:00', value: 0 },
    { date: '2024-05-15 22:40:00', value: 0 },
    { date: '2024-05-15 22:45:00', value: 0 },
    { date: '2024-05-15 22:50:00', value: 0 },
    { date: '2024-05-15 22:55:00', value: 0 },
    { date: '2024-05-15 23:00:00', value: 0 },
    { date: '2024-05-15 23:05:00', value: 0 },
    { date: '2024-05-15 23:10:00', value: 0 },
    { date: '2024-05-15 23:15:00', value: 0 },
    { date: '2024-05-15 23:20:00', value: 0 },
    { date: '2024-05-15 23:25:00', value: 0 },
    { date: '2024-05-15 23:30:00', value: 0 },
    { date: '2024-05-15 23:35:00', value: 0 },
    { date: '2024-05-15 23:40:00', value: 0 },
    { date: '2024-05-15 23:45:00', value: 0 },
    { date: '2024-05-15 23:50:00', value: 0 },
    { date: '2024-05-15 23:55:00', value: 0 },
].reduce(
    (prevValue, curValue, index, array) => {
        if ((index + 1) % 3 === 0) {
            prevValue.push({
                date: array[index - 2].date,
                value: (((curValue.value + array[index - 1].value + array[index - 2].value) / 3) * 0.25) / 1000,
            });
        }

        return prevValue;
    },
    [] as { date: string; value: number }[],
);

export const setexEnergyCostsMockData = [
    {
        date: '2024-04-11 00:00:00+00',
        value: 54.1,
    },
    {
        date: '2024-04-11 00:15:00+00',
        value: 54.91,
    },
    {
        date: '2024-04-11 00:30:00+00',
        value: 56.21,
    },
    {
        date: '2024-04-11 00:45:00+00',
        value: 56.82,
    },
    {
        date: '2024-04-11 01:00:00+00',
        value: 58.5,
    },
    {
        date: '2024-04-11 01:15:00+00',
        value: 60.84,
    },
    {
        date: '2024-04-11 01:30:00+00',
        value: 62.3,
    },
    {
        date: '2024-04-11 01:45:00+00',
        value: 60.9,
    },
    {
        date: '2024-04-11 02:00:00+00',
        value: 60.84,
    },
    {
        date: '2024-04-11 02:15:00+00',
        value: 60.43,
    },
    {
        date: '2024-04-11 02:30:00+00',
        value: 62.13,
    },
    {
        date: '2024-04-11 02:45:00+00',
        value: 63.13,
    },
    {
        date: '2024-04-11 03:00:00+00',
        value: 64.14,
    },
    {
        date: '2024-04-11 03:15:00+00',
        value: 63.69,
    },
    {
        date: '2024-04-11 03:30:00+00',
        value: 63.52,
    },
    {
        date: '2024-04-11 03:45:00+00',
        value: 65.69,
    },
    {
        date: '2024-04-11 04:00:00+00',
        value: 70.37,
    },
    {
        date: '2024-04-11 04:15:00+00',
        value: 73.75,
    },
    {
        date: '2024-04-11 04:30:00+00',
        value: 73.42,
    },
    {
        date: '2024-04-11 04:45:00+00',
        value: 70.58,
    },
    {
        date: '2024-04-11 05:00:00+00',
        value: 72.95,
    },
    {
        date: '2024-04-11 05:15:00+00',
        value: 74.62,
    },
    {
        date: '2024-04-11 05:30:00+00',
        value: 76.85,
    },
    {
        date: '2024-04-11 05:45:00+00',
        value: 78.47,
    },
    {
        date: '2024-04-11 06:00:00+00',
        value: 75.36,
    },
    {
        date: '2024-04-11 06:15:00+00',
        value: 71.87,
    },
    {
        date: '2024-04-11 06:30:00+00',
        value: 69.17,
    },
    {
        date: '2024-04-11 06:45:00+00',
        value: 68.05,
    },
    {
        date: '2024-04-11 07:00:00+00',
        value: 63.73,
    },
    {
        date: '2024-04-11 07:15:00+00',
        value: 63.8,
    },
    {
        date: '2024-04-11 07:30:00+00',
        value: 58.68,
    },
    {
        date: '2024-04-11 07:45:00+00',
        value: 59.0,
    },
    {
        date: '2024-04-11 08:00:00+00',
        value: 49.83,
    },
    {
        date: '2024-04-11 08:15:00+00',
        value: 54.14,
    },
    {
        date: '2024-04-11 08:30:00+00',
        value: 52.21,
    },
    {
        date: '2024-04-11 08:45:00+00',
        value: 50.1,
    },
    {
        date: '2024-04-11 09:00:00+00',
        value: 46.17,
    },
    {
        date: '2024-04-11 09:15:00+00',
        value: 43.15,
    },
    {
        date: '2024-04-11 09:30:00+00',
        value: 39.4,
    },
    {
        date: '2024-04-11 09:45:00+00',
        value: 39.32,
    },
    {
        date: '2024-04-11 10:00:00+00',
        value: 38.17,
    },
    {
        date: '2024-04-11 10:15:00+00',
        value: 44.4,
    },
    {
        date: '2024-04-11 10:30:00+00',
        value: 49.27,
    },
    {
        date: '2024-04-11 10:45:00+00',
        value: 48.19,
    },
    {
        date: '2024-04-11 11:00:00+00',
        value: 44.88,
    },
    {
        date: '2024-04-11 11:15:00+00',
        value: 51.76,
    },
    {
        date: '2024-04-11 11:30:00+00',
        value: 53.68,
    },
    {
        date: '2024-04-11 11:45:00+00',
        value: 45.1,
    },
    {
        date: '2024-04-11 12:00:00+00',
        value: 46.88,
    },
    {
        date: '2024-04-11 12:15:00+00',
        value: 47.51,
    },
    {
        date: '2024-04-11 12:30:00+00',
        value: 51.28,
    },
    {
        date: '2024-04-11 12:45:00+00',
        value: 42.75,
    },
    {
        date: '2024-04-11 13:00:00+00',
        value: 46.9,
    },
    {
        date: '2024-04-11 13:15:00+00',
        value: 48.31,
    },
    {
        date: '2024-04-11 13:30:00+00',
        value: 46.77,
    },
    {
        date: '2024-04-11 13:45:00+00',
        value: 49.22,
    },
    {
        date: '2024-04-11 14:00:00+00',
        value: 59.65,
    },
    {
        date: '2024-04-11 14:15:00+00',
        value: 60.66,
    },
    {
        date: '2024-04-11 14:30:00+00',
        value: 61.13,
    },
    {
        date: '2024-04-11 14:45:00+00',
        value: 60.03,
    },
    {
        date: '2024-04-11 15:00:00+00',
        value: 62.64,
    },
    {
        date: '2024-04-11 15:15:00+00',
        value: 63.08,
    },
    {
        date: '2024-04-11 15:30:00+00',
        value: 65.97,
    },
    {
        date: '2024-04-11 15:45:00+00',
        value: 67.26,
    },
    {
        date: '2024-04-11 16:00:00+00',
        value: 78.94,
    },
    {
        date: '2024-04-11 16:15:00+00',
        value: 77.37,
    },
    {
        date: '2024-04-11 16:30:00+00',
        value: 78.24,
    },
    {
        date: '2024-04-11 16:45:00+00',
        value: 77.34,
    },
    {
        date: '2024-04-11 17:00:00+00',
        value: 99.46,
    },
    {
        date: '2024-04-11 17:15:00+00',
        value: 97.51,
    },
    {
        date: '2024-04-11 17:30:00+00',
        value: 95.32,
    },
    {
        date: '2024-04-11 17:45:00+00',
        value: 94.93,
    },
    {
        date: '2024-04-11 18:00:00+00',
        value: 100.83,
    },
    {
        date: '2024-04-11 18:15:00+00',
        value: 104.97,
    },
    {
        date: '2024-04-11 18:30:00+00',
        value: 104.45,
    },
    {
        date: '2024-04-11 18:45:00+00',
        value: 100.77,
    },
    {
        date: '2024-04-11 19:00:00+00',
        value: 77.99,
    },
    {
        date: '2024-04-11 19:15:00+00',
        value: 80.69,
    },
    {
        date: '2024-04-11 19:30:00+00',
        value: 82.71,
    },
    {
        date: '2024-04-11 19:45:00+00',
        value: 76.61,
    },
    {
        date: '2024-04-11 20:00:00+00',
        value: 72.67,
    },
    {
        date: '2024-04-11 20:15:00+00',
        value: 75.46,
    },
    {
        date: '2024-04-11 20:30:00+00',
        value: 74.48,
    },
    {
        date: '2024-04-11 20:45:00+00',
        value: 74.75,
    },
    {
        date: '2024-04-11 21:00:00+00',
        value: 70.87,
    },
    {
        date: '2024-04-11 21:15:00+00',
        value: 68.02,
    },
    {
        date: '2024-04-11 21:30:00+00',
        value: 68.16,
    },
    {
        date: '2024-04-11 21:45:00+00',
        value: 69.62,
    },
    {
        date: '2024-04-11 22:00:00+00',
        value: 68.72,
    },
    {
        date: '2024-04-11 22:15:00+00',
        value: 68.26,
    },
    {
        date: '2024-04-11 22:30:00+00',
        value: 64.06,
    },
    {
        date: '2024-04-11 22:45:00+00',
        value: 66.48,
    },
    {
        date: '2024-04-11 23:00:00+00',
        value: 65.95,
    },
    {
        date: '2024-04-11 23:15:00+00',
        value: 63.17,
    },
    {
        date: '2024-04-11 23:30:00+00',
        value: 63.92,
    },
    {
        date: '2024-04-11 23:45:00+00',
        value: 66.41,
    },
];

export const longTimeMockData = [
    ...setexMockData.map(data => {
        const updatedDate = '2024-04-11 ' + data.date;
        return { date: updatedDate, value: data.value };
    }),
    ...whuMockData.map(data => {
        const updatedDate = '2024-04-12 ' + data.date;
        return { date: updatedDate, value: data.value };
    }),
    ...indiaBerlinMockData.map(data => {
        const updatedDate = '2024-04-13 ' + data.date;
        return { date: updatedDate, value: data.value };
    }),
    ...steinthermeBadBelzigMockData.map(data => {
        const updatedDate = '2024-04-14 ' + data.date;
        return { date: updatedDate, value: data.value };
    }),
];

export const batteryChartCurrentLoad = [
    { timestamp: '00:00', value: 432.5701429 },
    { timestamp: '00:15', value: 432.5701429 },
    { timestamp: '00:30', value: 432.5701429 },
    { timestamp: '00:45', value: 432.5701429 },
    { timestamp: '00:00', value: 432.5701429 },
    { timestamp: '00:15', value: 432.5701429 },
    { timestamp: '00:30', value: 432.5701429 },
    { timestamp: '00:45', value: 432.5701429 },
    { timestamp: '02:00', value: 432.5701429 },
    { timestamp: '02:15', value: 432.5701429 },
    { timestamp: '02:30', value: 432.5701429 },
    { timestamp: '02:45', value: 432.5701429 },
    { timestamp: '03:00', value: 432.5701429 },
    { timestamp: '03:15', value: 432.5701429 },
    { timestamp: '03:30', value: 432.5701429 },
    { timestamp: '03:45', value: 432.5701429 },
    { timestamp: '04:00', value: 432.5701429 },
    { timestamp: '04:15', value: 432.5701429 },
    { timestamp: '04:30', value: 432.5701429 },
    { timestamp: '04:45', value: 432.5701429 },
    { timestamp: '05:00', value: 108.8034638 },
    { timestamp: '05:15', value: 199.1523735 },
    { timestamp: '05:30', value: 0 },
    { timestamp: '05:45', value: 0 },
    { timestamp: '06:00', value: 238.6691926 },
    { timestamp: '06:15', value: 224.5780187 },
    { timestamp: '06:30', value: 197.4610995 },
    { timestamp: '06:45', value: 172.8992512 },
    { timestamp: '07:00', value: 165.6660788 },
    { timestamp: '07:15', value: 218.9010331 },
    { timestamp: '07:30', value: 319.3951714 },
    { timestamp: '07:45', value: 293.862915 },
    { timestamp: '08:00', value: 240.6321741 },
    { timestamp: '08:15', value: 218.975255 },
    { timestamp: '08:30', value: 221.6620826 },
    { timestamp: '08:45', value: 231.6210502 },
    { timestamp: '09:00', value: 201.8889603 },
    { timestamp: '09:15', value: 208.6004507 },
    { timestamp: '09:30', value: 206.0651135 },
    { timestamp: '09:45', value: 223.9215332 },
    { timestamp: '10:00', value: 156.5900927 },
    { timestamp: '10:15', value: 196.7580029 },
    { timestamp: '10:30', value: 187.346396 },
    { timestamp: '10:45', value: 151.8931071 },
    { timestamp: '11:00', value: 123.5642646 },
    { timestamp: '11:15', value: 52.82431461 },
    { timestamp: '11:30', value: 38.14138377 },
    { timestamp: '11:45', value: 231.5206787 },
    { timestamp: '12:00', value: 289.4647311 },
    { timestamp: '12:15', value: 336.3896604 },
    { timestamp: '12:30', value: 302.3012008 },
    { timestamp: '12:45', value: 319.7139902 },
    { timestamp: '13:00', value: 0 },
    { timestamp: '13:15', value: 0 },
    { timestamp: '13:30', value: 0 },
    { timestamp: '13:45', value: 0 },
    { timestamp: '14:00', value: 0 },
    { timestamp: '14:15', value: 0 },
    { timestamp: '14:30', value: 21.40734547 },
    { timestamp: '14:45', value: 34.71060928 },
    { timestamp: '15:00', value: 45.70563001 },
    { timestamp: '15:15', value: 88.54314861 },
    { timestamp: '15:30', value: 144.7851135 },
    { timestamp: '15:45', value: 162.404314 },
    { timestamp: '16:00', value: 230.1943554 },
    { timestamp: '16:15', value: 259.4305751 },
    { timestamp: '16:30', value: 280.6051128 },
    { timestamp: '16:45', value: 313.7451793 },
    { timestamp: '17:00', value: 0 },
    { timestamp: '17:15', value: 11.56584523 },
    { timestamp: '17:30', value: 14.19641969 },
    { timestamp: '17:45', value: 35.4459617 },
    { timestamp: '18:00', value: 50.78 },
    { timestamp: '18:15', value: 382.3303915 },
    { timestamp: '18:30', value: 393.76 },
    { timestamp: '18:45', value: 381.76 },
    { timestamp: '19:00', value: 340.1 },
    { timestamp: '19:15', value: 361.98 },
    { timestamp: '19:30', value: 378.82 },
    { timestamp: '19:45', value: 370.3 },
    { timestamp: '20:00', value: 354.98 },
    { timestamp: '20:15', value: 350.02 },
    { timestamp: '20:30', value: 320.1 },
    { timestamp: '20:45', value: 328.96 },
    { timestamp: '21:00', value: 347.44 },
    { timestamp: '21:15', value: 345.18 },
    { timestamp: '21:30', value: 351.14 },
    { timestamp: '21:45', value: 358.08 },
    { timestamp: '22:00', value: 360.16 },
    { timestamp: '22:15', value: 358.68 },
    { timestamp: '22:30', value: 350.02 },
    { timestamp: '22:45', value: 342.36 },
    { timestamp: '23:00', value: 432.5701429 },
];

export const batteryChartBatteryLoad = [
    { timestamp: '00:00', value: -3.609857062 },
    { timestamp: '00:15', value: 1.070142938 },
    { timestamp: '00:30', value: 13.29014294 },
    { timestamp: '00:45', value: 14.59014294 },
    { timestamp: '00:00', value: 3.630142938 },
    { timestamp: '00:15', value: 10.05014294 },
    { timestamp: '00:30', value: 49.05014294 },
    { timestamp: '00:45', value: 44.19014294 },
    { timestamp: '02:00', value: 53.11014294 },
    { timestamp: '02:15', value: 53.65014294 },
    { timestamp: '02:30', value: 64.19014294 },
    { timestamp: '02:45', value: 50.27014294 },
    { timestamp: '03:00', value: 43.39014294 },
    { timestamp: '03:15', value: 14.95014294 },
    { timestamp: '03:30', value: -6.489857062 },
    { timestamp: '03:45', value: 4.690142938 },
    { timestamp: '04:00', value: 14.89014294 },
    { timestamp: '04:15', value: 22.58716209 },
    { timestamp: '04:30', value: 24.07843602 },
    { timestamp: '04:45', value: 5.874372721 },
    { timestamp: '05:00', value: -320 },
    { timestamp: '05:15', value: -115.6647457 },
    { timestamp: '05:30', value: -267.3833474 },
    { timestamp: '05:45', value: -246.9265947 },
    { timestamp: '06:00', value: 0 },
    { timestamp: '06:15', value: 0 },
    { timestamp: '06:30', value: 0 },
    { timestamp: '06:45', value: 0 },
    { timestamp: '07:00', value: 0 },
    { timestamp: '07:15', value: 0 },
    { timestamp: '07:30', value: 0 },
    { timestamp: '07:45', value: 0 },
    { timestamp: '08:00', value: 0 },
    { timestamp: '08:15', value: 0 },
    { timestamp: '08:30', value: 0 },
    { timestamp: '08:45', value: 0 },
    { timestamp: '09:00', value: 0 },
    { timestamp: '09:15', value: 0 },
    { timestamp: '09:30', value: 0 },
    { timestamp: '09:45', value: 0 },
    { timestamp: '10:00', value: 0 },
    { timestamp: '10:15', value: 0 },
    { timestamp: '10:30', value: 0 },
    { timestamp: '10:45', value: 0 },
    { timestamp: '11:00', value: 0 },
    { timestamp: '11:15', value: 0 },
    { timestamp: '11:30', value: 0 },
    { timestamp: '11:45', value: 233.3903023 },
    { timestamp: '12:00', value: 320 },
    { timestamp: '12:15', value: 320 },
    { timestamp: '12:30', value: 320 },
    { timestamp: '12:45', value: 320 },
    { timestamp: '13:00', value: 16.36216299 },
    { timestamp: '13:15', value: 12.26427788 },
    { timestamp: '13:30', value: 15.9038449 },
    { timestamp: '13:45', value: -3.5937237 },
    { timestamp: '14:00', value: 28.66356195 },
    { timestamp: '14:15', value: 17.00957366 },
    { timestamp: '14:30', value: 0 },
    { timestamp: '14:45', value: 0 },
    { timestamp: '15:00', value: 0 },
    { timestamp: '15:15', value: 0 },
    { timestamp: '15:30', value: 0 },
    { timestamp: '15:45', value: 0 },
    { timestamp: '16:00', value: 0 },
    { timestamp: '16:15', value: 0 },
    { timestamp: '16:30', value: 0 },
    { timestamp: '16:45', value: 0 },
    { timestamp: '17:00', value: -317.5903915 },
    { timestamp: '17:15', value: -320 },
    { timestamp: '17:30', value: -320 },
    { timestamp: '17:45', value: -320 },
    { timestamp: '18:00', value: -320 },
    { timestamp: '18:15', value: -2.409608481 },
    { timestamp: '18:30', value: 0 },
    { timestamp: '18:45', value: 0 },
    { timestamp: '19:00', value: 0 },
    { timestamp: '19:15', value: 0 },
    { timestamp: '19:30', value: 0 },
    { timestamp: '19:45', value: 0 },
    { timestamp: '20:00', value: 0 },
    { timestamp: '20:15', value: 0 },
    { timestamp: '20:30', value: 0 },
    { timestamp: '20:45', value: 0 },
    { timestamp: '21:00', value: 0 },
    { timestamp: '21:15', value: 0 },
    { timestamp: '21:30', value: 0 },
    { timestamp: '21:45', value: 0 },
    { timestamp: '22:00', value: 0 },
    { timestamp: '22:15', value: 0 },
    { timestamp: '22:30', value: 0 },
    { timestamp: '22:45', value: 0 },
    { timestamp: '23:00', value: 70.99014294 },
];

export const batteryChartPvLoad = [
    { timestamp: '00:00', value: 0 },
    { timestamp: '00:15', value: 0 },
    { timestamp: '00:30', value: 0 },
    { timestamp: '00:45', value: 0 },
    { timestamp: '00:00', value: 0 },
    { timestamp: '00:15', value: 0 },
    { timestamp: '00:30', value: 0 },
    { timestamp: '00:45', value: 0 },
    { timestamp: '02:00', value: 0 },
    { timestamp: '02:15', value: 0 },
    { timestamp: '02:30', value: 0 },
    { timestamp: '02:45', value: 0 },
    { timestamp: '03:00', value: 0 },
    { timestamp: '03:15', value: 0 },
    { timestamp: '03:30', value: 0 },
    { timestamp: '03:45', value: 0 },
    { timestamp: '04:00', value: 0 },
    { timestamp: '04:15', value: 0.4570191486 },
    { timestamp: '04:30', value: 1.028293084 },
    { timestamp: '04:45', value: 5.484229783 },
    { timestamp: '05:00', value: 12.79653616 },
    { timestamp: '05:15', value: 21.02288084 },
    { timestamp: '05:30', value: 33.47665263 },
    { timestamp: '05:45', value: 45.47340528 },
    { timestamp: '06:00', value: 55.0708074 },
    { timestamp: '06:15', value: 77.12198132 },
    { timestamp: '06:30', value: 99.05890046 },
    { timestamp: '06:45', value: 113.3407488 },
    { timestamp: '07:00', value: 117.4539212 },
    { timestamp: '07:15', value: 130.4789669 },
    { timestamp: '07:30', value: 151.0448286 },
    { timestamp: '07:45', value: 169.097085 },
    { timestamp: '08:00', value: 192.7478259 },
    { timestamp: '08:15', value: 214.684745 },
    { timestamp: '08:30', value: 218.7979174 },
    { timestamp: '08:45', value: 225.5389498 },
    { timestamp: '09:00', value: 233.6510397 },
    { timestamp: '09:15', value: 233.8795493 },
    { timestamp: '09:30', value: 229.9948865 },
    { timestamp: '09:45', value: 237.8784668 },
    { timestamp: '10:00', value: 248.3899073 },
    { timestamp: '10:15', value: 256.5019971 },
    { timestamp: '10:30', value: 276.953604 },
    { timestamp: '10:45', value: 301.7468929 },
    { timestamp: '11:00', value: 321.8557354 },
    { timestamp: '11:15', value: 332.5956854 },
    { timestamp: '11:30', value: 342.8786162 },
    { timestamp: '11:45', value: 354.9896237 },
    { timestamp: '12:00', value: 376.3552689 },
    { timestamp: '12:15', value: 384.0103396 },
    { timestamp: '12:30', value: 394.9787992 },
    { timestamp: '12:45', value: 400.0060098 },
    { timestamp: '13:00', value: 403.662163 },
    { timestamp: '13:15', value: 406.4042779 },
    { timestamp: '13:30', value: 408.0038449 },
    { timestamp: '13:45', value: 388.4662763 },
    { timestamp: '14:00', value: 377.3835619 },
    { timestamp: '14:15', value: 365.7295737 },
    { timestamp: '14:30', value: 343.7926545 },
    { timestamp: '14:45', value: 313.6293907 },
    { timestamp: '15:00', value: 295.23437 },
    { timestamp: '15:15', value: 264.9568514 },
    { timestamp: '15:30', value: 229.9948865 },
    { timestamp: '15:45', value: 195.375686 },
    { timestamp: '16:00', value: 158.5856446 },
    { timestamp: '16:15', value: 123.5094249 },
    { timestamp: '16:30', value: 92.77488716 },
    { timestamp: '16:45', value: 61.3548207 },
    { timestamp: '17:00', value: 38.38960848 },
    { timestamp: '17:15', value: 21.59415477 },
    { timestamp: '17:30', value: 7.883580313 },
    { timestamp: '17:45', value: 0.9140382972 },
    { timestamp: '18:00', value: 0 },
    { timestamp: '18:15', value: 0 },
    { timestamp: '18:30', value: 0 },
    { timestamp: '18:45', value: 0 },
    { timestamp: '19:00', value: 0 },
    { timestamp: '19:15', value: 0 },
    { timestamp: '19:30', value: 0 },
    { timestamp: '19:45', value: 0 },
    { timestamp: '20:00', value: 0 },
    { timestamp: '20:15', value: 0 },
    { timestamp: '20:30', value: 0 },
    { timestamp: '20:45', value: 0 },
    { timestamp: '21:00', value: 0 },
    { timestamp: '21:15', value: 0 },
    { timestamp: '21:30', value: 0 },
    { timestamp: '21:45', value: 0 },
    { timestamp: '22:00', value: 0 },
    { timestamp: '22:15', value: 0 },
    { timestamp: '22:30', value: 0 },
    { timestamp: '22:45', value: 0 },
    { timestamp: '23:00', value: 0 },
];

export const batteryChartConsumption = [
    { timestamp: '00:00', value: 436.18 },
    { timestamp: '00:15', value: 431.5 },
    { timestamp: '00:30', value: 419.28 },
    { timestamp: '00:45', value: 417.98 },
    { timestamp: '00:00', value: 428.94 },
    { timestamp: '00:15', value: 422.52 },
    { timestamp: '00:30', value: 383.52 },
    { timestamp: '00:45', value: 388.38 },
    { timestamp: '02:00', value: 379.46 },
    { timestamp: '02:15', value: 378.92 },
    { timestamp: '02:30', value: 368.38 },
    { timestamp: '02:45', value: 382.3 },
    { timestamp: '03:00', value: 389.18 },
    { timestamp: '03:15', value: 417.62 },
    { timestamp: '03:30', value: 439.06 },
    { timestamp: '03:45', value: 427.88 },
    { timestamp: '04:00', value: 417.68 },
    { timestamp: '04:15', value: 410.44 },
    { timestamp: '04:30', value: 409.52 },
    { timestamp: '04:45', value: 432.18 },
    { timestamp: '05:00', value: 441.6 },
    { timestamp: '05:15', value: 335.84 },
    { timestamp: '05:30', value: 300.86 },
    { timestamp: '05:45', value: 292.4 },
    { timestamp: '06:00', value: 293.74 },
    { timestamp: '06:15', value: 301.7 },
    { timestamp: '06:30', value: 296.52 },
    { timestamp: '06:45', value: 286.24 },
    { timestamp: '07:00', value: 283.12 },
    { timestamp: '07:15', value: 349.38 },
    { timestamp: '07:30', value: 470.44 },
    { timestamp: '07:45', value: 462.96 },
    { timestamp: '08:00', value: 433.38 },
    { timestamp: '08:15', value: 433.66 },
    { timestamp: '08:30', value: 440.46 },
    { timestamp: '08:45', value: 457.16 },
    { timestamp: '09:00', value: 435.54 },
    { timestamp: '09:15', value: 442.48 },
    { timestamp: '09:30', value: 436.06 },
    { timestamp: '09:45', value: 461.8 },
    { timestamp: '10:00', value: 404.98 },
    { timestamp: '10:15', value: 453.26 },
    { timestamp: '10:30', value: 464.3 },
    { timestamp: '10:45', value: 453.64 },
    { timestamp: '11:00', value: 445.42 },
    { timestamp: '11:15', value: 385.42 },
    { timestamp: '11:30', value: 381.02 },
    { timestamp: '11:45', value: 353.12 },
    { timestamp: '12:00', value: 345.82 },
    { timestamp: '12:15', value: 400.4 },
    { timestamp: '12:30', value: 377.28 },
    { timestamp: '12:45', value: 399.72 },
    { timestamp: '13:00', value: 387.3 },
    { timestamp: '13:15', value: 394.14 },
    { timestamp: '13:30', value: 392.1 },
    { timestamp: '13:45', value: 392.06 },
    { timestamp: '14:00', value: 348.72 },
    { timestamp: '14:15', value: 348.72 },
    { timestamp: '14:30', value: 365.2 },
    { timestamp: '14:45', value: 348.34 },
    { timestamp: '15:00', value: 340.94 },
    { timestamp: '15:15', value: 353.5 },
    { timestamp: '15:30', value: 374.78 },
    { timestamp: '15:45', value: 357.78 },
    { timestamp: '16:00', value: 388.78 },
    { timestamp: '16:15', value: 382.94 },
    { timestamp: '16:30', value: 373.38 },
    { timestamp: '16:45', value: 375.1 },
    { timestamp: '17:00', value: 355.98 },
    { timestamp: '17:15', value: 353.16 },
    { timestamp: '17:30', value: 342.08 },
    { timestamp: '17:45', value: 356.36 },
    { timestamp: '18:00', value: 370.78 },
    { timestamp: '18:15', value: 384.74 },
    { timestamp: '18:30', value: 393.76 },
    { timestamp: '18:45', value: 381.76 },
    { timestamp: '19:00', value: 340.1 },
    { timestamp: '19:15', value: 361.98 },
    { timestamp: '19:30', value: 378.82 },
    { timestamp: '19:45', value: 370.3 },
    { timestamp: '20:00', value: 354.98 },
    { timestamp: '20:15', value: 350.02 },
    { timestamp: '20:30', value: 320.1 },
    { timestamp: '20:45', value: 328.96 },
    { timestamp: '21:00', value: 347.44 },
    { timestamp: '21:15', value: 345.18 },
    { timestamp: '21:30', value: 351.14 },
    { timestamp: '21:45', value: 358.08 },
    { timestamp: '22:00', value: 360.16 },
    { timestamp: '22:15', value: 358.68 },
    { timestamp: '22:30', value: 350.02 },
    { timestamp: '22:45', value: 342.36 },
    { timestamp: '23:00', value: 361.58 },
];

export const batteryChartCurrentLoadIa = [
    { timestamp: '00:00', value: 429.8415385 },
    { timestamp: '00:15', value: 429.8415385 },
    { timestamp: '00:30', value: 429.8415385 },
    { timestamp: '00:45', value: 429.8415385 },
    { timestamp: '00:00', value: 429.8415385 },
    { timestamp: '00:15', value: 429.8415385 },
    { timestamp: '00:30', value: 429.8415385 },
    { timestamp: '00:45', value: 429.8415385 },
    { timestamp: '02:00', value: 429.8415385 },
    { timestamp: '02:15', value: 429.8415385 },
    { timestamp: '02:30', value: 368.38 },
    { timestamp: '02:45', value: 382.3 },
    { timestamp: '03:00', value: 429.8415385 },
    { timestamp: '03:15', value: 429.8415385 },
    { timestamp: '03:30', value: 429.8415385 },
    { timestamp: '03:45', value: 427.88 },
    { timestamp: '04:00', value: 429.8415385 },
    { timestamp: '04:15', value: 409.9829809 },
    { timestamp: '04:30', value: 408.4917069 },
    { timestamp: '04:45', value: 426.6957702 },
    { timestamp: '05:00', value: 108.8034638 },
    { timestamp: '05:15', value: 314.8171192 },
    { timestamp: '05:30', value: 267.3833474 },
    { timestamp: '05:45', value: 246.9265947 },
    { timestamp: '06:00', value: 114.6153464 },
    { timestamp: '06:15', value: 224.5780187 },
    { timestamp: '06:30', value: 197.4610995 },
    { timestamp: '06:45', value: 338.56533 },
    { timestamp: '07:00', value: 0 },
    { timestamp: '07:15', value: 218.9010331 },
    { timestamp: '07:30', value: 319.3951714 },
    { timestamp: '07:45', value: 429.8415385 },
    { timestamp: '08:00', value: 104.6535506 },
    { timestamp: '08:15', value: 218.975255 },
    { timestamp: '08:30', value: 221.6620826 },
    { timestamp: '08:45', value: 429.8415385 },
    { timestamp: '09:00', value: 3.668471994 },
    { timestamp: '09:15', value: 208.6004507 },
    { timestamp: '09:30', value: 206.0651135 },
    { timestamp: '09:45', value: 429.8415385 },
    { timestamp: '10:00', value: 0 },
    { timestamp: '10:15', value: 196.7580029 },
    { timestamp: '10:30', value: 138.0164834 },
    { timestamp: '10:45', value: 429.8415385 },
    { timestamp: '11:00', value: 0 },
    { timestamp: '11:15', value: 52.82431461 },
    { timestamp: '11:30', value: 144.1884354 },
    { timestamp: '11:45', value: 318.1303763 },
    { timestamp: '12:00', value: 289.4647311 },
    { timestamp: '12:15', value: 16.3896604 },
    { timestamp: '12:30', value: 0 },
    { timestamp: '12:45', value: 0 },
    { timestamp: '13:00', value: 303.637837 },
    { timestamp: '13:15', value: 0 },
    { timestamp: '13:30', value: 0 },
    { timestamp: '13:45', value: 0 },
    { timestamp: '14:00', value: 291.3364381 },
    { timestamp: '14:15', value: 0 },
    { timestamp: '14:30', value: 21.40734547 },
    { timestamp: '14:45', value: 34.71060928 },
    { timestamp: '15:00', value: 45.70563001 },
    { timestamp: '15:15', value: 88.54314861 },
    { timestamp: '15:30', value: 144.7851135 },
    { timestamp: '15:45', value: 162.404314 },
    { timestamp: '16:00', value: 230.1943554 },
    { timestamp: '16:15', value: 259.4305751 },
    { timestamp: '16:30', value: 280.6051128 },
    { timestamp: '16:45', value: 0 },
    { timestamp: '17:00', value: 317.5903915 },
    { timestamp: '17:15', value: 331.5658452 },
    { timestamp: '17:30', value: 14.19641969 },
    { timestamp: '17:45', value: 289.6496025 },
    { timestamp: '18:00', value: 50.78 },
    { timestamp: '18:15', value: 384.74 },
    { timestamp: '18:30', value: 393.76 },
    { timestamp: '18:45', value: 381.76 },
    { timestamp: '19:00', value: 20.1 },
    { timestamp: '19:15', value: 361.98 },
    { timestamp: '19:30', value: 378.82 },
    { timestamp: '19:45', value: 429.8415385 },
    { timestamp: '20:00', value: 34.98 },
    { timestamp: '20:15', value: 350.02 },
    { timestamp: '20:30', value: 320.1 },
    { timestamp: '20:45', value: 429.8415385 },
    { timestamp: '21:00', value: 246.5584615 },
    { timestamp: '21:15', value: 345.18 },
    { timestamp: '21:30', value: 351.14 },
    { timestamp: '21:45', value: 429.8415385 },
    { timestamp: '22:00', value: 360.16 },
    { timestamp: '22:15', value: 358.68 },
    { timestamp: '22:30', value: 429.8415385 },
    { timestamp: '22:45', value: 429.8415385 },
    { timestamp: '23:00', value: 361.58 },
];

export const batteryChartBatteryLoadIa = [
    { timestamp: '00:00', value: -6.338461538 },
    { timestamp: '00:15', value: -1.658461538 },
    { timestamp: '00:30', value: 10.56153846 },
    { timestamp: '00:45', value: 11.86153846 },
    { timestamp: '00:00', value: 0.9015384615 },
    { timestamp: '00:15', value: 7.321538462 },
    { timestamp: '00:30', value: 46.32153846 },
    { timestamp: '00:45', value: 41.46153846 },
    { timestamp: '02:00', value: 50.38153846 },
    { timestamp: '02:15', value: 50.92153846 },
    { timestamp: '02:30', value: 0 },
    { timestamp: '02:45', value: 0 },
    { timestamp: '03:00', value: 40.66153846 },
    { timestamp: '03:15', value: 12.22153846 },
    { timestamp: '03:30', value: -9.218461538 },
    { timestamp: '03:45', value: 0 },
    { timestamp: '04:00', value: 12.16153846 },
    { timestamp: '04:15', value: 0 },
    { timestamp: '04:30', value: 0 },
    { timestamp: '04:45', value: 0 },
    { timestamp: '05:00', value: -320 },
    { timestamp: '05:15', value: 0 },
    { timestamp: '05:30', value: 0 },
    { timestamp: '05:45', value: 0 },
    { timestamp: '06:00', value: -124.0538462 },
    { timestamp: '06:15', value: 0 },
    { timestamp: '06:30', value: 0 },
    { timestamp: '06:45', value: 165.6660788 },
    { timestamp: '07:00', value: -165.6660788 },
    { timestamp: '07:15', value: 0 },
    { timestamp: '07:30', value: 0 },
    { timestamp: '07:45', value: 135.9786234 },
    { timestamp: '08:00', value: -135.9786234 },
    { timestamp: '08:15', value: 0 },
    { timestamp: '08:30', value: 0 },
    { timestamp: '08:45', value: 198.2204883 },
    { timestamp: '09:00', value: -198.2204883 },
    { timestamp: '09:15', value: 0 },
    { timestamp: '09:30', value: 0 },
    { timestamp: '09:45', value: 205.9200053 },
    { timestamp: '10:00', value: -156.5900927 },
    { timestamp: '10:15', value: 0 },
    { timestamp: '10:30', value: -49.32991256 },
    { timestamp: '10:45', value: 277.9484313 },
    { timestamp: '11:00', value: -123.5642646 },
    { timestamp: '11:15', value: 0 },
    { timestamp: '11:30', value: 106.0470516 },
    { timestamp: '11:45', value: 320 },
    { timestamp: '12:00', value: 320 },
    { timestamp: '12:15', value: 0 },
    { timestamp: '12:30', value: 17.69879917 },
    { timestamp: '12:45', value: 0.2860098018 },
    { timestamp: '13:00', value: 320 },
    { timestamp: '13:15', value: 12.26427788 },
    { timestamp: '13:30', value: 15.9038449 },
    { timestamp: '13:45', value: -3.5937237 },
    { timestamp: '14:00', value: 320 },
    { timestamp: '14:15', value: 17.00957366 },
    { timestamp: '14:30', value: 0 },
    { timestamp: '14:45', value: 0 },
    { timestamp: '15:00', value: 0 },
    { timestamp: '15:15', value: 0 },
    { timestamp: '15:30', value: 0 },
    { timestamp: '15:45', value: 0 },
    { timestamp: '16:00', value: 0 },
    { timestamp: '16:15', value: 0 },
    { timestamp: '16:30', value: 0 },
    { timestamp: '16:45', value: -313.7451793 },
    { timestamp: '17:00', value: 0 },
    { timestamp: '17:15', value: 0 },
    { timestamp: '17:30', value: -320 },
    { timestamp: '17:45', value: -65.79635916 },
    { timestamp: '18:00', value: -320 },
    { timestamp: '18:15', value: 0 },
    { timestamp: '18:30', value: 0 },
    { timestamp: '18:45', value: 0 },
    { timestamp: '19:00', value: -320 },
    { timestamp: '19:15', value: 0 },
    { timestamp: '19:30', value: 0 },
    { timestamp: '19:45', value: 59.54153846 },
    { timestamp: '20:00', value: -320 },
    { timestamp: '20:15', value: 0 },
    { timestamp: '20:30', value: 0 },
    { timestamp: '20:45', value: 100.8815385 },
    { timestamp: '21:00', value: -100.8815385 },
    { timestamp: '21:15', value: 0 },
    { timestamp: '21:30', value: 0 },
    { timestamp: '21:45', value: 71.76153846 },
    { timestamp: '22:00', value: 0 },
    { timestamp: '22:15', value: 0 },
    { timestamp: '22:30', value: 79.82153846 },
    { timestamp: '22:45', value: 87.48153846 },
    { timestamp: '23:00', value: 0 },
];
