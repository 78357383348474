import { Button, Input, Label } from '@trawa-energy/ui-kit';
import { useIntl } from 'react-intl';
import { Section } from '../Section.tsx';
import { Heading } from '../Heading.tsx';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

export function LinkRequestForm({
    onSubmit,
    busy,
}: {
    onSubmit: (email: string) => Promise<void> | void;
    busy: boolean;
}) {
    const intl = useIntl();
    const { control, handleSubmit } = useForm<{ email: string }>({
        reValidateMode: 'onBlur',
        defaultValues: { email: '' },
    });

    const emailController = useController({
        name: 'email',
        control,
        rules: {
            required: true,
            validate: x => {
                const parse = z.string().email().safeParse(x);
                return parse.success ? true : intl.formatMessage({ id: 'magicLink.login.errors.invalidEmail' });
            },
        },
    });

    const submit = async (data: { email: string }) => onSubmit(data.email);

    return (
        <>
            <Heading>{intl.formatMessage({ id: 'login.heading' })}</Heading>
            <form className="flex flex-col space-y-4" onSubmit={handleSubmit(submit)}>
                <Section title={intl.formatMessage({ id: 'magicLink.login.title' })}>
                    {intl.formatMessage({ id: 'magicLink.login.body' })}
                </Section>

                <div className="relative">
                    <Label text={intl.formatMessage({ id: 'magicLink.login.input.label' })}>
                        <Input
                            {...emailController.field}
                            disabled={busy}
                            placeholder={intl.formatMessage({ id: 'magicLink.login.input.placeholder' })}
                            invalid={!!emailController.fieldState.error}
                            autoComplete="on"
                            autoCorrect="on"
                        />
                    </Label>
                    <div>
                        {emailController.fieldState.error && (
                            <div className="text-fr-2">{emailController.fieldState.error.message}</div>
                        )}
                    </div>
                </div>

                <div className="pt-4">
                    <Button type="submit" className="w-full" disabled={busy}>
                        {intl.formatMessage({ id: 'magicLink.login.button' })}
                    </Button>
                </div>
            </form>
        </>
    );
}
