import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { config } from '../config.ts';
import { useCallback, useRef, useState } from 'react';

const userPool = new CognitoUserPool({
    endpoint: 'https://cognito-idp.eu-central-1.amazonaws.com/',
    ClientId: config.amplifyAuth.aws_user_pools_web_client_id,
    UserPoolId: config.amplifyAuth.aws_user_pools_id,
});

export const removeTokenFromLocalStorage = () => {
    return userPool.getCurrentUser()?.signOut();
};

type AuthState = {
    isSigningIn: boolean;
    isAuthenticated: boolean;
    error?: unknown;
};

export type Credentials = {
    username: string;
    password: string;
};

export function useAuthenticate() {
    const [state, setState] = useState<AuthState>(() => ({
        isSigningIn: false,
        isAuthenticated: !!userPool.getCurrentUser(),
    }));
    const inProgressRef = useRef(false);

    const signIn = useCallback(async (credentials: Credentials) => {
        return wrapSignInState(authenticateUser, setState, inProgressRef)(credentials);
    }, []);

    const changeTemporaryPassword = useCallback(async (username: string, oldPassword: string, newPassword: string) => {
        return wrapSignInState(authenticateUserAndSetNewPassword, setState, inProgressRef)(
            username,
            oldPassword,
            newPassword,
        );
    }, []);

    const getAccessToken = useCallback(async () => {
        const token = await getAccessTokenOrRefresh();
        setState({ isSigningIn: false, isAuthenticated: !!token });
        return token ?? undefined;
    }, []);

    return {
        getAccessToken, // gets access token and optionally sets isAuthenticated to false if current session is null
        changeTemporaryPassword,
        signIn,
        signOut: removeTokenFromLocalStorage,
        ...state,
    };
}

async function getAccessTokenOrRefresh() {
    const user = userPool.getCurrentUser();
    if (!user) {
        console.warn('No current user found');
        return null;
    }

    const getSession = () =>
        new Promise<CognitoUserSession | null>((resolve, reject) => {
            user.getSession((error: Error | null, session: CognitoUserSession | null) => {
                if (!error && !session) {
                    console.warn('Weird stuff: no session and no error', error);
                }

                session ? resolve(session) : reject(error);
            });
        });

    const session = await getSession();
    if (!session) {
        console.warn('No auth session found');
        return null;
    }

    return session.getAccessToken().getJwtToken();
}

function authenticateUser({ username, password }: Credentials) {
    return new Promise<{ session: CognitoUserSession; userConfirmationNecessary?: boolean }>((resolve, reject) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
        cognitoUser.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
            onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) =>
                resolve({ session, userConfirmationNecessary }), // onSuccess produces an access token, but after an hour it will get replaced by another one. No point tracking it here
            onFailure: reject,
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                reject(new Error('New password required')); // TODO: we need a UX-friendly way to handle this
            },
        });
    });
}

async function authenticateUserAndSetNewPassword(username: string, oldPassword: string, newPassword: string) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

        cognitoUser.authenticateUser(new AuthenticationDetails({ Username: username, Password: oldPassword }), {
            onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) =>
                resolve({ session, userConfirmationNecessary }), // onSuccess produces an access token, but after an hour it will get replaced by another one. No point tracking it here
            onFailure: reject,
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log('newPasswordRequired', userAttributes, requiredAttributes);
                cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
                    onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) =>
                        resolve({ session, userConfirmationNecessary }),
                    onFailure: reject,
                });
            },
        });
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SignIn = (...args: any[]) => Promise<any>;
function wrapSignInState<T extends SignIn>(
    func: T,
    setState: React.Dispatch<React.SetStateAction<AuthState>>,
    inProgressRef: React.MutableRefObject<boolean>,
) {
    return async (...args: Parameters<T>) => {
        if (inProgressRef.current) return false;

        inProgressRef.current = true;
        setState(x => ({ ...x, isSigningIn: true }));

        userPool.getCurrentUser()?.signOut();
        try {
            await func(...args);

            setState({ isSigningIn: false, isAuthenticated: true });
            return true;
        } catch (error) {
            setState(x => ({
                ...x,
                isSigningIn: false,
                isAuthenticated: !!userPool.getCurrentUser(),
            }));
            throw error;
        } finally {
            inProgressRef.current = false;
        }
    };
}
