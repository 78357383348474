import { AccountType, useUserSettings } from '../userSettings';
import { trpc } from '../utils/trpc';

export const useGetMarketLocations = () => {
    const { userSettings } = useUserSettings();
    const contractPartnerId =
        userSettings.accountType === AccountType.RealEstate ? userSettings.contractPartnerId : undefined;

    return trpc.dashboard.getMarketLocations.useQuery({ contractPartnerId }, {});
};
