import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

type SizeType = 'sm' | 'md' | 'lg';

type PowerChipProps = HTMLAttributes<HTMLDivElement> & {
    color: string;
    isHidden?: boolean;
    size?: SizeType;
};

type StylesForSizeType = {
    width: string;
    text: string;
    size: string;
};

export const PowerChip: FC<PropsWithChildren<PowerChipProps>> = ({
    children,
    color,
    size = 'lg',
    isHidden = false,
    ...props
}) => {
    const defaultSize: StylesForSizeType = {
        width: 'min-w-32',
        text: 'text-sm',
        size: 'min-w-24',
    };

    const sizeMap: Record<SizeType, StylesForSizeType> = {
        sm: {
            width: 'min-w-32',
            text: 'text-xs',
            size: 'size-full',
        },
        md: defaultSize,
        lg: defaultSize,
    };

    return (
        <div
            style={{ background: color }}
            className={`px-1 rounded-full text-white text-center text-sm lg:text-base 2xl:${sizeMap[size].text} inline-block z-10 relative ${sizeMap[size].size} 2xl:${sizeMap[size].width} ${isHidden ? 'opacity-0' : ''} `}
            {...props}
        >
            {children}
        </div>
    );
};
