import { createContext, useContext } from 'react';
import { Credentials } from './useAuthenticate';

export type AuthContext = {
    signOut: () => void;
    signIn: (credentials: Credentials) => Promise<unknown>;
    status: AuthStatus;
    isAuthenticated: boolean;
};

export type AuthStatus =
    | 'SIGNING_OUT'
    | 'SIGNED_IN'
    | 'REFRESHING_SIGN_IN'
    | 'SIGNING_IN'
    | 'CHECKING'
    | 'NOT_SIGNED_IN';

export const AuthContext = createContext<AuthContext>({
    signOut: null!,
    signIn: null!,
    status: 'NOT_SIGNED_IN',
    isAuthenticated: false,
});

export const useAuthContext = () => useContext(AuthContext);
