import { AssetLocation, FlexPowerLocation, MapLocation, MarketLocation } from '../../constants/locations';
import marketLocationIcon from '../../assets/icons/locationPin.svg';
import windmillIcon from '../../assets/icons/windmill.svg';
import windmillIconCluster from '../../assets/icons/windmillCluster.svg';
import solarPvaIcon from '../../assets/icons/solarPva.svg';
import solarPvaIconCluster from '../../assets/icons/solarPvaCluster.svg';

import { clusterStyleMarketLocations, clusterStylesAssetsLocations } from './mapStyles';

export const formatMapCoordinates = (
    marketLocations: MarketLocation[],
    assetLocations: AssetLocation[],
    flexPowerLocations: FlexPowerLocation[],
) => {
    const formattedMarketLocations: MapLocation[] = marketLocations
        .filter(({ coordinates }) => coordinates !== null)
        .map(location => {
            return {
                coordinates: {
                    lat: location.coordinates!.lat,
                    lng: location.coordinates!.lng,
                },
                name: location.label ?? 'marketLocation',
                icon: marketLocationIcon,
                styles: clusterStyleMarketLocations,
                productType: undefined,
                type: 'market',
            };
        });

    const formattedAssetLocations: MapLocation[] = assetLocations.map(location => {
        return {
            coordinates: {
                lat: location.lat,
                lng: location.lng,
            },
            name: location.productType,
            productType: location.productType,
            icon: location.productType === 'Onshore Wind PPA' ? windmillIcon : solarPvaIcon,
            styles:
                location.productType === 'Onshore Wind PPA'
                    ? clusterStylesAssetsLocations(windmillIconCluster)
                    : clusterStylesAssetsLocations(solarPvaIconCluster),
            type: location.productType === 'Onshore Wind PPA' ? 'wind' : 'solar',
        };
    });

    const formattedFlexPowerLocations: MapLocation[] = flexPowerLocations.map(location => {
        return {
            coordinates: { lat: location.lat, lng: location.lng },
            name: location.productType,
            productType: location.productType,
            icon: location.productType === 'Onshore Wind PPA' ? windmillIcon : solarPvaIcon,
            styles:
                location.productType === 'Onshore Wind PPA'
                    ? clusterStylesAssetsLocations(windmillIconCluster)
                    : clusterStylesAssetsLocations(solarPvaIconCluster),
            type: location.productType === 'Onshore Wind PPA' ? 'wind' : 'solar',
        };
    });
    const formattedWindLocations = [...formattedAssetLocations, ...formattedFlexPowerLocations].filter(
        location => location.productType === 'Onshore Wind PPA',
    );

    const formattedSolarLocations = [...formattedAssetLocations, ...formattedFlexPowerLocations].filter(
        location => location.productType === 'Solar PPA',
    );
    return [formattedMarketLocations, formattedWindLocations, formattedSolarLocations];
};
