import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Temporal } from 'temporal-polyfill';
import {
    AddressIcon,
    Calendar,
    CalendarIcon,
    LoadingSpinner,
    PauseIcon,
    PlayIcon,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@trawa-energy/ui-kit';
import { DisplayGrid, useGridData, useLastDayWithData } from '../../hooks/useGridData.ts';
import { AnimatePresence, motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { GridLine } from '../GridLine.tsx';
import { analytics } from '../../analytics.ts';
import { PowerChip } from './PowerChip.tsx';
import { calcAnimationSpeedPercentage, directionToGradientMap, getGridBreak, getOffsetByNumOfNodes } from './utils.ts';
import { MarketLocation as MarketLocationT, PowerSupplier as PowerSupplierT } from './types.ts';
import { MarketLocation } from './MarketLocation.tsx';
import { PowerSupplier } from './PowerSupplier.tsx';
import { Box } from './Box.tsx';
import { useFeatureFlags } from '../../hooks/useFeatureFlags.ts';

const useFormatKwh = () => {
    const { formatNumber } = useIntl();

    return (number: number) => {
        const formattedNumber = formatNumber(number, {
            maximumFractionDigits: number >= 10 ? 0 : 2,
        });

        return formattedNumber + ' kWh';
    };
};

export const ConsumptionGridContainer: FC<PropsWithChildren<{ className?: string; isTvScreen?: boolean }>> = ({
    children,
    className = '',
    isTvScreen = false,
}) => {
    return (
        <AnimatePresence mode="wait">
            <div
                className={`${className} ${!isTvScreen ? 'border-y sm:border-x sm:rounded-xl bg-card' : 'h-full flex flex-col justify-around'} border-muted-foreground overflow-auto mx-[-0.5rem] sm:mx-0`}
            >
                {children}
            </div>
        </AnimatePresence>
    );
};

type Mode = {
    id: string;
    type: 'default' | 'address';
};

export const ConsumptionGrid = ({ groupId }: { groupId?: string }) => {
    const { isManageOnly } = useFeatureFlags();

    const intl = useIntl();
    const formatKwh = useFormatKwh();
    const [mode, setMode] = useState<Mode>({
        id: '',
        type: 'default',
    });
    const [arrowsAreVisible, setArrowsAreVisible] = useState(true);
    const toggleArrowsAreVisible = () => {
        setArrowsAreVisible(prevState => !prevState);
    };

    const [selectedDate, setSelectedDate] = useState<Temporal.PlainDate>(
        Temporal.Now.plainDateISO().subtract({ days: 1 }),
    );
    const [localTimestamp, setLocalTimestamp] = useState<Temporal.PlainDateTime>(selectedDate.toPlainDateTime());

    useEffect(() => {
        setLocalTimestamp(selectedDate.toPlainDateTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate.toString()]);

    const {
        data: lastDayWithData,
        isLoading: isLoadingLastDayWithData,
        isError: isErrorLastDayWithData,
    } = useLastDayWithData({ display: DisplayGrid.DESKTOP });

    useEffect(() => {
        if (lastDayWithData) {
            setSelectedDate(lastDayWithData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastDayWithData?.toString()]);

    const {
        data,
        isLoading: isLoadingGridData,
        isError: isErrorGridData,
    } = useGridData({
        selectedDate,
        localTimestamp,
        groupId,
    });

    const isLoading = isLoadingLastDayWithData || isLoadingGridData;
    const isError = isErrorLastDayWithData || isErrorGridData;

    useEffect(() => {
        if (localTimestamp === undefined || intl.timeZone === undefined) {
            return;
        }

        analytics.track('Grid rendered', {
            Date: localTimestamp.toZonedDateTime(intl.timeZone).toString(),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTimestamp?.toPlainDate().toString()]);

    const paddingTailwindClasses = 'px-3 py-8 md:px-8';

    if (isLoading || data === undefined) {
        return (
            <ConsumptionGridContainer className={paddingTailwindClasses}>
                <LoadingSpinner />
            </ConsumptionGridContainer>
        );
    }

    if (isError) {
        return (
            <ConsumptionGridContainer className={`${paddingTailwindClasses} text-center`}>
                {intl.formatMessage({ id: 'overview.errorMsg' })}
            </ConsumptionGridContainer>
        );
    }

    return (
        <ConsumptionGridContainer>
            <div className={`w-full max-w-6xl 2xl:max-w-full flex flex-col items-center ${paddingTailwindClasses}`}>
                {!isManageOnly && (
                    <AnimatePresence>
                        {mode.type === 'default' && (
                            <motion.div
                                layout
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="w-full flex justify-around gap-2 sm:gap-6 2xl:w-2/3"
                            >
                                {data.powerSuppliers
                                    // TODO replace with .toSorted() when supported
                                    .sort((x, y) => {
                                        const order: PowerSupplierT['type'][] = ['wind', 'solar', 'spot', 'futures'];

                                        return order.indexOf(x.type) - order.indexOf(y.type);
                                    })
                                    .map((powerSupplier, index) => {
                                        return (
                                            <PowerSupplier
                                                key={powerSupplier.id}
                                                id={powerSupplier.id}
                                                kwh={powerSupplier.kwh}
                                                direction={powerSupplier.direction}
                                                type={powerSupplier.type}
                                                endElementId={data.id}
                                                numberOfPpas={data.powerSuppliers.length}
                                                index={index}
                                                maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                                            />
                                        );
                                    })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                )}
                <div className="flex justify-center my-16 2xl:my-20">
                    <button
                        type="button"
                        disabled={mode.type === 'default'}
                        onClick={() => {
                            setMode({
                                id: '',
                                type: 'default',
                            });
                        }}
                    >
                        <Box id={data.id} className="px-10 py-6 2xl:px-12 2xl:py-8">
                            <span className="font-bold 2xl:text-2xl">{data.companyName}</span>
                        </Box>
                    </button>
                </div>
                <AnimatePresence>
                    <div className="flex justify-around gap-6 2xl:gap-12 w-full">
                        {data.addresses.map((address, index) => {
                            const isVisible = mode.type === 'default' || mode.id === address.id;

                            return (
                                isVisible && (
                                    <motion.div
                                        key={address.id}
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.4 }}
                                        onLayoutAnimationStart={() => {
                                            toggleArrowsAreVisible();
                                        }}
                                        onLayoutAnimationComplete={() => {
                                            toggleArrowsAreVisible();
                                        }}
                                    >
                                        <div className="flex flex-col items-center mb-6 xl:mb-16 2xl:mb-20">
                                            <button
                                                type="button"
                                                disabled // TODO disabled until needed
                                                onClick={() => {
                                                    setMode({
                                                        id: address.id,
                                                        type: 'address',
                                                    });
                                                }}
                                            >
                                                <div className="mb-4 xl:opacity-0">
                                                    <PowerChip color={directionToGradientMap['receiving']}>
                                                        {formatKwh(address.kwh)}
                                                    </PowerChip>
                                                </div>
                                                <Box
                                                    id={address.id}
                                                    className="w-28 2xl:w-36 flex-col items-center px-4 py-2 2xl:px-6 2xl:py-4"
                                                >
                                                    <AddressIcon className="w-6 2xl:w-10" />
                                                    <span className="font-bold 2xl:text-xl">{address.name}</span>
                                                </Box>
                                            </button>
                                        </div>
                                        <div
                                            className={`hidden xl:grid gap-6 2xl:gap-12 grid-cols-${address.marketLocations.length}`}
                                        >
                                            {address.marketLocations
                                                // TODO replace with .toSorted() when supported
                                                .sort((x, y) => {
                                                    const order: MarketLocationT['type'][] = ['RLM', 'PV', 'SLP'];

                                                    return order.indexOf(x.type) - order.indexOf(y.type);
                                                })
                                                .map((marketLocation, index) => {
                                                    return (
                                                        <MarketLocation
                                                            id={marketLocation.id}
                                                            key={marketLocation.id}
                                                            startElementId={address.id}
                                                            marketLocation={marketLocation}
                                                            index={index}
                                                            direction={marketLocation.direction}
                                                            numberOfMarketLocations={address.marketLocations.length}
                                                            maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <AnimatePresence initial={false}>
                                            {arrowsAreVisible && (
                                                <motion.div
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.4 }}
                                                >
                                                    <GridLine
                                                        start={data.id}
                                                        end={address.id}
                                                        startAnchor={{
                                                            position: 'bottom',
                                                            offset: {
                                                                x: getOffsetByNumOfNodes(data.addresses.length)[index],
                                                                y: 0,
                                                            },
                                                        }}
                                                        endAnchor="top"
                                                        animationSpeedPercentage={calcAnimationSpeedPercentage(
                                                            address.kwh,
                                                            data.maxMarketLocationPeakKwh,
                                                        )}
                                                        dashColor="hsl(var(--primary-blue-4))"
                                                        gridBreak={getGridBreak(
                                                            data.addresses.length,
                                                            index,
                                                            'bottomUp',
                                                        )}
                                                    />
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                )
                            );
                        })}
                    </div>
                </AnimatePresence>
            </div>
            {selectedDate && localTimestamp && (
                <TimestampSlider
                    selectedDate={selectedDate}
                    setSelectedDate={(newSelectedDate: Temporal.PlainDate) => setSelectedDate(newSelectedDate)}
                    value={localTimestamp}
                    onChange={setLocalTimestamp}
                />
            )}
        </ConsumptionGridContainer>
    );
};

type DatepickerProps = {
    selected: Date;
    onChange: (date: Date) => void;
    label: string;
};
const Datepicker: FC<DatepickerProps> = ({ selected, onChange, label }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                <button
                    type="button"
                    aria-pressed={isOpen}
                    className="flex items-center gap-2 border px-4 py-3 rounded-xl hover:text-primary bg-white font-bold"
                >
                    <CalendarIcon className="text-primary" />
                    {label}
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-4 bg-white" align="start">
                <Calendar
                    mode="single"
                    selected={selected}
                    disabled={{
                        from: new Date(Temporal.Now.plainDateISO().toString()),
                        to: new Date(Temporal.Now.plainDateISO().add({ years: 99 }).toString()),
                    }}
                    onSelect={date => {
                        if (date === undefined) {
                            return;
                        }

                        onChange(date);
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};

const TimestampSlider: FC<{
    selectedDate: Temporal.PlainDate;
    setSelectedDate: (startDate: Temporal.PlainDate) => void;
    value: Temporal.PlainDateTime;
    onChange: (value: Temporal.PlainDateTime) => void;
}> = ({ selectedDate, value, onChange, setSelectedDate }) => {
    const intl = useIntl();
    const [autoSlideIsEnabled, setAutoSlideIsEnabled] = useState(true);
    const minDateTime = selectedDate.toPlainDateTime();
    const selectableDateTimes = useMemo(() => generateSelectableDateTimes(minDateTime), [minDateTime]);
    const selectedDateTimeIndex = selectableDateTimes.findIndex(dateTime => value.equals(dateTime));
    const percentage = (selectedDateTimeIndex / (selectableDateTimes.length - 1)) * 100;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!autoSlideIsEnabled) {
                return;
            }

            const nextIndex = selectedDateTimeIndex === selectableDateTimes.length - 1 ? 0 : selectedDateTimeIndex + 1;

            onChange(selectableDateTimes[nextIndex]);
        }, 6250);

        return () => clearInterval(interval);
    }, [autoSlideIsEnabled, onChange, selectableDateTimes, selectedDateTimeIndex, value]);

    const tailwindClasses = [
        'w-[calc(100%+1.5rem)]', // full-w + thumb-width
        'h-8',
        'block',
        'bg-transparent',
        'cursor-pointer',
        'relative',
        'z-20',
        'appearance-none',
        '[&::-webkit-slider-thumb]:appearance-none',
        '[&::-moz-range-thumb]:appearance-none',

        '-ml-3', // thumb-width / 2
        'mb-2',

        // // slider thumb
        '[&::-webkit-slider-thumb]:w-6',
        '[&::-moz-range-thumb]:w-6',

        '[&::-webkit-slider-thumb]:h-6',
        '[&::-moz-range-thumb]:h-6',

        '[&::-webkit-slider-thumb]:shadow-[0_0_0_2px_rgba(30,64,175,1)]',
        '[&::-moz-range-thumb]:shadow-[0_0_0_1px_rgba(30,64,175,1)]',
        '[&::-moz-range-thumb]:border-blue-800',

        '[&::-webkit-slider-thumb]:bg-white',
        '[&::-moz-range-thumb]:bg-white',

        '[&::-webkit-slider-thumb]:rounded-full',
        '[&::-moz-range-thumb]:rounded-full',
    ];

    return (
        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-y-8 gap-x-6 bg-primary-blue-1 px-3 py-8 md:px-8 border-t border-muted-foreground">
            <div className="order-2 sm:order-1 justify-self-center sm:justify-self-end">
                <div className="flex items-center gap-4">
                    <div>
                        <button
                            type="button"
                            className="flex items-center gap-2 border px-4 py-3 rounded-xl hover:text-primary bg-white font-bold"
                            onClick={() => {
                                setAutoSlideIsEnabled(prevState => !prevState);
                                analytics.track('Animation toggled', {
                                    'Toggle State': autoSlideIsEnabled ? 'paused' : 'playing',
                                });
                            }}
                        >
                            {autoSlideIsEnabled ? <PauseIcon /> : <PlayIcon />}
                        </button>
                    </div>
                    <div>
                        <Datepicker
                            label={intl.formatDate(value.toString(), { dateStyle: 'medium' })}
                            selected={new Date(selectedDate.toString())}
                            onChange={date => {
                                setSelectedDate(
                                    Temporal.PlainDate.from({
                                        year: date.getFullYear(),
                                        month: date.getMonth() + 1 /* 0-based */,
                                        day: date.getDate(),
                                    }),
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="px-8 order-1 sm:order-2 col-span-2 2xl:col-span-1">
                <div className="relative">
                    <div className="relative">
                        <input
                            className={tailwindClasses.join(' ')}
                            type="range"
                            min={0}
                            max={selectableDateTimes.length - 1}
                            value={selectableDateTimes.findIndex(x => value.equals(x))}
                            onChange={event => onChange(selectableDateTimes[parseInt(event.target.value, 10)])}
                            onMouseDown={() => setAutoSlideIsEnabled(false)}
                            onMouseUp={() => {
                                analytics.track('Grid rendered', {
                                    Date: value.toZonedDateTime(intl.timeZone!).toString(),
                                });
                            }}
                        />
                        <div className="h-1 bg-blue-600 absolute top-1/2 w-full -translate-y-1/2 z-10" />
                        <div className="grid grid-cols-3 absolute top-1/2 w-full">
                            <div className="border-l-2 h-4" />
                            <div className="border-x-2 h-3" />
                            <div className="border-r-2 h-4" />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="-translate-x-1/2">{intl.formatTime(selectableDateTimes[0].toString())}</span>
                        <output
                            className="absolute -translate-x-1/2 -translate-y-1 w-16 bg-gray-6 rounded-xl text-center text-white px-2 py-1 z-20"
                            style={{ left: `${percentage}%` }}
                        >
                            {intl.formatTime(value.toString())}
                        </output>
                        <span className="translate-x-1/2">
                            {intl.formatTime(selectableDateTimes[selectableDateTimes.length - 1].toString())}
                        </span>
                    </div>
                </div>
            </div>
            <div className="hidden sm:block order-3" />
        </div>
    );
};
const generateSelectableDateTimes = (minDateTime: Temporal.PlainDateTime) => {
    const maxDateTime = minDateTime.add({ hours: 24 });
    const addDateTimesRecursively = (
        dateTimeToAdd: Temporal.PlainDateTime,
        array: Temporal.PlainDateTime[],
    ): Temporal.PlainDateTime[] => {
        if (Temporal.PlainDateTime.compare(dateTimeToAdd, maxDateTime) === 0) {
            return array;
        }

        return addDateTimesRecursively(dateTimeToAdd.add({ minutes: 15 }), [...array, dateTimeToAdd]);
    };

    return addDateTimesRecursively(minDateTime, []);
};
