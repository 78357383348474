import { useIntl } from 'react-intl';
import { PageHeading } from '../components/PageHeading.tsx';
import { Button, InputField, LoadingSpinner } from '@trawa-energy/ui-kit';
import { trpc } from '../utils/trpc';
import { useController, useForm } from 'react-hook-form';
import { useEffect } from 'react';

type AccountFormValues = { name: string };

export function Account() {
    const { data: account, refetch } = trpc.account.getCurrent.useQuery({});
    return account ? <AccountForm account={account} onSave={refetch} /> : <LoadingSpinner />;
}

function AccountForm({ account, onSave }: { account: AccountFormValues; onSave: () => unknown }) {
    const intl = useIntl();

    const { handleSubmit, formState, reset, control } = useForm<AccountFormValues>({
        defaultValues: account,
    });

    const { isPending, mutateAsync } = trpc.account.save.useMutation({});

    useEffect(() => {
        if (account) {
            reset({ name: account.name });
        }
    }, [reset, account]);

    const nameController = useController({
        name: 'name',
        control,
        rules: { required: true },
    });

    const save = async (data: AccountFormValues) => {
        await mutateAsync({ name: data.name });
        onSave();
    };

    return (
        <section>
            <PageHeading title={'account.title'} />
            <form onSubmit={handleSubmit(save)}>
                <fieldset className="space-y-4 w-full md:max-w-md" disabled={isPending}>
                    <InputField
                        {...nameController.field}
                        error={nameController.fieldState.error?.message}
                        type="text"
                        label={intl.formatMessage({ id: 'account.name' })}
                    />
                    <Button className="w-full" type="submit" disabled={isPending}>
                        {intl.formatMessage({ id: 'common.save' })}
                    </Button>
                </fieldset>
            </form>
        </section>
    );
}
