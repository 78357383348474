import { useController, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button, InputField, LoadingIcon } from '@trawa-energy/ui-kit';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAuthenticate } from '../../auth/useAuthenticate.ts';

type SignUpConfirmationFormValues = { email: string; password: string };

export function SignUpConfirmationForm({ onSuccess }: { onSuccess: () => unknown }) {
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const username = searchParams.get('username');
    const temporaryPassword = searchParams.get('code');
    const { isAuthenticated, isSigningIn, changeTemporaryPassword } = useAuthenticate();
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<SignUpConfirmationFormValues>({
        defaultValues: {
            password: '',
        },
    });

    const passwordController = useController({
        name: 'password',
        control,
        rules: { required: true },
    });

    const onSubmit = async (data: SignUpConfirmationFormValues) => {
        await changeTemporaryPassword(username!, temporaryPassword!, data.password);
        onSuccess();
    };

    return isSigningIn ? (
        <LoadingIcon />
    ) : !isAuthenticated ? (
        <form className="space-y-4 w-full md:max-w-md" onSubmit={handleSubmit(onSubmit)}>
            {/* <input type="text" style={{ visibility: 'hidden' }} name="username" value={username!} /> */}
            <InputField
                {...passwordController.field}
                error={passwordController.fieldState.error?.message}
                type="password"
                autoComplete="new-password"
                label={intl.formatMessage({ id: 'confirm.newPassword' })}
            />
            <Button className="w-full" type="submit">
                {intl.formatMessage({ id: 'confirm.submit' })}
            </Button>
        </form>
    ) : (
        <div className="text-xl text-gray-5">Authentication is completed! TODO</div>
    );
}
