import { Temporal } from 'temporal-polyfill';

export const getDMY = (input: string) => {
    const dmy = input.split('.').map(d => parseInt(d));
    return new Temporal.PlainDate(dmy[2], dmy[1], dmy[0]);
};

const formatSingleToDoubleDigit = (num: number) => {
    return num < 10 ? '0' + num : num;
};

export const formatToDDMMYYYY = (input: Temporal.PlainDate) => {
    return `${formatSingleToDoubleDigit(input.day)}.${formatSingleToDoubleDigit(input.month)}.${input.year}`;
};
