import { ReactNode } from 'react';

export function Section({ title, children }: { title: ReactNode; children: ReactNode }) {
    return (
        <div className="space-y-4">
            <div className="text-xl font-bold">{title}</div>
            <div>{children}</div>
        </div>
    );
}
