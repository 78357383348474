import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { NestedTable } from '../components/NestedTable.tsx';
import { Card } from '../components/Card.tsx';
import { useMemo } from 'react';
import { AccountType, useUserSettings } from '../userSettings.ts';
import { PageHeading } from '../components/PageHeading.tsx';

export function Products() {
    const intl = useIntl();
    const { data: account } = trpc.account.getCurrent.useQuery({});
    const { userSettings } = useUserSettings();
    const contractPartnerId =
        userSettings.accountType === AccountType.RealEstate ? userSettings.contractPartnerId : undefined;
    const { data: products, isLoading } = trpc.dashboard.getContractPartnerSupplyProducts.useQuery({
        contractPartnerId,
    });

    const tableData = useMemo(() => {
        return {
            headings: ['name', 'volume', 'price', 'startDate', 'endDate'].map(h =>
                intl.formatMessage({ id: `products.table.${h}` }),
            ),
            body: products
                ?.filter(p => !p.productType.match(/goo|trawa_fee|pv_selling|balancing_product/))
                .sort((a, b) => {
                    const nameA = intl.formatMessage({ id: 'products.table.' + a.productType });
                    const nameB = intl.formatMessage({ id: 'products.table.' + b.productType });
                    if (nameA === nameB) {
                        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                    }
                    return nameA.localeCompare(nameB);
                })
                .map(product => {
                    const endDate = new Date(product.endDate);
                    // subtract one minute to make it look like it's ending at the end of the day #605
                    const adaptedEndDate = new Date(endDate.getTime() - 1000 * 60);
                    return {
                        cells: [
                            intl.formatMessage({ id: 'products.table.' + product.productType }),
                            product.expectedYearlyVolumeKwh
                                ? intl.formatNumber(Math.round(product.expectedYearlyVolumeKwh))
                                : '',
                            product.fixedPrice ? intl.formatNumber(product.fixedPrice * 100) : '',
                            intl.formatDate(product.startDate || ''),
                            intl.formatDate(adaptedEndDate),
                        ],
                    };
                }),
        };
    }, [intl, products]);

    return (
        <section className="min-w-[600px]">
            <PageHeading title={'products.title'} />
            <Card>
                <NestedTable
                    headings={tableData ? tableData.headings : []}
                    rows={tableData.body || []}
                    emptyMessageKey="products.table.noData"
                    isLoading={isLoading}
                />
            </Card>
        </section>
    );
}
