import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Calendar, InputField } from '@trawa-energy/ui-kit';
import { Temporal } from 'temporal-polyfill';
import { formatToDDMMYYYY, getDMY } from '../utils/formatting/formatDateDMY';

const defaultTimeZone = 'Europe/Berlin';

export type DateRange = { from: Temporal.PlainDate; to: Temporal.PlainDate };

export function CalendarWrapper({
    value,
    onChange,
    single,
    showInputs,
}: {
    value: DateRange;
    onChange: (e: DateRange) => void;
    single?: boolean;
    showInputs?: boolean;
}) {
    const [selectedDates, setSelectedDates] = useState<
        { from: Temporal.PlainDate | undefined; to?: Temporal.PlainDate } | undefined
    >(value);
    const intl = useIntl();
    const timeZone = intl.timeZone ?? defaultTimeZone;

    const [displayMonth, setDisplayMonth] = useState(getDateFromPlainDate(value.from, timeZone));

    const handleChange = (
        dateRange:
            | {
                  from: Date | undefined;
                  to?: Date;
              }
            | undefined,
        selectedDay: Date,
    ) => {
        const change = {
            from: dateRange?.from ? getPlainDate(dateRange.from, timeZone) : undefined,
            to: dateRange?.to ? getPlainDate(dateRange.to, timeZone) : undefined,
        };

        const selectedDate = getPlainDate(selectedDay, timeZone);

        if (
            change.from &&
            change.to &&
            selectedDates?.from &&
            selectedDates?.to &&
            (selectedDates.from !== change.from || selectedDates.to !== change.to)
        ) {
            setSelectedDates({ from: selectedDate, to: undefined });
            setInputStart(change.from ? formatToDDMMYYYY(change.from) : '');
            setInputEnd('');
        } else {
            setSelectedDates(change);
            setInputStart(change.from ? formatToDDMMYYYY(change.from) : '');
            setInputEnd(change.to ? formatToDDMMYYYY(change.to) : '');
        }
    };

    const selectedDateVals = useMemo(() => {
        return {
            from: selectedDates?.from ? getDateFromPlainDate(selectedDates.from, timeZone) : undefined,
            to: selectedDates?.to ? getDateFromPlainDate(selectedDates.to, timeZone) : undefined,
        };
    }, [selectedDates?.from, selectedDates?.to, timeZone]);

    const [inputStart, setInputStart] = useState(selectedDates?.from?.toLocaleString(intl.locale) ?? '');
    const [inputEnd, setInputEnd] = useState(selectedDates?.to?.toLocaleString(intl.locale) ?? '');

    const invalidInputDateFormat = (input: string) => {
        const isValid = !input || input.match(/^(\d|0\d|1\d|2\d|3[01]).([1-9]|0[1-9]|1[012]).\d{4}$/);
        return !isValid ? intl.formatMessage({ id: 'filterControls.dateInputFormatError' }) : '';
    };

    const handleStartInput = (input: string) => {
        setInputStart(input);
        if (input && !invalidInputDateFormat(input)) {
            const start = getDMY(input);
            const endIsBeforeStart = Temporal.PlainDate.compare(selectedDates?.to || start, start) < 0;
            const end = endIsBeforeStart ? start.add({ days: 1 }) : selectedDates?.to;
            if (endIsBeforeStart) setInputEnd(end?.toLocaleString(intl.locale) ?? '');
            setSelectedDates({ from: start, to: end });
            setDisplayMonth(getDateFromPlainDate(start, timeZone));
        }
    };
    const handleEndInput = (input: string) => {
        setInputEnd(input);
        if (input && !invalidInputDateFormat(input)) {
            const end = getDMY(input);
            const endIsBeforeStart = Temporal.PlainDate.compare(end, selectedDates?.from || end) < 0;
            const start = endIsBeforeStart ? end.subtract({ days: 1 }) : selectedDates?.from;
            if (endIsBeforeStart) setInputStart(start?.toLocaleString(intl.locale) ?? '');
            setSelectedDates({ from: start, to: end });
            setDisplayMonth(getDateFromPlainDate(end.subtract({ months: 1 }), timeZone));
        }
    };

    return (
        <div>
            {showInputs && (
                <div className="flex gap-2 pb-6">
                    <div className="w-56">
                        <InputField
                            lean
                            label={intl.formatMessage({ id: 'filterControls.start' })}
                            placeholder={intl.formatMessage({ id: 'filterControls.dateInputFormat' })}
                            value={inputStart}
                            error={invalidInputDateFormat(inputStart)}
                            onChange={handleStartInput}
                        />
                    </div>
                    <div className="w-56">
                        <InputField
                            lean
                            label={intl.formatMessage({ id: 'filterControls.end' })}
                            placeholder={intl.formatMessage({ id: 'filterControls.dateInputFormat' })}
                            value={inputEnd}
                            error={invalidInputDateFormat(inputEnd)}
                            onChange={handleEndInput}
                        />
                    </div>
                </div>
            )}
            <Calendar
                initialFocus
                className="rounded border"
                mode="range"
                defaultMonth={selectedDateVals.from}
                month={displayMonth}
                onMonthChange={setDisplayMonth}
                selected={selectedDateVals}
                onSelect={handleChange}
                numberOfMonths={single ? 1 : 2}
            />
            <div className="flex justify-end">
                <Button
                    type="button"
                    disabled={!selectedDates?.from || (showInputs && !selectedDates?.to)}
                    className="bg-primary mt-4"
                    onClick={() =>
                        selectedDates?.from &&
                        onChange({ from: selectedDates.from, to: selectedDates.to ?? selectedDates.from })
                    }
                >
                    {intl.formatMessage({ id: 'filterControls.apply' })}
                </Button>
            </div>
        </div>
    );
}

function getPlainDate(date: Date, timeZone: string) {
    return Temporal.Instant.fromEpochMilliseconds(date.getTime()).toZonedDateTimeISO(timeZone).toPlainDate();
}

function getDateFromPlainDate(date: Temporal.PlainDate, timeZone: string) {
    return new Date(date.toZonedDateTime(timeZone).epochMilliseconds);
}
