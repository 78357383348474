import { ChevronDownIcon, ChevronUpIcon, cn } from '@trawa-energy/ui-kit';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

type RowData = {
    cells: ReactNode[];
    children?: RowData[][];
};

export function NestedTable({
    headings,
    rows,
    isLoading,
    emptyMessageKey,
}: {
    headings: string[];
    rows: RowData[];
    isLoading?: boolean;
    emptyMessageKey?: string;
}) {
    const intl = useIntl();

    return (
        <div role="table">
            <div className="px-2">
                <div
                    className="font-bold pr-12 grid"
                    style={{ gridTemplateColumns: `repeat(${headings.length}, minmax(0, 1fr))` }}
                >
                    {headings.map((heading, i) => (
                        <div key={i} className={`px-4 lg:px-6 2xl:px-10 py-4 ${i ? 'text-right' : 'text-left'}`}>
                            {heading || ''}
                        </div>
                    ))}
                </div>
            </div>
            <div className="rounded-md border border-gray-3 w-full overflow-hidden">
                <div className="text-sm lg:text-base">
                    {isLoading ? (
                        [1, 2, 3].map((row, i) => (
                            <TableRow key={i} firstEntry={!i} level={0} row={{ cells: [] }} keys={headings} isLoading />
                        ))
                    ) : rows.length ? (
                        rows.map((row, i) => <TableRow key={i} firstEntry={!i} level={0} row={row} keys={headings} />)
                    ) : (
                        <div className="p-4">{intl.formatMessage({ id: emptyMessageKey || 'common.noData' })}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

function TableRow({
    level,
    row,
    keys,
    firstEntry,
    boldFirstColumn = true,
    isLoading = false,
}: {
    level: number;
    row: RowData;
    keys: string[];
    firstEntry: boolean;
    boldFirstColumn?: boolean;
    isLoading?: boolean;
}) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            role="row"
            className={cn(
                'nested-table-row border-gray-2 transition',
                !level && 'p-2',
                !firstEntry && 'border-t',
                expanded ? 'bg-primary-5' : 'odd:bg-primary-1',
                !expanded && !level && row.children && 'hover:bg-primary-5 hover:text-white',
                isLoading && 'animate-pulse',
            )}
        >
            <div
                className={cn(
                    'relative grid pr-12',
                    firstEntry && level && 'bg-primary-2 font-bold',
                    expanded && 'text-white',
                    row.children && 'cursor-pointer',
                )}
                style={{ gridTemplateColumns: `repeat(${keys.length}, minmax(0, 1fr))` }}
                onClick={() => row.children && setExpanded(!expanded)}
            >
                {keys.map((key, index) =>
                    isLoading ? (
                        <div
                            key={index}
                            className={cn('h-10 px-4 lg:px-6 2xl:px-10 flex items-center', !!index && 'justify-end')}
                        >
                            <div className="w-20 h-2 max-w-full rounded-full bg-gray-2 opacity-60"></div>
                        </div>
                    ) : (
                        <div
                            role="cell"
                            key={key}
                            className={cn(
                                'px-4 lg:px-6 2xl:px-10 my-auto',
                                expanded ? 'pt-2 pb-4' : level ? 'p-4' : 'p-2',
                                index ? ' text-right' : ' text-left',
                                boldFirstColumn && !index && 'font-bold',
                            )}
                        >
                            {row.cells[index] || '-'}
                        </div>
                    ),
                )}
                {row.children && (
                    <button
                        aria-label={expanded ? 'collapse row' : 'expand row'}
                        className={`absolute right-6 top-2 ${expanded ? 'text-white' : 'text-primary'}`}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </button>
                )}
            </div>
            {row.children && expanded && (
                <div className="flex flex-col gap-2 text-xs">
                    {row.children?.map((child, i) => (
                        <div key={i} className="bg-primary-1 rounded-md overflow-hidden">
                            {child.map((r, n) => (
                                <TableRow key={n} firstEntry={!n} level={level + 1} row={r} keys={keys} />
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
