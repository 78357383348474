import { flatten } from 'flat';
import de_DE from './translations/de-DE.json';
import en_US from './translations/en-US.json';
import { Locales, LanguagesType } from './utils';

export const translations = {
    [Locales.DE]: de_DE,
    [Locales.EN]: en_US,
};

export const getTranslations = (locale: LanguagesType) => {
    return flatten(translations[locale]) as Record<string, string>;
};
