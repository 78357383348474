import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { LoginContainer } from './LoginContainer.tsx';
import { LoginForm } from './LoginForm.tsx';

export function Login() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const intl = useIntl();

    const onLoginSuccess = async () => {
        const returnUrl = searchParams.get('returnUrl') ?? '/';
        return navigate(returnUrl);
    };

    const faqKeys = ['password', 'dataProtection'];

    const [expandedFAQs, setExpandedFAQs] = useState<string[]>([]);

    const toggleFAQ = (key: string) => {
        if (expandedFAQs.includes(key)) {
            setExpandedFAQs(expandedFAQs.filter(exKey => exKey !== key));
        } else {
            setExpandedFAQs([...expandedFAQs, key]);
        }
    };

    const faqIconClass = 'md:hidden absolute right-4 top-4';

    return (
        <LoginContainer>
            <h1 className="text-2xl md:text-3xl font-bold pb-8 pt-16 md:pt-0 max-w-sm">
                {intl.formatMessage({ id: 'login.heading' })}
            </h1>
            <LoginForm onSuccess={onLoginSuccess} />
            <div className="w-full md:max-w-md pt-16 flex flex-col gap-2">
                {/* TODO: Can we remove this? <div className="font-bold text-xl">FAQ</div>
                                {faqKeys.map(key => (
                                <div
                                    className="border rounded-sm border-primary-blue-2 bg-primary-blue-1 p-4 relative cursor-pointer md:cursor-default"
                                    onClick={() => toggleFAQ(key)}
                                >
                                    <div className="font-bold">{intl.formatMessage({ id: 'faq.' + key + '.q' })}</div>
                                    {expandedFAQs.includes(key) ? (
                                        <ChevronUpIcon className={faqIconClass}></ChevronUpIcon>
                                    ) : (
                                        <ChevronDownIcon className={faqIconClass}></ChevronDownIcon>
                                    )}
                                    <div className={`${expandedFAQs.includes(key) ? '' : 'hidden'} md:block`}>
                                        {intl.formatMessage({ id: 'faq.' + key + '.a' })}
                                    </div>
                                </div>
                            ))} */}
            </div>
        </LoginContainer>
    );
}
