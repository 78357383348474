import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { LoginContainer } from '../login/LoginContainer.tsx'; // TODO: probably login should also go into the auth folder
import { SignUpConfirmationForm } from './SignUpConfirmationForm.tsx';

export function SignUpConfirmation() {
    const navigate = useNavigate();
    const intl = useIntl();

    return (
        <LoginContainer>
            <h1 className="text-2xl md:text-3xl font-bold pb-8 pt-16 md:pt-0 max-w-sm">
                {intl.formatMessage({ id: 'login.heading' })}
            </h1>
            <SignUpConfirmationForm onSuccess={() => navigate('/account')} />
        </LoginContainer>
    );
}
