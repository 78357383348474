import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { Card } from '../components/Card.tsx';
import { NestedTable } from '../components/NestedTable.tsx';
import { cn } from '@trawa-energy/ui-kit';
import { PageHeading } from '../components/PageHeading.tsx';
import { EChart } from '../components/EChart.tsx';
import { Temporal } from 'temporal-polyfill';

export function ExchangePrices() {
    const intl = useIntl();
    const { data: prices, isLoading } = trpc.prices.getAheadPrices.useQuery({});

    const formatPriceString = (price: number, unit: string) => {
        return `${intl.formatNumber(price, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })} ${intl.formatMessage({ id: `common.${unit}` })}`;
    };

    const isPast = (date: string) => {
        const parsedDate = Temporal.Instant.from(date);
        const now = Temporal.Now.instant();

        return parsedDate.epochMilliseconds < now.epochMilliseconds;
    };

    const formatTableRows = () => {
        const today = prices?.data[0];
        const tomorrow = prices?.data[1];

        if (!today) return [];

        const rows = [];

        for (let i = 0; i < today?.length; i++) {
            // hour
            const cell1 = `${intl.formatDate(today[i].timestampStart, { timeStyle: 'short' })} - ${intl.formatDate(today[i].timestampEnd, { timeStyle: 'short' })}`;
            // today's value in ct / kWh
            const cell2 = (
                <span className={cn(isPast(today[i].timestampEnd) && 'text-gray-4')}>
                    {formatPriceString(today[i].actualPrice, today[i].priceUnit)}
                </span>
            );
            // tomorrow's value in ct / kWh
            const cell3 = tomorrow?.[i] ? formatPriceString(tomorrow[i].actualPrice, tomorrow[i].priceUnit) : '-';

            rows.push({ cells: [cell1, cell2, cell3] });
        }

        return rows;
    };

    const getTodaysDate = () => {
        const today = Temporal.Now.plainDateISO();

        return today.toLocaleString(intl.locale, { month: 'numeric', day: 'numeric', year: 'numeric' });
    };

    const getTomorrowsDate = () => {
        const today = Temporal.Now.plainDateISO();
        const tomorrow = today.add({ days: 1 });

        return tomorrow.toLocaleString(intl.locale, { month: 'numeric', day: 'numeric', year: 'numeric' });
    };

    const formatTableHeadings = () => {
        const headings = ['hour', 'today', 'tomorrow'].map((h, i) => {
            return intl.formatMessage(
                { id: `exchangePrices.${h}` },
                { date: i < 2 ? getTodaysDate() : getTomorrowsDate() },
            );
        });

        return headings;
    };

    const tableData = {
        headings: formatTableHeadings(),
        body: !isLoading ? formatTableRows() : [],
    };

    const calcChartData = () => {
        if (prices === undefined) {
            return [];
        }

        return [
            ...prices.data[0].map(item => item.actualPrice.toFixed(2)),
            ...prices.data[1].map(item => item.actualPrice.toFixed(2)),
        ];
    };

    const calcXAxisData = () => {
        if (prices === undefined) {
            return [];
        }

        return [
            ...prices.data[0].map(item => `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`),
            ...prices.data[1].map(item => `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`),
        ];
    };

    const priceUnit = prices?.data[0][0].priceUnit ?? '';

    console.log(tableData);

    return (
        <section>
            <PageHeading title={'exchangePrices.title'} />

            <Card className="mb-6">
                <EChart
                    isLoading={isLoading}
                    option={{
                        grid: {
                            left: '3%',
                            right: '3%',
                            bottom: '3%',
                            containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                            data: calcXAxisData(),
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: intl.formatMessage({ id: 'exchangePrices.yAxisLabel' }),
                            nameGap: 30,
                            nameTextStyle: {
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: 'black',
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'line',
                            },
                            valueFormatter: value => {
                                if (value === undefined) {
                                    return '';
                                }

                                return formatPriceString(value as number, priceUnit);
                            },
                        },
                        visualMap: [
                            {
                                show: false,
                                type: 'continuous',
                                seriesIndex: 0,
                                min: 0,
                                max: 30,
                                inRange: {
                                    color: ['#27ae60', '#f1c40f', '#e74c3c'],
                                },
                            },
                        ],
                        series: [
                            {
                                name: intl.formatMessage({ id: 'exchangePrices.price' }),
                                type: 'line',
                                step: 'end',
                                data: calcChartData(),
                                symbol: 'none',
                                markLine: {
                                    symbol: 'none',
                                    lineStyle: {
                                        type: 8,
                                        color: '#404040',
                                    },
                                    data: [
                                        {
                                            xAxis: Temporal.Now.plainTimeISO().hour,
                                            label: {
                                                formatter: intl.formatMessage({ id: 'exchangePrices.now' }),
                                            },
                                        },
                                        {
                                            xAxis: prices?.data[0].length ?? 0,
                                            label: {
                                                formatter: getTomorrowsDate(),
                                            },
                                        },
                                    ],
                                },
                                markArea: {
                                    itemStyle: {
                                        color: '#F3F3F3',
                                        opacity: 0.5,
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            color: '#F3F3F3',
                                        },
                                    },
                                    data: [
                                        [
                                            {
                                                xAxis: 0,
                                            },
                                            {
                                                xAxis: Temporal.Now.plainTimeISO().hour,
                                            },
                                        ],
                                    ],
                                },
                            },
                        ],
                    }}
                />
            </Card>
            <Card>
                <NestedTable headings={tableData.headings} rows={tableData.body} isLoading={isLoading} />
            </Card>
        </section>
    );
}
