import { FC } from 'react';
import { DropdownField } from '@trawa-energy/ui-kit';
import { FilterValues } from '../../utils/useFilterValues';
import { useIntl } from 'react-intl';

type Props = {
    marketLocations: { id: string; label: string }[];
    filterValues: FilterValues;
    setFilterValues: (values: FilterValues) => void;
    isLoading?: boolean;
};

export const MarketLocationFilterControls: FC<Props> = ({
    marketLocations,
    filterValues,
    setFilterValues,
    isLoading = false,
}) => {
    const intl = useIntl();

    if (marketLocations.length < 1) {
        return undefined;
    }

    return (
        <div className="w-full md:max-w-[250px] dropdown-marketlocation">
            <DropdownField
                label={intl.formatMessage({ id: 'filterControls.marketLocations.label' })}
                lean
                disabled={isLoading}
                value={
                    marketLocations.find(location => location.id === filterValues.marketLocationId)?.id ??
                    marketLocations[0].id
                }
                options={marketLocations.map(location => ({
                    text: location.label,
                    value: location.id,
                }))}
                onChange={option => {
                    if (option === null || typeof option.value !== 'string') {
                        return;
                    }

                    setFilterValues({
                        ...filterValues,
                        marketLocationId: option.value,
                    });
                }}
            />
        </div>
    );
};
