import { useCallback, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, PolygonF } from '@react-google-maps/api';
import { Cluster } from '@react-google-maps/marker-clusterer';
import { config } from '../../config';
import { MapLocation } from '../../constants/locations';
import { getMapOptions, MapOption } from '../../utils/getMapOptionStyles';
import { trpc } from '../../utils/trpc';
import { defaultCoordinates, defaultZoom, defaultBoundingBox, germanStates } from './mapStyles';
import { MarkerClusterer } from './MarkerCluster';
import { useIntl } from 'react-intl';
import { LoadingSpinner } from '@trawa-energy/ui-kit';

const mapOptions = getMapOptions(MapOption.minimal);

export function MapWithMarkers({ setsOfLocations }: { setsOfLocations: MapLocation[][] }) {
    const { formatMessage } = useIntl();
    const { data: germanCoordinates } = trpc.assets.getGermanCoordinates.useQuery();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: config.googleMapsApiKey,
    });
    const [center] = useMemo(() => [defaultCoordinates], []);
    const [zoom, setZoom] = useState(defaultZoom);
    const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

    const onLoad = useCallback((mapItem: google.maps.Map) => {
        setMapInstance(mapItem);
        mapItem.fitBounds(defaultBoundingBox);
    }, []);

    const onUnmount = useCallback(() => setMapInstance(null), []);

    const onClusterClick = useCallback(
        (cluster: Cluster) => {
            if (mapInstance && cluster.center) {
                mapInstance.panTo({
                    lat: cluster.center?.lat(),
                    lng: cluster.center?.lng(),
                });
                setZoom(zoom + 1);
            }
        },
        [mapInstance, zoom],
    );

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <LoadingSpinner />
                {formatMessage({ id: 'locations.loadingMaps' })}
            </div>
        );
    }

    return (
        <GoogleMap
            mapContainerStyle={mapOptions.mapContainerStyles}
            zoom={zoom}
            options={mapOptions.options}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {setsOfLocations.map((locations, index) => (
                <MarkerClusterer
                    key={index}
                    onClusterClick={onClusterClick}
                    locations={locations}
                    clusterProps={{ styles: locations[0]?.styles }}
                    clusterType={locations[0]?.type}
                />
            ))}
            {germanCoordinates &&
                germanCoordinates.map(({ paths, name }, index) => (
                    <PolygonF key={index} paths={paths} options={germanStates[name]} />
                ))}
        </GoogleMap>
    );
}
