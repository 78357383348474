import { useIntl } from 'react-intl';
import { Button } from '@trawa-energy/ui-kit';
import { Heading } from '../Heading.tsx';
import { Section } from '../Section.tsx';

export function LinkRequestFailed({ error, onTryAgain }: { error: Error | undefined; onTryAgain: () => void }) {
    const intl = useIntl();

    const tryAgainButton = (
        <div className="pt-4">
            <Button type="button" className="w-full" onClick={() => onTryAgain()}>
                {intl.formatMessage({ id: 'magicLink.requestNewLinkButton' })}
            </Button>
        </div>
    );

    return error?.message === 'NOT_NOW_ERROR.' ? (
        <div>
            <Heading>{intl.formatMessage({ id: 'login.heading' })}</Heading>
            <div className="space-y-4">
                <Section title={intl.formatMessage({ id: 'magicLink.login.title' })}>
                    <div>
                        {intl.formatMessage({
                            // unfortunately a dot "." is added by an authentication challenge handler to the end of the message
                            id: `magicLink.errors.${error.message.replace(/\.$/g, '')}`,
                        })}
                    </div>
                </Section>
                {tryAgainButton}
            </div>
        </div>
    ) : (
        <div>
            <Heading>{intl.formatMessage({ id: 'magicLink.unknownError.heading' })}</Heading>
            <div className="space-y-4">
                <Section title={intl.formatMessage({ id: 'magicLink.unknownError.title' })}>
                    <div>{intl.formatMessage({ id: 'magicLink.unknownError.body' })}</div>
                </Section>
                {tryAgainButton}
            </div>
        </div>
    );
}
