import { useIntl } from 'react-intl';
import { Button } from '@trawa-energy/ui-kit';
import { Heading } from '../Heading.tsx';
import { Section } from '../Section.tsx';

export function LinkRequestSucceeded({ onTryAgain }: { onTryAgain: () => void }) {
    const intl = useIntl();

    return (
        <div>
            <Heading>{intl.formatMessage({ id: 'login.heading' })}</Heading>
            <div className="space-y-4">
                <div className="space-y-16">
                    <Section title={intl.formatMessage({ id: 'magicLink.linkSent.title' })}>
                        {intl.formatMessage({ id: 'magicLink.linkSent.body' })}
                    </Section>
                    <Section title={intl.formatMessage({ id: 'magicLink.linkNotReceivedYet.title' })}>
                        {intl.formatMessage({ id: 'magicLink.linkNotReceivedYet.body' })}
                    </Section>
                </div>
                <div className="pt-4">
                    <Button type="button" className="w-full" onClick={() => onTryAgain()}>
                        {intl.formatMessage({ id: 'magicLink.requestNewLinkButton' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}
