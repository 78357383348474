import { useAtom } from 'jotai/react';
import { createPartialUserSettings, partialUserSettingsAtom } from '../userSettings';
import { trpc } from '../utils/trpc';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { loginPath } from './router';
import { makeRedirectUrl } from './makeRedirectUrl';
import { useHybridAuthenticate } from '../auth/useHybridAuthenticate';

export function AuthenticationGuard() {
    const location = useLocation();
    const navigate = useNavigate();
    const { authState } = useHybridAuthenticate();
    const { data: account } = trpc.account.getCurrent.useQuery({}, { enabled: authState.isAuthenticated });
    const [userSettings, setUserSettings] = useAtom(partialUserSettingsAtom);

    useEffect(() => {
        if (account && (!userSettings || account?.id !== userSettings.accountId)) {
            setUserSettings(createPartialUserSettings({ account }));
        }
    }, [account, setUserSettings, userSettings]);

    useEffect(() => {
        if (authState.status === 'NOT_SIGNED_IN') {
            navigate(makeRedirectUrl({ to: loginPath, returnUrl: location.pathname + location.search }));
        }
    }, [authState.status, location.pathname, location.search, navigate]);

    if (
        authState.status === 'CHECKING' ||
        authState.status === 'REFRESHING_SIGN_IN' ||
        authState.status === 'SIGNING_IN'
    ) {
        return null;
    }

    if (!userSettings) {
        return null; // settings are being loaded
    }

    return <Outlet />;
}
