import { createTRPCClient, httpBatchLink, httpLink } from '@trpc/client';
import { config } from '../config';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { getStoredAccessToken } from '../auth/useHybridAuthenticate.ts';

const batchedOrSeparateLink = config.doNotBatchRequests ? httpLink : httpBatchLink; // if test, then run requests separately
export const standaloneTrpcClient = createTRPCClient<AppRouter>({
    links: [
        batchedOrSeparateLink({
            url: config.portalApiBaseUrl,
            async headers() {
                if (!getStoredAccessToken) {
                    throw new Error('getStoredAccessToken is not yet loaded. Must be a race condition.');
                }

                const token = await getStoredAccessToken();
                return {
                    authorization: token ? `Bearer ${token}` : undefined,
                };
            },
        }),
    ],
});
