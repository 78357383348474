export enum Locales {
    DE = 'de-DE',
    EN = 'en-US',
}

export enum TimeZones {
    EUROPE_BERLIN = 'Europe/Berlin',
}

export type TimeZoneType = `${TimeZones}`;
export type LanguagesType = `${Locales}`;
