import { IntlShape } from 'react-intl';
import { Interval, Period } from '../useFilterValues';

export function formatAxisDateTimeByInterval(
    intl: IntlShape,
    dateTime: Date,
    interval: Interval,
    recordsTotal: number,
    period: Period,
    timeZone: string,
) {
    switch (interval) {
        case '15min':
            if (recordsTotal > 570) {
                return intl.formatDate(dateTime, { month: 'short', day: 'numeric', timeZone });
            }
            if (recordsTotal > 100) {
                return intl.formatDate(dateTime, {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone,
                });
            }
            return intl.formatDate(dateTime, { hour: 'numeric', minute: 'numeric', timeZone });
        case 'hour':
            if (recordsTotal > 120) {
                return intl.formatDate(dateTime, { month: 'short', day: 'numeric', timeZone });
            }
            if (recordsTotal > 24) {
                return intl.formatDate(dateTime, {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone,
                });
            }
            return intl.formatDate(dateTime, { hour: 'numeric', minute: 'numeric', timeZone });
        case 'day':
            if (recordsTotal < 9) {
                return intl.formatDate(dateTime, { weekday: 'short', timeZone });
            } else if (recordsTotal < 40) {
                return intl.formatDate(
                    dateTime,
                    period.includes('Month') ? { day: 'numeric' } : { month: 'short', day: 'numeric', timeZone },
                );
            } else {
                return intl.formatDate(dateTime, { month: 'short', timeZone });
            }
        case 'week':
            return intl.formatDate(dateTime, { year: 'numeric', month: 'short', day: 'numeric', timeZone });
        case 'month':
            return intl.formatDate(dateTime, { year: 'numeric', month: 'short', timeZone });
        default:
            throw new Error(`Unexpected interval: ${interval}`);
    }
}
