import React from 'react';

export function Card({
    children,
    className,
    padding,
    testId,
}: React.PropsWithChildren<{ className?: string; padding?: string; testId?: string }>) {
    return (
        <div
            className={`border border-muted-foreground rounded-xl bg-card ${padding || 'p-4'} ${className || ''}`}
            data-testid={testId}
        >
            {children}
        </div>
    );
}
