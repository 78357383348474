import React from 'react';
import {
    BoltIcon,
    ChartLineIcon,
    DocumentIcon,
    EuroIcon,
    FixedProductListIcon,
    LabelIcon,
    LocationPinIcon,
    WorldIcon,
} from '@trawa-energy/ui-kit';
import { useFeatureFlags } from './useFeatureFlags.ts';

export type NavItem = {
    id: string;
    translationKey: string;
    icon: React.ReactNode;
    path: string;
    hidden: boolean;
};

export const useNavItems = () => {
    const { isManageOnly, isClusterEnabled } = useFeatureFlags();

    const navItems = {
        overview: {
            id: 'overview',
            translationKey: 'overview.title',
            icon: <WorldIcon />,
            path: '/overview',
            hidden: false,
        },
        consumption: {
            id: 'consumptionView',
            translationKey: 'consumptionView.title',
            icon: <ChartLineIcon />,
            path: '/consumption',
            hidden: isManageOnly || isClusterEnabled,
        },
        products: {
            id: 'products',
            translationKey: 'products.title',
            icon: <FixedProductListIcon />,
            path: '/products',
            hidden: isManageOnly,
        },
        locations: {
            id: 'locations',
            translationKey: 'locations.title',
            icon: <LocationPinIcon />,
            path: '/locations',
            hidden: isManageOnly,
        },
        costPrediction: {
            id: 'costPrediction',
            translationKey: 'costPrediction.title',
            icon: <EuroIcon />,
            path: '/cost-prediction',
            hidden: isManageOnly,
        },
        exchangePrices: {
            id: 'exchangePrices',
            translationKey: 'exchangePrices.title',
            icon: <LabelIcon />,
            path: '/exchange-prices',
            hidden: false,
        },
        documents: {
            id: 'documents',
            translationKey: 'documents.title',
            icon: <DocumentIcon />,
            path: '/documents',
            hidden: isManageOnly,
        },
        pvGeneration: {
            id: 'pvGeneration',
            translationKey: 'pvGeneration.title',
            icon: <BoltIcon />,
            path: '/pv-generation',
            hidden: true,
        },
        marketLocations: {
            id: 'marketLocations',
            translationKey: 'marketLocationsList.title',
            icon: <LocationPinIcon />,
            path: '/market-locations',
            hidden: true,
        },
        analysis: {
            id: 'analysis',
            translationKey: 'analysis.title',
            icon: <ChartLineIcon />,
            path: '/analysis',
            hidden: !isManageOnly && !isClusterEnabled,
        },
    } satisfies Record<string, NavItem>;

    return {
        navigationItems: navItems,
    };
};
