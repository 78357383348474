import { ReactNode } from 'react';
import { BrandBackground } from '../../components/brand-background/BrandBackground';

export function LoginContainer({ children }: { children: ReactNode }) {
    return (
        <div className="flex w-full h-screen overflow-hidden">
            <BrandBackground />
            <div className="w-full md:w-[469px] 2xl:w-[40%] overflow-auto p-4 md:p-10 xl:p-16 2xl:px-24">
                {children}
            </div>
        </div>
    );
}
