import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { config } from '../config.ts';

export const initSentry = () => {
    if (config.sentryEnabled) {
        Sentry.init({
            environment: config.sentryEnvironment,
            release: 'customer-portal',
            dsn: config.sentryDsnToken,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                ...(config.sentryEnvironment === 'production'
                    ? [
                          Sentry.replayIntegration({
                              maskAllText: false,
                              blockAllMedia: false,
                          }),
                      ]
                    : []),
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            tracesSampleRate: 1.0,
            tracePropagationTargets: [
                /^https:\/\/api\.trawa\.energy\/portal/,
                /^https:\/\/api\.production\.trawa\.de\/portal/,
            ],
        });

        const searchParams = new URL(window.location.href).searchParams;
        if (searchParams.has('testMode')) {
            Sentry.setTag('testMode', 'true');
        }
    }
};
