import { useIntl } from 'react-intl';
import { AccountType, useUserSettings } from '../userSettings.ts';
import { trpc } from '../utils/trpc.js';
import { IconTooltip } from '@trawa-energy/ui-kit';

export const PageHeading = ({ title }: { title: string }) => {
    const { data: account } = trpc.account.getCurrent.useQuery({});
    const { userSettings } = useUserSettings();
    const { data: contractPartners } = trpc.dashboard.getContractPartners.useQuery({}, {});

    const isRealEstateUser = userSettings.accountType === AccountType.RealEstate;
    const contractPartnerName =
        isRealEstateUser &&
        contractPartners?.find(contractPartner => contractPartner.contractPartnerId === userSettings.contractPartnerId)
            ?.contractPartnerName;

    const intl = useIntl();
    return (
        <div className="mb-6">
            <h1 className="text-2xl md:text-[2rem] font-bold">
                {intl.formatMessage({ id: title })} {account?.name}
            </h1>
            {isRealEstateUser && (
                <div className="mt-2 flex gap-4">
                    <h2>{intl.formatMessage({ id: 'realEstate.subtext.text' }, { partner: contractPartnerName })}</h2>
                </div>
            )}
        </div>
    );
};
