import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageHeading } from '../components/PageHeading.tsx';

export const MarketLocationsList: FC = () => {
    const { formatMessage } = useIntl();

    return (
        <section>
            <PageHeading title={formatMessage({ id: 'marketLocationsList.title' })} />
            TODO: Implement MarketLocationsList
        </section>
    );
};
