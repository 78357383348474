import { useIntl } from 'react-intl';
import { CalendarIcon, cn, Popover, PopoverContent, PopoverTrigger } from '@trawa-energy/ui-kit';
import { CalendarWrapper } from './CalendarWrapper';
import { FilterValues } from '../utils/useFilterValues';
import { useState } from 'react';
import { Temporal } from 'temporal-polyfill';

export function CustomPeriodPopover({
    filterValues,
    selectedDates,
    setFilterValues,
    showInputs,
}: {
    filterValues: FilterValues;
    selectedDates: { from: Temporal.PlainDate; to: Temporal.PlainDate };
    setFilterValues: (values: FilterValues) => void;
    showInputs?: boolean;
}) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const isSelected = filterValues.period === 'custom';
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <button
                    aria-pressed={isSelected}
                    className={cn(
                        'bg-white font-bold p-2 2xl:px-4 2xl:py-3 flex flex-nowrap gap-2 border hover:text-primary text-sm 2xl:text-base',
                        isSelected && 'bg-primary-2',
                        showInputs
                            ? 'border-gray-2 bg-white font-bold rounded-md'
                            : 'md:rounded-none md:border-0 rounded-xl',
                        !isSelected && !showInputs && 'text-gray-4 hover:bg-primary-blue-1',
                    )}
                >
                    <CalendarIcon className="text-primary h-5 2xl:h-6" />
                    <span>
                        {isSelected && selectedDates
                            ? selectedDates.from.toLocaleString(intl.locale) +
                              ' - ' +
                              selectedDates.to.toLocaleString(intl.locale)
                            : intl.formatMessage({ id: `filterControls.periods.custom` })}
                    </span>
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-4 bg-white" align="start">
                <CalendarWrapper
                    value={selectedDates}
                    showInputs={showInputs}
                    onChange={dateRange => {
                        setFilterValues({
                            ...filterValues,
                            period: 'custom',
                            from: Temporal.PlainDate.from(dateRange.from),
                            to: Temporal.PlainDate.from(dateRange.to),
                        });
                        setOpen(false);
                    }}
                />
            </PopoverContent>
        </Popover>
    );
}
