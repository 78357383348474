import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DropdownField } from '@trawa-energy/ui-kit';
import { FilterValues, Interval } from '../../utils/useFilterValues';
import { getDateRangeForPeriod } from '../../utils/getDateRangeForPeriod';
import { Temporal } from 'temporal-polyfill';

type Props = {
    filterValues: FilterValues;
    setFilterValues: (values: FilterValues) => void;
    showDayOption?: boolean;
};

export const IntervalFilterControls: FC<Props> = ({ filterValues, setFilterValues, showDayOption = true }) => {
    const intl = useIntl();
    const [intervalOptions, setIntervalOptions] = useState<Interval[]>([]);

    useEffect(() => {
        const intervalOptionKeys = ['15min', 'hour', 'day'] as Interval[];
        const today = Temporal.Now.plainDateISO();
        const range =
            filterValues.period !== 'custom' ? getDateRangeForPeriod(today, filterValues.period) : filterValues;
        const totalDays = range.from.until(range.to).days;
        const intervalOptions = intervalOptionKeys.filter(optionKey => {
            if (optionKey === '15min' && totalDays > 7) {
                return false;
            }

            if (optionKey === 'hour' && totalDays > 31) {
                return false;
            }

            if (!showDayOption && optionKey === 'day' && totalDays === 0) {
                return false;
            }

            return true;
        });

        setIntervalOptions(intervalOptions);

        if (!intervalOptions.includes(filterValues.interval)) {
            setFilterValues({
                ...filterValues,
                interval: intervalOptions[intervalOptions.length - 1],
            });
        }
    }, [filterValues, setFilterValues, showDayOption]);

    return (
        <div className="w-full md:max-w-52">
            <DropdownField
                label={intl.formatMessage({ id: 'filterControls.intervals.label' })}
                lean
                options={intervalOptions.map(option => ({
                    text: intl.formatMessage({ id: 'filterControls.intervals.' + option }),
                    value: option,
                }))}
                onChange={option => {
                    if (option?.value === undefined) {
                        return;
                    }

                    setFilterValues({
                        ...filterValues,
                        interval: option.value,
                    });
                }}
                value={filterValues.interval}
            />
        </div>
    );
};
