import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { NestedTable } from '../components/NestedTable.tsx';
import { Card } from '../components/Card.tsx';
import { PageHeading } from '../components/PageHeading.tsx';
import { useContractPartnerId } from '../userSettings.ts';

type CostPredictionData = {
    priceComponent: string;
    actualPrice: number;
    priceUnit: string;
    actualVolume: number;
    volumeUnit: string;
    actualCost: number;
    costUnit: string;
};

export function CostPrediction() {
    const intl = useIntl();

    const contractPartnerId = useContractPartnerId();
    const { data: report, isLoading } = trpc.costPrediction.getReport.useQuery({
        contractPartnerId: contractPartnerId,
    });

    const formatTableRow = (data?: CostPredictionData, start?: string) => {
        if (!data) return [];
        const isApproximate = data.priceComponent === 'totalExpectedElectricity';
        const decimalCount = /offshoreGridCharge|kwkCharge/.test(data.priceComponent) ? 3 : 2;
        return [
            start
                ? intl.formatDate(start, { month: 'long', year: 'numeric' })
                : intl.formatMessage({ id: `costPrediction.table.${data.priceComponent}` }),
            (isApproximate ? `${intl.formatMessage({ id: 'common.approx' })} ` : '') +
                `${intl.formatNumber(data.actualPrice, {
                    minimumFractionDigits: decimalCount,
                    maximumFractionDigits: decimalCount,
                })} ${intl.formatMessage({ id: `common.${data.priceUnit}` })}`,
            `${intl.formatNumber(data.actualVolume, {
                maximumFractionDigits: 0,
            })} ${intl.formatMessage({ id: `common.${data.volumeUnit}` }, { num: Math.round(data.actualVolume) })}`,
            (isApproximate ? `${intl.formatMessage({ id: 'common.approx' })} ` : '') +
                intl.formatNumber(data.actualCost, {
                    style: 'currency',
                    currency: data.costUnit,
                    maximumFractionDigits: 0,
                }),
        ];
    };

    const formatTableData = () => {
        return (
            report?.costPredictionData.map(month => {
                const cellOrder = [
                    'totalCosts',
                    'totalTrawaPortfolio',
                    'totalExpectedElectricity',
                    'goo',
                    'trawaFee',
                    'totalGridCharges',
                    'gridChargesWork',
                    'gridChargesPower',
                    'meteringCharge',
                    'totalTaxesAndFees',
                    'kwkCharge',
                    'nevChargeBelow_1gwh',
                    'nevChargeAbove_1gwh',
                    'concessionCharge',
                    'offshoreGridCharge',
                    'electricityTaxCharge',
                ];

                const values = month.values.sort(
                    (a, b) => cellOrder.indexOf(a.priceComponent) - cellOrder.indexOf(b.priceComponent),
                );

                const sectionOneEnd = 4;
                const sectionTwoEnd = 8;

                return {
                    cells: formatTableRow(values[0], month.index.startDate),
                    children: [
                        values
                            .filter((entry, i) => !!i && i <= sectionOneEnd)
                            .map(entry => ({ cells: formatTableRow(entry) })),
                        values
                            .filter(
                                (entry, i) =>
                                    i > sectionOneEnd &&
                                    i <= sectionTwoEnd &&
                                    !(entry.priceComponent.includes('nev') && !entry.actualCost),
                            )
                            .map(entry => ({ cells: formatTableRow(entry) })),
                        values
                            .filter(
                                (entry, i) =>
                                    i > sectionTwoEnd && !(entry.priceComponent.includes('nev') && !entry.actualCost),
                            )
                            .map(entry => ({ cells: formatTableRow(entry) })),
                    ],
                };
            }) || []
        );
    };

    const tableData = {
        headings: ['month', 'price', 'volume', 'monthlyCost'].map(h =>
            intl.formatMessage({ id: `costPrediction.table.${h}` }),
        ),
        body: formatTableData(),
    };

    return (
        <section className="min-w-[600px] min-h-[102vh]">
            <PageHeading title={'costPrediction.title'} />
            <Card>
                <NestedTable
                    headings={tableData.headings}
                    rows={tableData.body}
                    isLoading={isLoading}
                    emptyMessageKey="costPrediction.table.noData"
                />
            </Card>
        </section>
    );
}
