import { Card } from './Card';

export function StatsCardSkeleton() {
    const skeletonClassBase = 'bg-gray-1 rounded-lg';
    return (
        <div className="animate-pulse">
            <div className="mb-6 h-10 w-80 rounded-lg bg-gray-2"></div>
            <Card padding="p-0" className="overflow-auto">
                <div className="flex flex-col-reverse md:flex-row">
                    <div className="p-3 md:p-8 md:w-2/3 flex flex-col items-start gap-4 min-h-[410px]">
                        <div className={`h-7 w-28 ${skeletonClassBase}`}></div>
                        <div className={`h-5 w-36 ${skeletonClassBase}`}></div>
                        <div className={`h-72 w-full ${skeletonClassBase}`}></div>
                    </div>
                    <div className="bg-gray-2 w-full md:w-1/3 h-60 md:h-auto min-h-[240px]"></div>
                </div>
            </Card>
        </div>
    );
}
