import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, httpLink, TRPCClientError } from '@trpc/client';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { trpc } from './utils/trpc.ts';
import { config } from './config.ts';
import { loginPath } from './routing/router.tsx';

function redirectIfBadAccessTokenError(error: unknown) {
    console.error(error);
    if (
        (error instanceof TRPCClientError && error.message === 'UNAUTHORIZED') ||
        (error instanceof Error && error.name === 'UserNotFoundException') // UserNotFoundException is a cognito error
    ) {
        console.log(error);
        if (new URL(window.location.href).pathname.startsWith(loginPath)) {
            throw new Error('Login flow is probably broken. Will not redirect.'); // TODO: there is a case when a refresh token expires, this may happen. why?
        }
        window.location.href = loginPath;
    }
}

export default function AuthTRpcProviders({
    children,
    getAccessToken,
}: {
    children: React.ReactElement | React.ReactElement[];
    getAccessToken: () => Promise<string | undefined>;
}) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    onError: (error, query) => {
                        if (
                            window.navigator.webdriver &&
                            error instanceof TRPCClientError &&
                            error.message.includes('Failed to fetch')
                        ) {
                            // this is a network error, not a trpc error
                            // during e2e this is getting thrown, due to fast redirects disable it for now.
                            return;
                        }

                        Sentry.captureException(error);
                        Sentry.setContext('queryClient queryCache', {
                            error,
                            queryHash: JSON.stringify(query.queryHash),
                        });
                        redirectIfBadAccessTokenError(error);
                    },
                }),
                mutationCache: new MutationCache({
                    onError: (error, variables) => {
                        Sentry.captureException(error);
                        Sentry.setContext('queryClient mutationCache', {
                            error,
                            variables: JSON.stringify(variables),
                        });
                        redirectIfBadAccessTokenError(error);
                    },
                }),
                defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
            }),
    );

    const batchedOrSeparateLink = config.doNotBatchRequests ? httpLink : httpBatchLink; // if we mock API, then run requests separately
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                batchedOrSeparateLink({
                    url: config.portalApiBaseUrl,
                    async headers() {
                        try {
                            const token = await getAccessToken();
                            // when the token expires, cognito gets called to refresh it. There might be an error, like "UserNotFoundException"
                            return token
                                ? {
                                      authorization: `Bearer ${token}`,
                                  }
                                : {};
                        } catch (error) {
                            redirectIfBadAccessTokenError(error);
                            throw error;
                        }
                    },
                }),
            ],
        }),
    );

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </trpc.Provider>
    );
}
