import React, { FC, ReactNode } from 'react';
import { PowerSupplier as PowerSupplierT, PowerSupplierProps } from './types.ts';
import { useIntl } from 'react-intl';
import { FuturesIcon, SolarPpaIcon, SpotIcon, SpotLogoIcon, WindPpaIcon } from '@trawa-energy/ui-kit';
import { PowerChip } from './PowerChip.tsx';
import { calcAnimationSpeedPercentage, getGridBreak, getOffsetByNumOfNodes, useFormatKwh } from './utils.ts';
import { GridLine } from '../GridLine.tsx';
import { Box } from './Box.tsx';

const powerSupplyTypeToGradientMap: Record<PowerSupplierT['type'], string> = {
    wind: 'linear-gradient(180deg, hsl(var(--primary-blue-2)) 0%, hsl(var(--accent-aqua-3)) 100%)',
    solar: 'linear-gradient(180deg, hsl(var(--solar)) 0%, hsl(var(--orange)) 100%)',
    futures: 'linear-gradient(180deg, hsl(var(--primary-green-4)) 0%, hsl(var(--primary-green-5)) 75.59%)',
    spot: 'linear-gradient(180deg, hsl(var(--gray-3)) 0%, hsl(var(--gray-4)) 100%)',
};

const powerSupplyTypeToColorMap: Record<PowerSupplierT['type'], string> = {
    wind: 'hsl(var(--accent-aqua-3))',
    solar: 'hsl(var(--orange))',
    futures: 'hsl(var(--primary-green-5))',
    spot: 'hsl(var(--gray-4))',
};

export const PowerSupplier: FC<PowerSupplierProps> = ({
    id,
    kwh,
    direction,
    endElementId,
    type,
    numberOfPpas,
    index,
    maxMarketLocationPeakKwh,
    isTvScreen = false,
}) => {
    const intl = useIntl();
    const formatKwh = useFormatKwh();
    const typeToIconMap: Record<PowerSupplierT['type'], ReactNode> = {
        spot: <SpotIcon className="w-6 2xl:w-10" transform={direction === 'receiving' ? 'scale(1 -1)' : ''} />,
        solar: <SolarPpaIcon className="w-6 2xl:w-10" />,
        wind: <WindPpaIcon className="w-6 2xl:w-10" />,
        futures: <FuturesIcon className="w-6 2xl:w-10" />,
    };

    const boxStyle = isTvScreen
        ? 'w-36 flex-col items-center py-2 px-5 text-xs sm:text-base'
        : 'w-20 sm:w-28 2xl:w-36 flex-col items-center p-2 2xl:py-4 text-xs sm:text-base';

    const powerSupplyLabelStyle = isTvScreen ? 'font-bold text-xl' : 'font-bold 2xl:text-xl';
    return (
        <div>
            <div className="flex items-center flex-col">
                <Box id={id} className={boxStyle}>
                    {typeToIconMap[type]}
                    <span className={powerSupplyLabelStyle}>
                        {intl.formatMessage({ id: `overview.powerSupplier-${type}` })}
                    </span>
                </Box>
                <div className="mt-4">
                    <PowerChip color={powerSupplyTypeToGradientMap[type]} isHidden={kwh === null}>
                        {kwh !== null && formatKwh(kwh)}
                    </PowerChip>
                </div>
            </div>
            <GridLine
                start={id}
                end={endElementId}
                startAnchor="bottom"
                endAnchor={{
                    position: 'top',
                    offset: {
                        x: getOffsetByNumOfNodes(numberOfPpas)[index],
                        y: 0,
                    },
                }}
                animationSpeedPercentage={
                    kwh !== null ? calcAnimationSpeedPercentage(kwh, maxMarketLocationPeakKwh) : 0
                }
                dashColor={powerSupplyTypeToColorMap[type]}
                animationIsInverted={direction === 'receiving'}
                gridBreak={getGridBreak(numberOfPpas, index)}
            />
        </div>
    );
};
