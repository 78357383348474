/**
 * This component is for demo purposes only.
 * Ask Volker if this is still needed. Maybe it can be removed.
 */
import { FC, useState } from 'react';
import { EChart } from './EChart.tsx';
import {
    indiaBerlinMockData,
    setexMockData,
    steinthermeBadBelzigMockData,
    steinthermeBadBelzigAprilMockData,
    whuMockData,
    steinthermeBadBelzigMarchMockData,
    pricesMockData0,
    pricesMockData1,
    pricesMockData2,
    pricesMockData3,
    pricesMockData4,
    pvGenerationMockData,
    setexEnergyCostsMockData,
    longTimeMockData,
    batteryChartCurrentLoad as currentLoad,
    batteryChartBatteryLoad as batteryLoad,
    batteryChartPvLoad as pvLoad,
    batteryChartConsumption as consumption,
    batteryChartCurrentLoadIa as currentLoadIa,
    batteryChartBatteryLoadIa as batteryLoadIa,
} from './mockData.ts';
import { EChartsOption, graphic } from 'echarts';
import { Button, Checkbox } from '@trawa-energy/ui-kit';
import { PageHeading } from './PageHeading.tsx';

type MockData = { date: string; value: number }[];

const BatteryChart: FC = () => {
    const [currentLoadEqualsConsumption, setGridLoadEqualsConsumption] = useState(false);
    const [hideNegativeValues, setHideNegativeValues] = useState(false);
    const [hideBatteryValues, setHideBatteryValues] = useState(false);
    const [barWidth, setBarWidth] = useState('105%');
    const [title, setTitle] = useState('04. Mai 2024 | What a battery can do for you');
    const [consumptionColor, setConsumptionColor] = useState('#737373');
    const [currentLoadColor, setGridLoadColor] = useState('#066EF9');
    const [batteryLoadChargeColor, setBatteryLoadChargeColor] = useState('#ff0000');
    const [batteryLoadDischargeColor, setBatteryLoadDischargeColor] = useState('#29ED9B');
    const [pvLoadColor, setPvLoadColor] = useState('#FFCA63');
    const [consumptionLabel, setConsumptionLabel] = useState('Consumption');
    const [currentLoadLabel, setGridLoadLabel] = useState('Grid load');
    const [batteryLoadLabel, setBatteryLoadLabel] = useState('Battery load');
    const [pvLoadLabel, setPvLoadLabel] = useState('PV load');
    const [batteryAndPvLoadLabel, setBatteryAndPvLoadLabel] = useState('Battery load and PV load');
    const [showArrows, setShowArrows] = useState(true);
    const [arrowDashArrayX0, setArrowDashArrayX0] = useState(8);
    const [arrowDashArrayX1, setArrowDashArrayX1] = useState(8);
    const [arrowDashArrayY0, setArrowDashArrayY0] = useState(8);
    const [arrowDashArrayY1, setArrowDashArrayY1] = useState(8);
    const [iaDataAreActive, setIaDataAreActive] = useState(false);

    const decal = {
        symbol: 'rect',
        symbolSize: 1,
        color: pvLoadColor,
        backgroundColor: batteryLoadChargeColor,
        rotation: 0.5,
        dashArrayX: [5, 5],
        dashArrayY: [1, 0],
    };

    const batteryLoadData = iaDataAreActive ? batteryLoadIa : batteryLoad;
    const currentLoadData = iaDataAreActive ? currentLoadIa : currentLoad;

    return (
        <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
            <div className="flex gap-3 items-center mb-6">
                <Button
                    variant={currentLoadEqualsConsumption ? 'default' : 'outline'}
                    onClick={() => setGridLoadEqualsConsumption(!currentLoadEqualsConsumption)}
                >
                    Grid Load = Consumption
                </Button>
                <Button
                    variant={hideNegativeValues ? 'default' : 'outline'}
                    onClick={() => setHideNegativeValues(!hideNegativeValues)}
                >
                    Hide Negative Values
                </Button>
                <Button
                    variant={hideBatteryValues ? 'default' : 'outline'}
                    onClick={() => setHideBatteryValues(!hideBatteryValues)}
                >
                    Hide Battery Values
                </Button>
                <Button
                    variant={iaDataAreActive ? 'default' : 'outline'}
                    onClick={() => setIaDataAreActive(!iaDataAreActive)}
                >
                    IA Data
                </Button>
                <input
                    type="text"
                    value={title}
                    onChange={event => setTitle(event.target.value)}
                    placeholder="Title"
                    className="border border-muted-foreground rounded-md p-2"
                    title="Title"
                />
                <input
                    type="text"
                    value={barWidth}
                    onChange={event => setBarWidth(event.target.value)}
                    placeholder="Bar Width"
                    className="border border-muted-foreground rounded-md p-2"
                />
            </div>
            <div className="flex gap-3 items-center mb-6">
                <Checkbox label={'Show Arrows'} checked={showArrows} onChange={() => setShowArrows(!showArrows)} />
                DashArray X0
                <input
                    type="number"
                    value={arrowDashArrayX0}
                    onChange={event => setArrowDashArrayX0(Number(event.target.value))}
                    placeholder="Arrow Dash Array X0"
                    className="border border-muted-foreground rounded-md p-2 w-20"
                />
                DashArray X1
                <input
                    type="number"
                    value={arrowDashArrayX1}
                    onChange={event => setArrowDashArrayX1(Number(event.target.value))}
                    placeholder="Arrow Dash Array X1"
                    className="border border-muted-foreground rounded-md p-2 w-20"
                />
                DashArray Y0
                <input
                    type="number"
                    value={arrowDashArrayY0}
                    onChange={event => setArrowDashArrayY0(Number(event.target.value))}
                    placeholder="Arrow Dash Array Y0"
                    className="border border-muted-foreground rounded-md p-2 w-20"
                />
                DashArray Y1
                <input
                    type="number"
                    value={arrowDashArrayY1}
                    onChange={event => setArrowDashArrayY1(Number(event.target.value))}
                    placeholder="Arrow Dash Array Y1"
                    className="border border-muted-foreground rounded-md p-2 w-20"
                />
            </div>
            <div className="flex gap-3 items-center mb-12">
                <input
                    type="color"
                    value={consumptionColor}
                    onChange={event => setConsumptionColor(event.target.value)}
                    title="Consumption color"
                />
                <input
                    type="text"
                    value={consumptionLabel}
                    onChange={event => setConsumptionLabel(event.target.value)}
                    placeholder="Consumption Label"
                    className="border border-muted-foreground rounded-md p-2 w-36"
                    title="Consumption Label"
                />
                <input
                    type="color"
                    value={currentLoadColor}
                    onChange={event => setGridLoadColor(event.target.value)}
                    title="Grid Load color"
                />
                <input
                    type="text"
                    value={currentLoadLabel}
                    onChange={event => setGridLoadLabel(event.target.value)}
                    placeholder="Grid Load Label"
                    className="border border-muted-foreground rounded-md p-2 w-36"
                    title="Grid Load Label"
                />
                <input
                    type="color"
                    value={batteryLoadDischargeColor}
                    onChange={event => setBatteryLoadDischargeColor(event.target.value)}
                    title="Battery Load Discharge color"
                />
                <input
                    type="color"
                    value={batteryLoadChargeColor}
                    onChange={event => setBatteryLoadChargeColor(event.target.value)}
                    title="Battery Load Charge color"
                />
                <input
                    type="text"
                    value={batteryLoadLabel}
                    onChange={event => setBatteryLoadLabel(event.target.value)}
                    placeholder="Battery Load Label"
                    className="border border-muted-foreground rounded-md p-2 w-36"
                    title="Battery Load Label"
                />
                <input
                    type="color"
                    value={pvLoadColor}
                    onChange={event => setPvLoadColor(event.target.value)}
                    title="PV Load color"
                />
                <input
                    type="text"
                    value={pvLoadLabel}
                    onChange={event => setPvLoadLabel(event.target.value)}
                    placeholder="PV Load Label"
                    className="border border-muted-foreground rounded-md p-2 w-36"
                    title="PV Load Label"
                />
                <input
                    type="text"
                    value={batteryAndPvLoadLabel}
                    onChange={event => setBatteryAndPvLoadLabel(event.target.value)}
                    placeholder="Battery and PV Load Label"
                    className="border border-muted-foreground rounded-md p-2 w-36"
                    title="Battery and PV Load Label"
                />
            </div>
            <EChart
                style={{ height: '750px' }}
                option={{
                    grid: [
                        {
                            top: 75,
                            left: 60,
                            right: 60,
                            containLabel: true,
                        },
                    ],
                    title: [
                        {
                            text: title,
                            left: 'left',
                        },
                    ],
                    legend: [
                        {
                            data: [
                                consumptionLabel,
                                currentLoadLabel,
                                batteryLoadLabel,
                                pvLoadLabel,
                                {
                                    name: batteryAndPvLoadLabel,
                                    itemStyle: {
                                        decal,
                                    },
                                },
                            ],
                            right: 0,
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: currentLoad.map(({ timestamp }) => timestamp),
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: 'kWh',
                            nameTextStyle: {
                                fontWeight: 'bold',
                            },
                            axisLine: {
                                show: true,
                            },
                        },
                    ],
                    series: [
                        {
                            name: consumptionLabel,
                            data: consumption.map(({ value }) => value),
                            type: 'line',
                            step: 'middle',
                            symbol: 'none',
                            color: consumptionColor,
                            lineStyle: {
                                type: 'dashed',
                            },
                        },
                        {
                            name: currentLoadLabel,
                            data: currentLoadEqualsConsumption
                                ? consumption.map(({ value }) => value)
                                : currentLoadData.map(({ value }, index) => {
                                      const batteryLoadValue = batteryLoadData[index].value;
                                      return hideBatteryValues ? value - batteryLoadValue : value;
                                  }),
                            type: 'line',
                            step: 'middle',
                            symbol: 'none',
                            color: currentLoadColor,
                        },
                        {
                            name: 'transparent',
                            data: currentLoadData.map(({ value: currentLoadValue }, index) => {
                                const batteryLoadValue = batteryLoadData[index].value;
                                const value =
                                    batteryLoadValue < 0 ? currentLoadValue : currentLoadValue - batteryLoadValue;
                                const showStripes = value < 0;

                                const calcColor = () => {
                                    if (hideNegativeValues) {
                                        return 'transparent';
                                    }

                                    if (showStripes && hideBatteryValues) {
                                        return pvLoadColor;
                                    }

                                    return showStripes ? batteryLoadChargeColor : 'transparent';
                                };

                                return {
                                    value,
                                    itemStyle: {
                                        color: calcColor(),
                                        decal:
                                            showStripes && !hideNegativeValues && !hideBatteryValues
                                                ? decal
                                                : undefined,
                                    },
                                };
                            }),
                            type: 'bar',
                            stack: 'stack1',
                            color: 'transparent',
                        },
                        {
                            name: batteryAndPvLoadLabel,
                            data: batteryLoadData.map(({ value: batteryLoadValue }, index) => {
                                const makeValuePositve = (value: number) => (value < 0 ? -value : value);
                                const consumptionValue = consumption[index].value;
                                const currentLoadValue = currentLoadData[index].value;
                                const pvLoadValue = pvLoad[index].value;
                                const blueButTransparentValue = currentLoadValue - batteryLoadValue;

                                const showStripesBatteryLoad =
                                    consumptionValue > currentLoadValue && batteryLoadValue > 0;
                                const showStripesPvLoad = currentLoadValue > consumptionValue && pvLoadValue > 0;

                                const calcValue = () => {
                                    if (showStripesBatteryLoad) {
                                        return (
                                            batteryLoadValue - makeValuePositve(Math.min(blueButTransparentValue, 0))
                                        );
                                    }

                                    if (showStripesPvLoad) {
                                        return pvLoadValue - makeValuePositve(Math.min(currentLoadValue, 0));
                                    }

                                    return 0;
                                };

                                return {
                                    value: calcValue(),
                                    itemStyle: {
                                        color: hideBatteryValues ? pvLoadColor : batteryLoadChargeColor,
                                        decal: hideBatteryValues ? undefined : decal,
                                    },
                                };
                            }),
                            color: new graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: batteryLoadChargeColor },
                                { offset: 0.5, color: batteryLoadChargeColor },
                                { offset: 0.5, color: pvLoadColor },
                                { offset: 1, color: pvLoadColor },
                            ]),
                            type: 'bar',
                            stack: 'stack1',
                        },
                        {
                            name: batteryLoadLabel,
                            data: batteryLoadData.map(({ value: batteryLoadValue }, index) => {
                                const makeValuePositve = (value: number) => (value < 0 ? -value : value);
                                const consumptionValue = consumption[index].value;
                                const currentLoadValue = currentLoadData[index].value;
                                const pvLoadValue = pvLoad[index].value;

                                const showStripesBatteryLoad =
                                    consumptionValue > currentLoadValue && batteryLoadValue > 0;
                                const showStripesPvLoad = currentLoadValue > consumptionValue && pvLoadValue > 0;

                                const calcValue = () => {
                                    if (showStripesBatteryLoad) {
                                        return 0;
                                    }

                                    if (showStripesPvLoad) {
                                        return makeValuePositve(batteryLoadValue) - pvLoadValue;
                                    }

                                    return makeValuePositve(batteryLoadValue);
                                };

                                const calcColor = () => {
                                    if (hideBatteryValues) {
                                        return 'transparent';
                                    }

                                    return batteryLoadValue < 0 ? batteryLoadDischargeColor : batteryLoadChargeColor;
                                };

                                return {
                                    value: calcValue(),
                                    itemStyle: {
                                        color: calcColor(),
                                        decal: showArrows
                                            ? {
                                                  color: 'white',
                                                  symbol: 'arrow',
                                                  dashArrayX: [arrowDashArrayX0, arrowDashArrayX1],
                                                  dashArrayY: [arrowDashArrayY0, arrowDashArrayY1],
                                                  symbolSize: 1,
                                                  rotation: batteryLoadValue < 0 ? Math.PI : 0,
                                              }
                                            : undefined,
                                    },
                                };
                            }),
                            color: new graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: batteryLoadDischargeColor },
                                { offset: 0.5, color: batteryLoadDischargeColor },
                                { offset: 0.5, color: batteryLoadChargeColor },
                                { offset: 1, color: batteryLoadChargeColor },
                            ]),
                            type: 'bar',
                            stack: 'stack1',
                        },
                        {
                            name: pvLoadLabel,
                            data: pvLoad.map(({ value: pvLoadValue }, index) => {
                                const consumptionValue = consumption[index].value;
                                const currentLoadValue = currentLoadData[index].value;
                                const batteryLoadValue = batteryLoadData[index].value;

                                const showStripesBatteryLoad =
                                    consumptionValue > currentLoadValue && batteryLoadValue > 0;
                                const showStripesPvLoad = currentLoadValue > consumptionValue && pvLoadValue > 0;

                                const calcValue = () => {
                                    if (showStripesPvLoad) {
                                        return 0;
                                    }

                                    if (showStripesBatteryLoad) {
                                        return pvLoadValue - batteryLoadValue;
                                    }

                                    return pvLoadValue;
                                };

                                return {
                                    value: calcValue(),
                                    itemStyle: {
                                        color: pvLoadColor,
                                    },
                                };
                            }),
                            type: 'bar',
                            stack: 'stack1',
                            color: pvLoadColor,
                            barWidth: barWidth === '' ? undefined : barWidth,
                        },
                    ],
                }}
            />
        </div>
    );
};

export const AnalysisDemoCharts: FC = () => {
    const [activePriceMockData, setActivePriceMockData] = useState(pricesMockData0);

    const paddingX = 60;
    const chartHeight = 250;
    const paddingTop = 75;
    const titleHight = 14;

    const getEChartOption = (kwhData: MockData, pvGenerationData: MockData, peakVal: number, maxYVal: number) => {
        return {
            grid: [
                {
                    top: paddingTop,
                    left: paddingX,
                    right: paddingX,
                    height: chartHeight,
                },
                {
                    top: paddingTop * 2 + chartHeight + titleHight * 2 + 30,
                    left: paddingX,
                    right: paddingX,
                    height: chartHeight,
                },
            ],
            title: [
                {
                    text: 'Verbrauch und Erzeugung am Dienstag, 14. Mai 2024',
                    left: 'left',
                },
                {
                    text: 'Strompreise und Gesamtkosten am Dienstag, 14. Mai 2024',
                    left: 'left',
                    top: paddingTop + titleHight + chartHeight + 40,
                },
            ],
            legend: {
                data: ['Gesamtverbrauch', 'PV Erzeugung', 'Strompreis', 'Gesamtkosten'],
                right: 0,
            },
            visualMap: [
                {
                    show: false,
                    type: 'continuous',
                    seriesIndex: 1,
                    min: 0,
                    max: 200,
                    inRange: {
                        color: ['#27ae60', '#f1c40f', '#e74c3c'],
                    },
                },
            ],
            xAxis: [
                {
                    type: 'category',
                    data: kwhData.map(({ date }) => date),
                    axisTick: {
                        alignWithLabel: true,
                    },
                },
                {
                    gridIndex: 1,
                    type: 'category',
                    data: activePriceMockData.map(({ date }) => date),
                    axisTick: {
                        alignWithLabel: true,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'kWh',
                    max: maxYVal,
                    nameTextStyle: {
                        fontWeight: 'bold',
                    },
                },
                {
                    type: 'value',
                    name: 'ct/kWh',
                    position: 'right',
                    nameTextStyle: {
                        fontWeight: 'bold',
                    },
                    gridIndex: 1,
                },
                {
                    type: 'value',
                    name: 'EUR',
                    position: 'left',
                    nameTextStyle: {
                        fontWeight: 'bold',
                    },
                    gridIndex: 1,
                },
            ],
            axisPointer: {
                link: [
                    {
                        xAxisIndex: 'all',
                    },
                ],
            },
            tooltip: {
                trigger: 'axis',
            },
            series: [
                {
                    name: 'Gesamtverbrauch',
                    data: kwhData.map(({ value }) => value),
                    type: 'bar',
                    color: '#82b6fc',
                    emphasis: {
                        itemStyle: {
                            color: '#066EF9',
                        },
                    },
                    markLine: {
                        symbol: 'none',
                        lineStyle: {
                            type: 8,
                            width: 1.5,
                            color: '#E84236',
                        },
                        label: {
                            formatter: 'Lastspitze',
                            position: 'insideStartTop',
                            color: '#E84236',
                        },
                        data: [
                            {
                                name: 'Peak',
                                yAxis: peakVal,
                            },
                        ],
                    },
                    zlevel: 1,
                },
                {
                    name: 'Strompreis',
                    data: activePriceMockData.map(({ value }) => value),
                    type: 'line',
                    showSymbol: false,
                    step: 'middle',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#e74c3c',
                        },
                        {
                            offset: 0.5,
                            color: '#f1c40f',
                        },
                        {
                            offset: 1,
                            color: '#27ae60',
                        },
                    ]),
                    zlevel: 1,
                },
                {
                    name: 'PV Erzeugung',
                    data: pvGenerationData.map(({ value }) => value),
                    symbol: 'none',
                    type: 'line',
                    lineStyle: {
                        width: 0,
                    },
                    color: '#EFB13A',
                    areaStyle: {
                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#EFB13A',
                            },
                            {
                                offset: 1,
                                color: '#DD8B0F',
                            },
                        ]),
                    },
                    zlevel: 2,
                },
                {
                    name: 'Gesamtkosten',
                    data: setexEnergyCostsMockData.map(({ value }) => value),
                    type: 'line',
                    areaStyle: {
                        color: '#055E45',
                    },
                    step: 'middle',
                    symbol: 'none',
                    color: '#055E45',
                    xAxisIndex: 1,
                    yAxisIndex: 2,
                },
            ],
        } satisfies EChartsOption;
    };

    return (
        <section>
            <div id="consumption-page-header" className="consumption-page-header">
                <PageHeading title={'analysis.title'} />
            </div>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 mb-6">
                {[pricesMockData0, pricesMockData1, pricesMockData2, pricesMockData3, pricesMockData4].map(
                    (data, index) => {
                        return (
                            <Button
                                key={index}
                                className="mr-3"
                                variant={activePriceMockData === data ? 'default' : 'outline'}
                                onClick={() => {
                                    setActivePriceMockData(data);
                                }}
                            >
                                Price Data {index}
                            </Button>
                        );
                    },
                )}
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Battery Stuff</h2>
            <BatteryChart />
            <h2 className="font-bold mb-4 text-xl md:text-2xl">By Origin</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={{
                        grid: [
                            {
                                top: paddingTop,
                                left: paddingX,
                                right: paddingX,
                                height: chartHeight,
                            },
                            {
                                top: paddingTop * 2 + chartHeight + titleHight * 2 + 30,
                                left: paddingX,
                                right: paddingX,
                                height: chartHeight,
                            },
                        ],
                        title: [
                            {
                                text: '14. Mai 2024 | Consumption by origin',
                                left: 'left',
                            },
                            {
                                text: '14. Mai 2024 | Coast by origin',
                                left: 'left',
                                top: paddingTop + titleHight + chartHeight + 40,
                            },
                        ],
                        legend: [
                            {
                                data: [
                                    'Consumption Tranche',
                                    'Consumption Wind PPA',
                                    'Consumption PV',
                                    'Consumption Spot',
                                ],
                                right: 0,
                            },
                            {
                                data: ['Costs Tranche', 'Costs Wind PPA', 'Costs PV', 'Costs Spot'],
                                top: paddingTop + titleHight + chartHeight + 40,
                                right: 0,
                            },
                        ],
                        tooltip: {
                            trigger: 'axis',
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: setexMockData.map(({ date }) => date).filter((x, i) => i % 4 === 0),
                                axisTick: {
                                    alignWithLabel: true,
                                },
                            },
                            {
                                type: 'category',
                                data: setexMockData.map(({ date }) => date).filter((x, i) => i % 4 === 0),
                                axisTick: {
                                    alignWithLabel: true,
                                },
                                gridIndex: 1,
                            },
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: 'kWh',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                                axisLine: {
                                    show: true,
                                },
                            },
                            {
                                type: 'value',
                                name: 'EUR',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                                axisLine: {
                                    show: true,
                                },
                                gridIndex: 1,
                            },
                        ],
                        axisPointer: {
                            link: [
                                {
                                    xAxisIndex: 'all',
                                },
                            ],
                        },
                        series: [
                            {
                                name: 'Consumption Tranche',
                                data: setexMockData.map(({ value }) => 100).filter((x, i) => i % 4 === 0),
                                type: 'bar',
                                stack: 'stack1',
                                color: '#02523B',
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                            {
                                name: 'Consumption PV',
                                data: pvGenerationMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(0))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'bar',
                                stack: 'stack1',
                                color: '#DD8B0F',
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                            {
                                name: 'Consumption Wind PPA',
                                data: setexMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(0))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'bar',
                                stack: 'stack1',
                                color: '#78C3D1',
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },

                            {
                                name: 'Consumption Spot',
                                data: setexMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(0))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'bar',
                                stack: 'stack1',
                                color: '#878787',
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },

                            {
                                name: 'Costs Tranche',
                                data: setexMockData.map(({ value }) => 50).filter((x, i) => i % 4 === 0),
                                type: 'line',
                                lineStyle: {
                                    width: 0,
                                },
                                areaStyle: {
                                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: '#02523B',
                                        },
                                        {
                                            offset: 1,
                                            color: '#02523B',
                                        },
                                    ]),
                                },
                                symbol: 'none',
                                stack: 'stack2',
                                color: '#02523B',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                            {
                                name: 'Costs Wind PPA',
                                data: setexMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(2))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'line',
                                lineStyle: {
                                    width: 0,
                                },
                                areaStyle: {
                                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: '#78C3D1',
                                        },
                                        {
                                            offset: 1,
                                            color: '#78C3D1',
                                        },
                                    ]),
                                },
                                symbol: 'none',
                                stack: 'stack2',
                                color: '#78C3D1',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                            {
                                name: 'Costs PV',
                                data: pvGenerationMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(2))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'line',
                                lineStyle: {
                                    width: 0,
                                },
                                areaStyle: {
                                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: '#DD8B0F',
                                        },
                                        {
                                            offset: 1,
                                            color: '#DD8B0F',
                                        },
                                    ]),
                                },
                                symbol: 'none',
                                stack: 'stack2',
                                color: '#DD8B0F',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                            {
                                name: 'Costs Spot',
                                data: setexMockData
                                    .map(({ value }) => (value * Math.random()).toFixed(2))
                                    .filter((x, i) => i % 4 === 0),
                                type: 'line',
                                lineStyle: {
                                    width: 0,
                                },
                                areaStyle: {
                                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: '#878787',
                                        },
                                        {
                                            offset: 1,
                                            color: '#878787',
                                        },
                                    ]),
                                },
                                symbol: 'none',
                                stack: 'stack2',
                                color: '#878787',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                itemStyle: {
                                    opacity: 0.5,
                                },
                                emphasis: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                            },
                        ],
                    }}
                    style={{ height: '750px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Long time data</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={{
                        title: {
                            text: 'Long time data',
                            left: 'left',
                        },
                        grid: [{ left: 60, right: 60 }],
                        tooltip: {
                            trigger: 'axis',
                        },
                        xAxis: {
                            type: 'category',
                            data: longTimeMockData.map(({ date }) => date),
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: 'kWh',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#82b6fc',
                                    },
                                },
                            },
                            {
                                type: 'value',
                                name: 'kWh',
                                position: 'right',
                                alignTicks: true,
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#EF913A',
                                    },
                                },
                            },
                        ],
                        series: [
                            {
                                name: 'kWh',
                                data: longTimeMockData.map(({ value }) => value),
                                type: 'line',
                                symbol: 'none',
                                step: 'middle',
                                color: '#82b6fc',
                            },
                            {
                                name: 'kWh',
                                data: longTimeMockData.map(({ value }) => value / (100 * Math.random())),
                                type: 'line',
                                symbol: 'none',
                                step: 'middle',
                                color: '#EF913A',
                                yAxisIndex: 1,
                            },
                        ],
                        dataZoom: [
                            {
                                type: 'slider',
                                start: 0,
                                end: 25,
                            },
                        ],
                    }}
                    style={{ height: '600px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">India Berlin</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart option={getEChartOption(indiaBerlinMockData, [], 100, 120)} style={{ height: '750px' }} />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Setex</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={getEChartOption(setexMockData, pvGenerationMockData, 300, 400)}
                    style={{ height: '750px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Steintherme Bad Belzig</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={getEChartOption(steinthermeBadBelzigMockData, [], 40, 50)}
                    style={{ height: '750px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Steintherme Bad Belzig März</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={getEChartOption(steinthermeBadBelzigMarchMockData, [], 2200, 2500)}
                    style={{ height: '750px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">Steintherme Bad Belzig April</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={getEChartOption(steinthermeBadBelzigAprilMockData, [], 2200, 2500)}
                    style={{ height: '750px' }}
                />
            </div>
            <h2 className="font-bold mb-4 text-xl md:text-2xl">WHU</h2>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={getEChartOption(whuMockData, pvGenerationMockData, 23, 30)}
                    style={{ height: '750px' }}
                />
            </div>
            <div className="border border-muted-foreground rounded-xl bg-card p-3 md:p-8 min-h-[410px] mb-6">
                <EChart
                    option={{
                        title: {
                            text: 'Steintherme Bad Belzig',
                            left: 'left',
                        },
                        legend: {
                            data: ['kWh März', 'kWh April'],
                            right: 0,
                        },
                        grid: [
                            { width: '45%', left: 60 },
                            { width: '45%', right: 60 },
                        ],
                        tooltip: {
                            trigger: 'axis',
                        },
                        axisPointer: {
                            link: [
                                {
                                    xAxisIndex: 'all',
                                },
                            ],
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: steinthermeBadBelzigMarchMockData.map(({ date }) => date),
                            },
                            {
                                type: 'category',
                                data: steinthermeBadBelzigMarchMockData.map(({ date }) => date),
                                gridIndex: 1,
                            },
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                            },
                            {
                                type: 'value',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                },
                                gridIndex: 1,
                            },
                        ],
                        series: [
                            {
                                name: 'kWh März',
                                data: steinthermeBadBelzigMarchMockData.map(({ value }) => value),
                                type: 'bar',
                                color: '#066EF9',
                            },
                            {
                                name: 'kWh April',
                                data: steinthermeBadBelzigAprilMockData.map(({ value }) => value),
                                type: 'bar',
                                color: '#30ec9c',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                            },
                        ],
                    }}
                />
            </div>
        </section>
    );
};
