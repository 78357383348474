import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { Card } from '../components/Card.tsx';
import { Button, DownloadIcon, DropdownField } from '@trawa-energy/ui-kit';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { Temporal } from 'temporal-polyfill';
import { AccountType, useUserSettings } from '../userSettings.ts';
import { PageHeading } from '../components/PageHeading.tsx';
import { useMemo, useState } from 'react';
import { useGetMarketLocations } from '../hooks/useMarketLocations.ts';
import { CustomPeriodPopover } from '../components/CustomPeriodPopover';
import { FilterValues } from '../utils/useFilterValues.tsx';
import { getSelectedDatesByFilterValues } from '../utils/getSelectedDatesByFilterValues.ts';

export type DocumentMetadata = NonNullable<inferRouterOutputs<AppRouter>['documents']['getMany']>[number];

const tableRowClassName =
    'transition odd:bg-primary-1 *:px-4 *:lg:px-6 *:2xl:px-10 *:py-4 *:border-primary-2 *:border-t [&:last-child>*]:border-b [&:first-child>*]:border-t-gray-3 [&:last-child>*]:border-b-gray-3 first:*:border-l-gray-3 last:*:border-r-gray-3 first:*:border-l last:*:border-r [&:first-child>:first-child]:rounded-tl-md [&:first-child>:last-child]:rounded-tr-md [&:last-child>:first-child]:rounded-bl-md [&:last-child>:last-child]:rounded-br-md';

export function Documents() {
    const { userSettings } = useUserSettings();
    const contractPartnerId =
        userSettings.accountType === AccountType.RealEstate ? userSettings.contractPartnerId : undefined;

    const intl = useIntl();

    const { mutateAsync: archiveFiles, isPending: isArchiving } = trpc.documents.archiveFiles.useMutation({});

    const { client } = trpc.useUtils();

    const { data: marketLocations } = useGetMarketLocations();

    const documentTypeOptions = ['all', 'monthlyInvoice', 'downPaymentInvoice'];
    const [selectedMarketLocationId, setSelectedMarketLocationId] = useState('');
    const [selectedDocType, setSelectedDocType] = useState('');

    const today = Temporal.Now.plainDateISO();

    const initialFilterValues = {
        period: 'custom',
        from: Temporal.PlainDate.from({ year: today.year, month: 1, day: 1 }),
        to: today,
    } as FilterValues; // TODO: this is a hack. CustomPeriodPopover needs to be refactored to not need FilterValues

    const [filterValues, setFilterValues] = useState(initialFilterValues);
    const selectedDates = useMemo(() => getSelectedDatesByFilterValues(filterValues), [filterValues]);

    const documentFilterValues = useMemo(
        () => ({
            contractPartnerId,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            documentType: (selectedDocType as any) || undefined, // TODO: I hacked with `any` here because strings are not assignable to enums
            dateRange: { from: selectedDates.from.toString(), exclusiveTo: selectedDates.exclusiveTo.toString() },
            marketLocationId: selectedMarketLocationId,
        }),
        [contractPartnerId, selectedDocType, selectedDates, selectedMarketLocationId],
    );
    const { data: documents, isLoading } = trpc.documents.getMany.useQuery(documentFilterValues);
    const { data: contractPartners } = trpc.dashboard.getContractPartners.useQuery({}, {});

    const resetFilters = () => {
        setSelectedDocType('');
        setSelectedMarketLocationId('');
        setFilterValues(initialFilterValues);
    };

    const downloadDocument = async (doc: DocumentMetadata) => {
        const url = await client.documents.getFileUrl.query({ documentId: doc.id });
        if (!url) {
            console.error(
                `No document found by id '${doc.id}'. Probably document metadata is dirty and needs debugging!`,
                doc,
            );
            alert('No document found!');
            return;
        }

        downloadFile(url);
    };

    const downloadAll = async () => {
        const archiveUrl = await archiveFiles(documentFilterValues);
        downloadFile(archiveUrl);
    };

    return (
        <section className="min-w-[600px]">
            <PageHeading title={'documents.title'} />
            <div className="flex justify-between">
                <div className="flex gap-8">
                    <div className="max-w-sm">
                        <DropdownField
                            label={intl.formatMessage({ id: 'documents.filters.documentTypeLabel' })}
                            lean
                            options={documentTypeOptions.map(option => ({
                                text: intl.formatMessage({ id: 'documents.' + option }),
                                value: option === 'all' ? '' : option,
                            }))}
                            onChange={option => {
                                if (option?.value === undefined) {
                                    return;
                                }
                                setSelectedDocType(option.value);
                            }}
                            value={selectedDocType}
                        />
                    </div>
                    <div className="max-w-sm">
                        {marketLocations && (
                            <DropdownField
                                label={intl.formatMessage({ id: 'documents.filters.locationsLabel' })}
                                lean
                                options={[
                                    {
                                        text: intl.formatMessage({ id: 'documents.all' }),
                                        value: '',
                                    },
                                    ...marketLocations.map(location => ({
                                        text: location.label ?? '',
                                        value: location.id,
                                    })),
                                ]}
                                onChange={location => setSelectedMarketLocationId(location?.value ?? '')}
                                value={selectedMarketLocationId}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <div className="text-sm 2xl:text-base font-bold">
                        {intl.formatMessage({ id: 'filterControls.periods.label' })}
                    </div>
                    <CustomPeriodPopover
                        filterValues={filterValues}
                        selectedDates={selectedDates}
                        setFilterValues={setFilterValues}
                        showInputs
                    />
                </div>
            </div>
            <Card>
                {!!documents?.length && (
                    <Button
                        className="text-sm 2xl:text-base font-bold mb-4 ml-auto flex"
                        onClick={downloadAll}
                        disabled={!documents?.length || isArchiving}
                    >
                        <div className="flex items-center gap-2">
                            <DownloadIcon />
                            <span>{intl.formatMessage({ id: 'documents.downloadAll' })}</span>
                        </div>
                    </Button>
                )}
                <div>
                    {documents?.length ? (
                        <table className="text-sm lg:text-base w-full border-separate border-spacing-0">
                            <tbody>
                                {contractPartners &&
                                    documents?.map((entry, i) => (
                                        <tr key={entry.id} className={tableRowClassName}>
                                            <td className="text-start font-bold">
                                                <DocumentName entry={entry} contractPartners={contractPartners} />
                                            </td>
                                            <td className="text-center">
                                                {intl.formatDate(entry.issueDate, {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                })}
                                            </td>
                                            <td className="text-end">
                                                <Button size="sm" onClick={() => downloadDocument(entry)}>
                                                    <DownloadIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="flex items-center justify-between py-4">
                            <div className="font-bold">{intl.formatMessage({ id: 'documents.noResults' })}</div>
                            <Button
                                size="sm"
                                variant="outline"
                                className="font-semibold bg-white"
                                onClick={resetFilters}
                            >
                                {intl.formatMessage({ id: 'documents.filters.resetFilters' })}
                            </Button>
                        </div>
                    )}
                    <LoaderOrNoData isEmpty={!!documents && !documents.length} isLoading={isLoading} />
                </div>
            </Card>
        </section>
    );
}

function LoaderOrNoData({
    isEmpty,
    isLoading,
    skeletonRowLength = 5,
}: {
    isEmpty: boolean;
    isLoading: boolean;
    skeletonRowLength?: number;
}) {
    const intl = useIntl();
    return (
        <div className="w-full">
            {isEmpty && (
                <div className={tableRowClassName}>
                    <div>{intl.formatMessage({ id: 'common.noData' })}</div>
                </div>
            )}
            {isLoading &&
                new Array(skeletonRowLength).fill(null).map((x, i) => (
                    <div key={i} className={tableRowClassName}>
                        <div className="flex w-full h-full items-center justify-center">
                            <div className="flex-grow-0 animate-pulse w-20 h-4 rounded-full bg-gray-2 opacity-100"></div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

function DocumentName({
    entry,
    contractPartners,
}: {
    entry: DocumentMetadata;
    contractPartners: { contractPartnerId: string; contractPartnerName: string }[];
}) {
    const intl = useIntl();

    const formatYearMonth = (yearMonth: string | number) => {
        let tmpYearMonth: string = yearMonth.toString();

        // If numeric, then yearMonth is only a year without month
        if (typeof yearMonth === 'number') {
            tmpYearMonth = `${yearMonth}-01`;
        }

        return Temporal.PlainYearMonth.from(tmpYearMonth).toLocaleString(intl.locale, {
            month: 'long',
            year: 'numeric',
            calendar: 'iso8601',
        });
    };

    switch (entry.type) {
        case 'monthlyInvoice':
        case 'correctionInvoice':
            return (
                <>
                    {intl.formatMessage({ id: `documents.${entry.type}` })} {formatYearMonth(entry.monthYear)}
                    {' | '}
                    {entry.locationName}
                    {' | '}
                    <span>{entry.marketLocationId}</span> (PDF)
                </>
            );
        case 'yearlyInvoice':
            return (
                <>
                    {intl.formatMessage({ id: `documents.${entry.type}` })} {entry.year}
                    {' | '}
                    {entry.locationName}
                    {' | '}
                    <span>{entry.marketLocationId}</span> (PDF)
                </>
            );
        case 'downPaymentInvoice':
            return (
                <>
                    {intl.formatMessage({ id: `documents.${entry.type}` })} {entry.year}
                    {' | '}
                    {contractPartners.find(c => entry.contractPartnerId == c.contractPartnerId)!.contractPartnerName}
                    {' | '}
                    <span>{entry.marketLocationId}</span> (PDF)
                </>
            );
        default:
            console.error('Unknown document type:', entry);
            return null;
    }
}

function downloadFile(url: string) {
    const a = window.document.createElement('a');
    a.href = url;
    // NOTE: a.download only works for same-domain links, but we serve files from Amazon's S3 presigned URLs
    a.target = '_blank';
    a.click();
}
