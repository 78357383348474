import { Cluster, ClusterIconStyle, MarkerExtended } from '@react-google-maps/marker-clusterer';
import { Coordinates, MapLocation } from '../../constants/locations';
import { InfoWindow, MarkerClustererF } from '@react-google-maps/api';
import { Fragment, useState } from 'react';
import { SingleMarker } from './SingleMarker';
import { useIntl } from 'react-intl';

const ClusterWindowText = ({
    clusterType,
    markers,
}: {
    clusterType: 'market' | 'wind' | 'solar';
    markers: MarkerExtended[];
}) => {
    const { formatMessage } = useIntl();
    switch (clusterType) {
        case 'market':
            return (
                <div>
                    {markers.map((marker, index) => (
                        <p key={index}>{marker.getTitle()}</p>
                    ))}
                </div>
            );
        case 'wind':
            return <p>{formatMessage({ id: 'products.table.Onshore Wind PPA' })}</p>;
        case 'solar':
            return <p>{formatMessage({ id: 'products.table.Solar PPA' })}</p>;
        default:
            <></>;
    }
};

export function MarkerClusterer({
    onClusterClick,
    clusterProps,
    locations,
    clusterType,
}: {
    onClusterClick: (cluster: Cluster) => void;
    clusterProps: { clusterClass?: string; styles?: ClusterIconStyle[] };
    locations: MapLocation[];
    clusterType: 'market' | 'wind' | 'solar';
}) {
    const { formatMessage } = useIntl();
    const [showInfo, setShowInfo] = useState(false);
    const [clusterCenter, setClusterCenter] = useState<Coordinates | undefined>(undefined);
    const [markersInCluster, setMarkersInCluster] = useState<MarkerExtended[]>([]);
    // Pixel offset for the info window: height offset based on height 48px on clusterStylesAssetLocations
    const offSet = new google.maps.Size(0, -24);

    return (
        <MarkerClustererF
            onClick={onClusterClick}
            averageCenter={false}
            gridSize={10}
            enableRetinaIcons
            onMouseOver={cluster => {
                const center = cluster.getCenter();
                center && setClusterCenter({ lat: center.lat(), lng: center.lng() });
                setMarkersInCluster(cluster.getMarkers());
                setShowInfo(true);
            }}
            onMouseOut={() => setShowInfo(false)}
            onClusteringBegin={() => setShowInfo(false)}
            {...clusterProps}
        >
            {clusterer => (
                <>
                    {locations?.map(({ coordinates, name, icon }, index) => (
                        <Fragment key={index}>
                            {coordinates?.lat !== 0 && (
                                <SingleMarker
                                    clusterer={clusterer}
                                    key={`${index}-${coordinates?.lat}-${coordinates?.lng}`}
                                    position={coordinates}
                                    name={
                                        clusterType === 'market'
                                            ? name
                                            : formatMessage({ id: `products.table.${name}` })
                                    }
                                    icon={icon ?? ''}
                                />
                            )}
                        </Fragment>
                    ))}
                    {showInfo && (
                        <InfoWindow
                            options={{ pixelOffset: offSet, headerDisabled: true }}
                            position={clusterCenter}
                            onCloseClick={() => setShowInfo(false)}
                        >
                            <h6 className="font-extrabold">
                                <ClusterWindowText clusterType={clusterType} markers={markersInCluster} />
                            </h6>
                        </InfoWindow>
                    )}
                </>
            )}
        </MarkerClustererF>
    );
}
