import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { useMemo, useRef } from 'react';
import { useAuthenticate } from './useAuthenticate';

type GetAccessToken = () => Promise<string | undefined>;

export let getStoredAccessToken: GetAccessToken | undefined;

export function useHybridAuthenticate() {
    const authState = useAuthenticate();
    const passwordlessAuthState = usePasswordless();

    const getAccessTokenFromClassicCognito = authState.getAccessToken;
    const getAccessTokenFromPasswordlessCognito = async () => passwordlessAuthState.tokens?.accessToken ?? undefined;

    const accessTokenGetterRef = useRef<GetAccessToken>(getAccessTokenFromClassicCognito);
    accessTokenGetterRef.current = authState.isAuthenticated
        ? getAccessTokenFromClassicCognito
        : getAccessTokenFromPasswordlessCognito;

    getStoredAccessToken = accessTokenGetterRef.current; // TODO: redesign this ugly solution for analytics plugin to have access to access token through a global var
    const hybridAuthStatus = authState.isAuthenticated ? 'SIGNED_IN' : passwordlessAuthState.signInStatus;
    const isAuthenticated = hybridAuthStatus === 'SIGNED_IN' || hybridAuthStatus === 'REFRESHING_SIGN_IN';

    const hybridAuthState = useMemo(
        () => ({
            status: hybridAuthStatus,
            isAuthenticated,
            signOut: async () => {
                authState.signOut();
                await passwordlessAuthState.signOut().signedOut;
            },
            signIn: authState.signIn, // only for password-based sign-ins
        }),
        [hybridAuthStatus, isAuthenticated, authState, passwordlessAuthState],
    );

    return {
        isAuthenticated,
        authState: hybridAuthState,
        getAccessToken: () => accessTokenGetterRef.current(), // must always be a fresh value from a ref
        tokens: passwordlessAuthState.tokens,
    };
}
