import { useState } from 'react';
import { LanguagesType, TimeZoneType, Locales, TimeZones } from '../locales/utils';

type LocalesContextProps = {
    locale: LanguagesType;
    timeZone: TimeZoneType;
    setActiveLanguage: (lang: LanguagesType) => void;
    setActiveTimeZone: (lang: TimeZoneType) => void;
};

export const useLocaleTimeZone = (): LocalesContextProps => {
    // TODO: Implement the locale state
    // Sync with the BE to have the same locale and timezone
    const [locale, setLocale] = useState<LanguagesType>(Locales.DE);
    const [timeZone, setTimeZone] = useState<TimeZoneType>(TimeZones.EUROPE_BERLIN);

    const setActiveLanguage = (lang: LanguagesType) => {
        setLocale(lang);
    };

    const setActiveTimeZone = (timeZone: TimeZoneType) => {
        setTimeZone(timeZone);
    };

    return {
        locale,
        timeZone,
        setActiveLanguage,
        setActiveTimeZone,
    };
};
