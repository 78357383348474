import { FC } from 'react';
import { Temporal } from 'temporal-polyfill';
import { trpc } from '../utils/trpc';
import { PageHeading } from '../components/PageHeading.tsx';
import { Card } from '../components/Card.tsx';
import { NestedTable } from '../components/NestedTable.tsx';
import { useIntl } from 'react-intl';

export const PvGeneration: FC = () => {
    const intl = useIntl();

    const yesterdayMidnight = Temporal.Now.plainDateISO().toPlainDateTime();
    const todayMidnight = yesterdayMidnight.add({ days: 1 });

    const { data, isError } = trpc.athena.getActualVolumeCurve.useQuery({
        volumeCurveId: '2eff1431-258e-4c83-b5b8-a14257ce8a6e',
        dateRange: {
            from: yesterdayMidnight.toString(),
            to: todayMidnight.toString(),
        },
    });

    if (isError) {
        return <div>An Error occurred</div>;
    }

    if (data === undefined) {
        return undefined;
    }

    const headings = ['Time', 'kwh', 'Ingestion'];
    const rows = data.map(date => {
        return {
            cells: [
                <span>
                    {intl.formatTime(date.timestamp_start, { timeStyle: 'short', dateStyle: 'short' })}
                    {' - '}
                    {intl.formatDate(date.timestamp_end, { timeStyle: 'short' })}
                </span>,
                date.kwh,
                intl.formatDate(date.timestamp_ingestion, { dateStyle: 'medium', timeStyle: 'medium' }),
            ],
        };
    });

    return (
        <section>
            <PageHeading title={'pvGeneration.title'} />
            <Card>
                <NestedTable headings={headings} rows={rows} isLoading={false} />
            </Card>
        </section>
    );
};
