import { TrawaLogoIcon } from '@trawa-energy/ui-kit';
import { TvKPI } from '../../components/tv-consumption-grid/KPI/index.ts';
import { TvConsumptionGrid } from '../../components/tv-consumption-grid/TvConsumptionGrid.tsx';
import { useWindowSize } from '../../hooks/useWindowSize.ts';
import './styles.css';
import { DisplayGrid, useLastDayWithData } from '../../hooks/useGridData.ts';
import { useLayoutEffect } from 'react';

export const TvScreen = () => {
    // All the TV styles are applied to the dark theme
    useLayoutEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);

    const { data: latestDate, isLoading } = useLastDayWithData({ display: DisplayGrid.TV });

    useWindowSize();

    if (isLoading || !latestDate) {
        return null;
    }

    return (
        <div className="h-screen bg-gradient-to-br from-primary-green-5 from-1% via-black to-black">
            <div className="flex flex-col items-stretch h-full p-11 lg:p-14">
                <TvKPI lastDate={latestDate} />
                <TvConsumptionGrid latestDayWithData={latestDate} />
                <div>
                    <footer className="pb-2 flex justify-end">
                        <TrawaLogoIcon className="w-20 h-5 lg:w-28 lg:h-7" />
                    </footer>
                </div>
            </div>
        </div>
    );
};
