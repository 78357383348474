import { IntlShape } from 'react-intl';
import { Interval } from '../useFilterValues';

export function formatTooltipDateTimeByInterval(intl: IntlShape, dateTime: Date, interval: Interval, timeZone: string) {
    switch (interval) {
        case '15min':
            return intl.formatDate(dateTime, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone,
            });
        case 'hour':
            return intl.formatDate(dateTime, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone,
            });
        case 'day':
        case 'week':
            return intl.formatDate(dateTime, { year: 'numeric', month: 'short', day: 'numeric', timeZone });
        case 'month':
            return intl.formatDate(dateTime, { year: 'numeric', month: 'short', timeZone });
        default:
            throw new Error(`Unexpected interval: ${interval}`);
    }
}
