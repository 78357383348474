import ReactDOM from 'react-dom/client';
import './index.css';
import { initSentry } from './utils/setupSentry.ts';
import { PasswordlessContextProvider } from 'amazon-cognito-passwordless-auth/react';
import { App } from './App.tsx';

initSentry();

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement!).render(
    <PasswordlessContextProvider enableLocalUserCache={true}>
        <App />
    </PasswordlessContextProvider>,
);
