import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import { config } from '../../config';
import { MapOption, getMapOptions } from '../../utils/getMapOptionStyles';
import { LoadingSpinner } from '@trawa-energy/ui-kit';
import { useIntl } from 'react-intl';

const mapOptions = getMapOptions(MapOption.default);

export function MapWithSingleMarker({ coordinate }: { coordinate: { lat: number; lng: number } }) {
    const { formatMessage } = useIntl();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapsApiKey,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <LoadingSpinner />
                {formatMessage({ id: 'locations.loadingMaps' })}
            </div>
        );
    }

    return (
        <GoogleMap
            mapContainerStyle={mapOptions.mapContainerStyles}
            zoom={18}
            center={coordinate}
            options={mapOptions.options}
        >
            <MarkerF position={coordinate} />
        </GoogleMap>
    );
}
