import React from 'react';
import { cn, DayIcon, LoadingIcon, NightIcon } from '@trawa-energy/ui-kit';

export const TimelineIcon = ({ isActive = false, type }: { isActive?: boolean; type: 'day' | 'night' }) => {
    return (
        <div className={`relative inline-block`}>
            <div
                className={cn(
                    `w-10 lg:w-[52px] h-10 lg:h-[52px] `,
                    'absolute -translate-y-2/4 -translate-x-2/4 top-2/4 left-2/4 ',
                    isActive ? 'primary-green-3' : 'invisible',
                )}
            >
                <LoadingIcon className="w-10 h-10 lg:w-[52px] lg:h-[52px] animate-[spin_3s_linear_infinite] text-primary-green-3" />
            </div>
            <div
                className={cn(
                    'w-[22px] h-[22px] lg:w-[30px] lg:h-[30px] rounded-full flex justify-center items-center',
                    isActive ? 'bg-primary-green-3' : 'bg-transparent',
                )}
            >
                {type === 'night' ? (
                    <NightIcon className={cn('w-4 h-4 lg:h-5 lg:w-5', isActive ? 'text-black' : 'text-gray-4')} />
                ) : (
                    <DayIcon className={cn('w-4 h-4 lg:h-5 lg:w-5', isActive ? 'text-black' : 'text-gray-4')} />
                )}
            </div>
        </div>
    );
};
