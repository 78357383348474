import { Passwordless } from 'amazon-cognito-passwordless-auth';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { config } from '../../../config.ts';
import { MagicLinkCreation } from './creation/MagicLinkCreation.tsx';
import { MagicLinkHandler } from './MagicLinkHandler.tsx';
import { Heading } from './Heading.tsx';
import { LoginContainer } from '../LoginContainer.tsx';

export type SigningInStatus = ReturnType<typeof usePasswordless>['signingInStatus'];

Passwordless.configure(config.passwordless);

/**
 * There are two stages in magic link login:
 * 1. Requesting a magic link
 * 2. Handling the magic link
 */
export const MagicLinkLogin = () => {
    const { requestSignInLink, lastError, signInStatus, signingInStatus } = usePasswordless();
    const intl = useIntl();
    const isAuthenticated = signInStatus === 'SIGNED_IN';

    useNavigateWhenAuthenticated(isAuthenticated);

    if (isAuthenticated || signingInStatus === 'CHECKING_FOR_SIGNIN_LINK') {
        // we are navigating away, so we don't need to render anything
        return null;
    }

    // 1. Requesting a magic link
    if (MagicLinkCreation.isValidStatus(signingInStatus)) {
        return (
            <LoginContainer>
                <MagicLinkCreation
                    lastError={lastError}
                    signingInStatus={signingInStatus}
                    onSubmit={email => {
                        requestSignInLink({
                            username: email,
                        });
                    }}
                />
            </LoginContainer>
        );
    }

    // 2. Handling the magic link
    if (MagicLinkHandler.isValidStatus(signingInStatus)) {
        return (
            <LoginContainer>
                <Heading>{intl.formatMessage({ id: 'login.heading' })}</Heading>
                <MagicLinkHandler signingInStatus={signingInStatus} />
            </LoginContainer>
        );
    }

    // There should be no way out of the flow to get here. If it happens, we need to investigate.
    console.error(`Unexpected signingInStatus '${signingInStatus}' in MagicLinkLogin`);
    return null;
};

function useNavigateWhenAuthenticated(isAuthenticated: boolean) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (isAuthenticated) {
            const returnUrl = searchParams.get('returnUrl') ?? '/';
            navigate(returnUrl);
        }
    }, [navigate, searchParams, isAuthenticated]);
}
