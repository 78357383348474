import React, { useMemo } from 'react';
import { Temporal } from 'temporal-polyfill';
import { BoltIcon } from '@trawa-energy/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { trpc } from '../../../utils/trpc';
import { KPIKeys, KPIType } from './utils';
import { KPIBox } from './KPIBox';

type TvKPIProps = {
    lastDate: Temporal.PlainDate;
};

export const TvKPI = ({ lastDate }: TvKPIProps) => {
    const intl = useIntl();
    const currentDate = Temporal.Now.plainDateISO();
    const yesterdayDate = currentDate.subtract({ days: 1 });
    const isToday = lastDate.equals(currentDate);
    const isYesterday = lastDate.equals(yesterdayDate);
    const { data, isLoading } = trpc.tvConsumption.getKPIData.useQuery({
        date: lastDate.toString(),
    });

    const getDateTitle =
        isToday || isYesterday
            ? intl.formatMessage(
                  { id: 'tv.kpi.verboseDate' },
                  {
                      day: intl.formatMessage({
                          id: isToday ? 'filterControls.periods.today' : 'filterControls.periods.yesterday',
                      }),
                      date: intl.formatDate(lastDate.toString()),
                  },
              )
            : intl.formatMessage(
                  { id: 'tv.kpi.date' },
                  {
                      date: intl.formatDate(lastDate.toString()),
                  },
              );

    const getYearTitle = intl.formatMessage(
        { id: 'tv.kpi.totalValue' },
        { year: intl.formatDate(lastDate.toString(), { year: 'numeric' }) },
    );

    const kpis = useMemo(() => {
        const kpiArray: { title: string; type: KPIType; data: { title: string; data: number }[] }[] = [];

        if (!data) return [];
        if (data.consumption.day && data?.consumption.year) {
            kpiArray.push({
                title: intl.formatMessage({ id: 'tv.kpi.consumption' }),
                type: KPIKeys.Consumption,
                data: [
                    {
                        title: getDateTitle,
                        data: data.consumption.day,
                    },
                    {
                        title: getYearTitle,
                        data: data.consumption.year,
                    },
                ],
            });
        }
        if (data.solar.day && data.solar.year) {
            kpiArray.push({
                title: intl.formatMessage({ id: 'tv.kpi.solar' }),
                type: KPIKeys.Solar,
                data: [
                    {
                        title: getDateTitle,
                        data: data.solar.day,
                    },
                    {
                        title: getYearTitle,
                        data: data.solar.year,
                    },
                ],
            });
        }
        if (data.emissions.day && data.emissions.year) {
            kpiArray.push({
                title: intl.formatMessage({ id: 'tv.kpi.emission' }),
                type: KPIKeys.Emission,
                data: [
                    {
                        title: getDateTitle,
                        data: data.emissions.day,
                    },
                    {
                        title: getYearTitle,
                        data: data.emissions.year,
                    },
                ],
            });
        }

        return kpiArray;
    }, [data, getDateTitle, getYearTitle, intl]);

    return (
        <div className="gap-8 lg:gap-10">
            <div className="dark:bg-primary-green-6 border border-gray-5 rounded-2xl p-8 lg:p-10 flex flex-col gap-8 lg:gap-10">
                <div className="flex justify-between">
                    <div className="flex text-[64px] lg:text-[80px] flex-auto gap-8 lg:gap-10">
                        <div>
                            <BoltIcon className="w-28 lg:w-36 h-28 lg:h-36 text-primary-green-3 border-4 border-primary-green-5 rounded-full p-4 drop-shadow-[0_0_12.5px_rgba(37,233,163,0.25)]" />
                        </div>
                        <div className="w-full">
                            <p className="font-bold leading-[1.1]">
                                <FormattedMessage
                                    id="tv.title"
                                    values={{
                                        company: 'SETEX',
                                        span: chunk => <span className="text-primary-green-3">{chunk}</span>,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
                {!isLoading && (
                    <div className="flex justify-between gap-2.5">
                        {kpis.map((entry, index) => (
                            <KPIBox key={index} title={entry?.title} type={entry?.type} data={entry?.data} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
