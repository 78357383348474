import backgroundUrl from './bg-o.webp';
import { TrawaLogoIcon } from '@trawa-energy/ui-kit';

export function BrandBackground() {
    return (
        <div className="relative hidden md:flex bg-black justify-start 2xl:justify-end items-start h-screen w-full">
            <div
                style={{
                    backgroundImage: `url('${backgroundUrl}')`,
                    backgroundSize: 'cover',
                }}
                className="flex-1 w-full h-full bg-no-repeat bg-right-top"
            ></div>
            <div className="flex justify-between items-center w-full h-14 absolute top-20 left-16">
                <TrawaLogoIcon className="h-14 w-auto text-background" />
            </div>
        </div>
    );
}

// TODO: why did the container className have 2xl:w-[60%]? I removed it because it looks ugly with a whitespace in between
